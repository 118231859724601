import React, { useEffect, useState } from 'react'
import { ShortListElements } from '../short-list-elements/shortListElements'
import { GetBodyInnerHTML } from "../../../../modules/services/getElements.service";
import { ProgressStages } from "../InfoProgress/Progress";
import { Breadcrumb, BreadcrumbItem, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getAllBP, getPointsService, getTourApi } from '../../../../api/userSession';
import { getTourData, Tour } from '../GamificationServices/gamification-service';
import LoadSpinner from '../../../load-spinner/load-spinner';
import CarouselView from '../../../carousel/carousel';
import '../../../../scss/custom/_gamification.scss'
import { store } from '../../../../modules/appState/store/store';
import { AutocompleteInput } from '../Activities/activities-detail/bp-autocomplete';
import { getDomain } from '../../../../config/domain';
import { FeedbackTour } from '../Feedback-form/feedback-tour/FeedbackTour';

export const GamificationLanding = () => {
    const storeApp = store;
    const [user, setUser] = useState<any>(null);
    const [tour, setTour] = useState<any>(null);
    const [pointsOpened, setPointsOpened] = useState<boolean>(false);
    const [tourOpened, setTourOpened] = useState<boolean>(false);
    const [domainData, setDomainData] = useState(
        getDomain(window.location.hostname + ''),
    );
    storeApp.subscribe(() => {
        if (!user)
            setUser(JSON.parse(localStorage.getItem('user_info') || ''));
    });

    useEffect(() => {
        if (!user && localStorage.getItem('user_info'))
            setUser(JSON.parse(localStorage.getItem('user_info') || ''));
        if (!tour && user) {
            //-------------------->               DEFINO BANDA PARA PRUEBAS <---------------------------------
            let bandId=user?.band;
            // let bandId = 'VII';
            let buData: string = domainData?.countryGammification || 'CO';
            getTourApi(buData).then((elem: any) => {
                let t = getTourData(elem);
                if (!pointsOpened && t?.id)
                    getPointsService(t?.id, bandId).then((elem: any) => {
                        setPointsOpened(true);
                        
                        if (t) setTour({ ...t, ...elem, 'band': bandId });
                        setTourOpened(true);

                    }).catch((error: any) => console.log("Error query:  ", error));
            }).catch((error: any) => console.log("Error query:  ", error));
        }
    }, [tour, user]);
    const breadItems = [
        { label: domainData?.name||'', active: false, link: '/landing' },
        { label: 'Tour de Gente', active: true, link: '' },
    ];

    if (tourOpened && user && tour) {
        return (
            <div className={'main-content'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-12 col-md-8">
                            <Breadcrumb>
                                {breadItems.map(({ label, active, link }) => (
                                    <BreadcrumbItem
                                        key={`payment-breadcrumb-item-${label}`}
                                        {...{ active }}
                                    >
                                        {!active ? <Link to={link}>{label}</Link> : label}
                                    </BreadcrumbItem>
                                ))}
                            </Breadcrumb>
                        </div>
                        <div className="col col-12 col-md-4">
                            {user && (
                                <ModalComponentBp lockBackdrop={true} user={user} />
                            )}
                        </div>
                    </div>
                    <h1 className='text-center'>{tour?.title}</h1>
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-7 mb-4">
                            <div className="">
                                <CarouselView content={tour?.media?.map((m: any) => {
                                    return {
                                        'altText': 'bannerTour',
                                        'caption': '',
                                        'link': '',
                                        'src': {
                                            'src': m?.src,
                                            'type': m?.type
                                        }
                                    }
                                })} />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="ps-md-4">
                                <div className="title-welcome fw-bold">Conoce nuestro Tour</div>
                                <div
                                    className="info_programs"
                                >
                                    {tour?.description}
                                </div>
                                <div className="text-center">
                                    <ButtonTour url={tour?.link?.link} name={tour?.link?.title} />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <ProgressStages text={'Progreso general'} percent={parseFloat(tour?.progress?.total_progress || '0').toFixed(2)} score={tour?.points?.total_points} />
                        </div>
                    </div>
                    <ShortListElements type={'stages'} tour={tour} title={'Tus Etapas'} />
                    <ShortListElements type={'stations'} tour={tour} title={'Tus Estaciones'} />
                    <FeedbackTour description={tour?.csatDescription} btn={tour?.csatBtn}/>
                </div>
            </div>
        )
    }
    else {
        if (tourOpened &&user && !tour) {

            return (
                <div className={'main-content'}>
                    <div className="container-fluid text-center">
                        <h3>No hay tour asignado para {domainData?.country || ' esta BU'}</h3>
                    </div>
                </div>

            )

        }
        else {
            return <LoadSpinner />
        }
    }


}

export const ButtonTour = (props: any) => {
    const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(props.url);
    if (extern) {
        return (
            <button className="btn btn-ga-primary">
                <a target="_blank" href={props.url || ''}>
                    {props.name}
                </a>
            </button>
        );
    } else {
        return (
            <button className="btn btn-ga-primary">
                <Link to={props.url}>{props.name}</Link>
            </button>
        );
    }
};


export const ModalComponentBp = (props: any) => {
    const [name, setName] = useState<string>('');
    const [modal, setModal] = React.useState(false);
    const [bp, setBp] = useState('');
    const [options, setOptions] = useState([]);
    const [opened, setOpened] = useState(false);
    const [backdrop, setBackdrop] = useState<boolean | "static">(true);
    const { lockBackdrop, user } = props;


    const toggle = () => setModal(!modal);
    useEffect(() => {
        if (!opened && user?.bu)
            getAllBP(user?.bu||'CO').then(({ bp, bps }: any) => {
                setOptions(bps);
                setName(getNameBp(bp, bps));
                setOpened(true);
                if (!bp && lockBackdrop) {
                    setModal(true);
                    setBackdrop('static')
                }
            }).catch((error: any) => console.log("Error query:  ", error));
    }, [options, bp, name, backdrop]);
    function getNameBp(bp: string, bps: any[]): string {
        let name = '';
        bps?.map((bpItem: any) => {
            if (bpItem?.field_sharp == bp) {

                name = bpItem?.name || '';
            }
        });
        return name;
    }
    return (
        <div className="col-auto col-md-auto  px-3 py-0 me-md-2">
            <div className="link-geo ms-md-3" onClick={toggle}>
                <div className="text">
                    <span>{name ? 'Tu BP:  ' + (name || '') : 'Selecciona tu BP'} </span>
                </div>
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 1000 }}
                backdrop={backdrop} keyboard={false}
                className="modal-dialog-centered"
            >
                <ModalBody>
                    <h3 className='mb-3'>Bussiness Partner</h3>
                    <span className='mb-3'>
                        Cuéntanos quien es tu Business Partner, recuerda que será la persona encargada de calificar tus actividades en Tour de gente.
                    </span>
                    <div>
                        <AutocompleteInput
                            text={'Escriba el nombre aquí..'}
                            bu={'CO'}
                            trigger={(bpData: any) => { setName(bpData?.name); toggle(); }}
                            options={options}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
