import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetCategoryNewsQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import {
  GetBodyInnerHTML,
  getImage,
  getNews,
  toLocaleDate,
} from '../../../../modules/services/getElements.service';

const GlobalNews = () => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const globalData = useGetCategoryNewsQuery('GLOBAL')?.data;
  const globalNews = getNews(globalData);

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row main_contests newsfeed-contest">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/news-feed">Newsfeed</Link>
                  </li>
                  <li
                    className="breadcrumb-item active bold"
                    aria-current="page"
                  >
                    Global
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">Global</div>
              <div className="">
                {globalNews.length > 0 && (
                  <div className="row content-news">
                    {globalNews.map((el: any, index: number) => {
                      if (index == 0) {
                        return (
                          <Link
                            className="col-xl-8 col-lg-8 col-md-12 col-sm-12  text-black"
                            to={'/detail-news?' + el.id}
                          >
                            <div className="card shadow news-list" key={index}>
                              <img
                                src={el?.image}
                                className="img-fluid rounded-4"
                                alt="..."
                              />
                              <div className="card-body preview-content">
                                <div className="prev-body">
                                  <h5 className="card-title">{el?.title}</h5>

                                  <GetBodyInnerHTML content={el.description} />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      }
                    })}
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 small-target">
                      <div className="row">
                        {globalNews.map((el: any, index: number) => {
                          if (index > 0 && index < 3) {
                            return (
                              <div
                                className="col-lg-12 col-md-6 col-sm-12"
                                key={index}
                              >
                                <Link
                                  className="text-black"
                                  to={'/detail-news?' + el.id}
                                >
                                  <div className="card shadow-sm">
                                    <img
                                      src={el.image}
                                      className="relevant-images "
                                      alt="..."
                                    />
                                    <div className="card-body">
                                      <span className="date-news">
                                        {el.date}
                                      </span>
                                      <h5 className="card-title mt-4">
                                        {el.title}
                                      </h5>
                                      <p className="card-text">
                                        {el.description.substr(0, 200)}...
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-12 footer-global">
                      <div className="row">
                        {globalNews.map((el: any, index: number) => {
                          if (index > 2) {
                            return (
                              <div
                                className="col-lg-4 col-md-6 col-sm-12"
                                key={index}
                              >
                                <Link
                                  className="text-black"
                                  to={'/detail-news?' + el.id}
                                >
                                  <div className="card shadow-sm">
                                    <img
                                      src={el.image}
                                      className="relevant-images "
                                      alt="..."
                                    />
                                    <div className="card-body">
                                      <span className="date-news">
                                        {el.date}
                                      </span>
                                      <h5 className="card-title mt-4">
                                        {el.title}
                                      </h5>
                                      <p className="card-text">
                                        {el.description.substr(0, 200)}...
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalNews;
