import React from 'react';

export const Empty = () => {
  return (
    <div>
      <div className="section-title mt-5">
        <img
          src="https://i.ibb.co/sw34sxm/buscador.png"
          className="search-image"
          alt="img-buscador"
        />
        <>
          <p className="search-text">
            Recuerda seleccionar la fecha de pedido que deseas ver
          </p>
        </>
      </div>
    </div>
  );
};
