import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { ModalLocation } from '../modalLocation';
import { Filled } from '../../../History/Filled';
import { Empty } from '../../../History/Empty';
import { getOrdersHistory } from '../../../../api/userSession';
import { getProductsShoppingCart } from '../../../../modules/services/getElements.service';
const breadItems = [
  { label: 'Planeta Beerway', active: false, link: '/landing' },
  { label: 'E-commerce', active: false, link: '/e-commerce/landing' },
  { label: 'Tu Pago', active: true, link: '' },
];
export const ShoppingHistory = () => {
  const [dates, setDates] = useState([]);
  const [productsData, setProductsData] = useState({});

  const getHistoryData = useCallback(
    async (whichData?: string, date?: string) => {
      const history = await getOrdersHistory();

      if (whichData === 'specific_data') {
        const dataByDate = history.filter(
          ({ purchase_date }: { purchase_date: string }) =>
            purchase_date === date,
        )[0];
        const products = getProductsShoppingCart(dataByDate.items);

        return {
          products: products.map((product) => ({
            ...product,
            score: 5,
            orderId: product.ecommerce_orders_id,
            img: product.gallery[0]?.content.src || '',
          })),
          resume: {
            total: dataByDate.items.reduce(
              (acc: number, cur: { total_price: string }) =>
                acc + (+cur.total_price || 0),
              0,
            ),
            phone: dataByDate.cellphone,
            // availableCredit: dataByDate.credit_data.available,
            locationId: dataByDate.location_id,
            paymentType: dataByDate.payment_type,
          },
        };
      } else
        return history
          .map(({ purchase_date }: { purchase_date: string }) => purchase_date)
          .sort();
    },
    [],
  );

  useEffect(() => {
    const getData = async () => {
      setDates(await getHistoryData());
    };

    getData();
  }, [getHistoryData]);

  const onDateChange = async ({
    target: { value },
  }: ChangeEvent<{ value: string }>) => {
    setProductsData(value ? await getHistoryData('specific_data', value) : {});
  };
  const full = Object.keys(productsData).length > 0;

  return (
    <div className="main-content">
      <div className="container-fluid">
        <Breadcrumb>
          {breadItems.map(({ label, active, link }) => (
            <BreadcrumbItem
              key={`payment-breadcrumb-item-${label}`}
              {...{ active }}
            >
              {!active ? <Link to={link}>{label}</Link> : label}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        <div className="title-welcome">Tu Historial</div>
        <div className="d-flex justify-content-between justify-content-md-end py-0">
          <ModalLocation />
          <div className="col-6 col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0">
            <div className="col-12">
              <div className="row">
                <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0" />
                <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                  <div className="link-opt ">
                    <Link to="/e-commerce/shopping-cart">
                      <i className="bi bi-cart3" />
                    </Link>
                  </div>
                </div>
                <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                  <div className="link-opt">
                    <Link to="/e-commerce/favorites">
                      <i className="bi bi-heart" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="col-4 tabs-section tabs-news hs-left">
              <select
                className="form-select hs"
                aria-label="Default select example"
                onChange={onDateChange}
              >
                <option value="" selected>
                  Selecciona una fecha
                </option>
                {dates.map((date) => {
                  const dateToShow = new Date(date).toLocaleString('es-CO', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour12: true,
                    hour: '2-digit',
                    minute: '2-digit',
                  });

                  return (
                    <option key={`history-date-option-${date}`} value={date}>
                      {dateToShow}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-12">
            {full ? <Filled {...{ productsData }} /> : <Empty />}
          </div>
        </div>
      </div>
    </div>
  );
};
