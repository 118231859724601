import {
  deleteSession,
  getUserData,
  postExHubAccessManagement,
} from '../../api/userSession';
import React, { useEffect, useRef, useState } from 'react';
import { store } from '../../modules/appState/store/store';
import { Link } from 'react-router-dom';
import { apiMainURL } from '../../config/environment';
import { domain_country } from '../../config/domain';

import AppsPopover from './AppsPopover';

import './Menu.scss';
import LoadSpinner from '../load-spinner/load-spinner';

const Menu = ({ list }: any) => {
  const sharp = localStorage.getItem('sharp') || '';

  const appsMenuButtonRef = useRef(null);

  const storeApp = store;
  const [user2, setUser2] = useState({
    image: storeApp.getState().AppReducer,
  });
  const [isOpen, setIsOver] = useState(false);
  const [isData, setIsData] = useState(false);
  const [exHubElements, setExHubElements] = useState<
    Array<{ url: string; img: string; label: string }> | false
  >(false);

  const toggle = () => {
    setIsOver((prevOpened) => !prevOpened);
  };

  // getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
  //   .then((data) => {

  //     let name = data?.profile?.first_name || '';
  //     localStorage.setItem('user', name);
  //     localStorage.setItem('user_info',JSON.stringify(data?.profile));
  //     storeApp.dispatch({
  //       type:storeApp.getState().AppReducer|| '',
  //     });
  //   })
  //   .catch((error) => {
  //     console.log('error', error);
  //     deleteSession(window.location.href);
  //   });
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );


  useEffect(() => {
    if (!isData)
      getUserData(`${apiMainURL}/api/authenticated-user`, '')
        .then((data) => {
          let d_id = data?.status?.drupal_id || '';
          localStorage.setItem('drupal_id', d_id);
          let name = data?.profile?.first_name || '';
          localStorage.setItem('user', name);
          localStorage.setItem('user_info', JSON.stringify(data?.profile));
          storeApp.dispatch({
            type: storeApp.getState().AppReducer || '',
          });
          setUser2({
            image: data?.profile?.user_image,
          });
          setIsData(true);
        })
        .catch((error) => {
          console.log('error', error);
          deleteSession(window.location.href);
        });

    const getData = async () => {
      const exHubItems = await postExHubAccessManagement();

      setExHubElements(
        exHubItems &&
        JSON.parse(exHubItems)
          .sort(
            ({ is_simplifica: aV = false }, { is_simplifica: bV = false }) =>
              Number(bV) - Number(aV),
          )
          .map((items: any) => ({
            url: items.access_url,
            img: items.thumbnail,
            label: items.name,
          })),
      );
    };

    getData();
  }, [user2]);
  if (isData)
    return (
      <>
        <header className="header" id="header">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col text-center">
                <span className="logo-header">
                  <Link to="/landing">
                    <img
                      alt="logo"
                      src={`${process.env.PUBLIC_URL}/customs/${domain.color}/logo_h.png`}
                    />
                  </Link>
                </span>
              </div>
              <div className="col-auto search_top">
                <Link to="/search">
                  {' '}
                  <i className="bi bi-search search-default-color"></i>
                </Link>
              </div>

              <div className="col-auto d-none d-md-block">
                <NotificationsComponent />
              </div>

              {exHubElements && (
                <div
                  className="col-auto header-icons"
                  id="Apps-Popover"
                  ref={appsMenuButtonRef}
                  onClick={toggle}
                >
                  <i className="bi bi-grid-3x3-gap-fill" title="Mis Apps" />
                  <AppsPopover
                    {...{ isOpen, toggle }}
                    target={appsMenuButtonRef}
                    elements={exHubElements}
                  />
                </div>
              )}
              <div className="col-auto">
                <div className="img-perfil">
                  <Link to="/profile">
                    {' '}
                    <img
                      src={
                        user2.image === ''
                          ? `${process.env.PUBLIC_URL}/resources/perfil-default.png`
                          : user2.image
                      }
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  else return <LoadSpinner />

};

export default Menu;

export const NotificationsComponent = () => {
  return (
    <div>
      <notifications-button-component></notifications-button-component>
    </div>
  );
};
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'notifications-button-component': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
