import React from 'react';
import ReactDOM from 'react-dom';
import Cropper from 'react-cropper';
import { apiMainURL, gamificationURL } from '../../config/environment';
import { store } from '../../modules/appState/store/store';
import { getUserData } from '../../api/userSession';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'
import 'cropperjs/dist/cropper.css';

class CropImage extends React.Component {
  constructor(props) {
    console.log("PROPS:  ",props)
    super(props);
    this.state = {
      imageSrc: '',
      flag: true,
      idactivity: props?.idactivity,
      tour:props?.tour,
      trigger:()=>props?.trigger
    };

    this.fileInput = React.createRef();
    this.handleFileRead = this.handleFileRead.bind(this);
    this.fileReader = new FileReader();
    this.cropper = React.createRef();
  }
  storeApp = store;
  handleFileRead(e) {
    const binaryData = this.fileReader.result;
    const base64Data = window.btoa(binaryData);
    this.setState({ base64: base64Data });
  }

  handleChange(event) {
    console.log('Evenr:  ', event.target.files[0]);
    const file = this.fileInput.current.files[0];
    const { name, size, type } = file;
    const imageSrc = window.URL.createObjectURL(event.target.files[0]);

    this.setState({
      name,
      size,
      type,
      imageSrc,
      croppedImgSrc: null,
      flag: !this.state.flag,
      idactivity:this.props.idactivity,
      tour:this.props.tour,
      trigger:this.props.trigger
      
    });

    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsBinaryString(file);
  }

  handleCropChange() {
    const croppedImgData = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();

    this.setState({ croppedImgSrc: croppedImgData });
  }

  handleRotate() {
    this.cropper.current.cropper.rotate(90);
    this.handleCropChange();
  }
  handleFlag() {
    this.setState({
      flag: !this.state.flag,
      croppedImgSrc: null,
    });
  }
  saveImage(image) {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var formdata = new FormData();
    formdata.append('sharp', localStorage.getItem('sharp')?.toString() || '');
    formdata.append('image', image);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/picture_user`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getUserData(`${apiMainURL}/api/authenticated-user`, '').then((data) => {
          this.storeApp.dispatch({
            type: data?.profile?.user_image || '',
          });
        });
      })
      .catch((error) => console.log('error', error));
  }
  saveEvidence(image) {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var formdata = new FormData();
    formdata.append('activity', this.state.idactivity || '');
    formdata.append('sharp', localStorage.getItem('sharp')?.toString() || '');
    formdata.append('image', image);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    fetch(`${gamificationURL}/api/tours/upload-evidence`, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        this.state?.trigger();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Evidencia Cargada'
        });
      })
      .catch((error) => console.log('error', error));
  }

  render() {
    const { name, type, size, imageSrc, base64, croppedImgSrc, idactivity, tour, trigger } = this.state;

    return (
      <div>
        <div>
          {!imageSrc && (
            <div className="modal-image">
              <i className="bi bi-image"></i>
            </div>
          )}
          <div id="div_file">
            <span>
              <i className="bi bi-plus-circle-dotted"></i> Subir foto
            </span>
            <input
              type="file"
              id="btn_enviar"
              accept="image/*"
              className=""
              ref={this.fileInput}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        </div>
        <div>
          {imageSrc && !croppedImgSrc && (
            <div className="area-selection">
              <p>Seleccione el área de la fotografía</p>
              <Cropper
                style={{ maxWidth: '90%', maxHeight: '400px' }}
                ref={this.cropper}
                src={imageSrc}
                aspectRatio={16 / 16}
                cropend={() => this.handleCropChange()}
              />
              <div>
                <button
                  className="btn btn-primary rounded mt-4"
                  onClick={() => this.handleRotate()}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              </div>
            </div>
          )}

          {croppedImgSrc && (
            <div className="text-center position-relative">
              <img
                src={croppedImgSrc}
                style={{ maxWidth: '90%' }}
                className="crop-image"
              />
              <button onClick={() => this.handleFlag()} className="button-crop">
                <i className="bi bi-pencil-square"></i>
              </button>
            </div>
          )}
        </div>
        {imageSrc && (
          <div className="text-center">
            <button
              className="btn btn-primary rounded mt-4"
              disabled={croppedImgSrc ? false : true}
              onClick={() => this.state?.tour?this.saveEvidence(croppedImgSrc):this.saveImage(croppedImgSrc)}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
    );
  }
}
export default CropImage;
