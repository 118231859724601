import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { getDomain } from '../../config/domain';
import { apiMainURL } from '../../config/environment';
import { useHistory } from 'react-router';

type LegalProps = {
  acceptTerms?: (a: string) => void;
};

export const Legal = ({ acceptTerms }: LegalProps) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    acceptTerms: Yup.bool()
      .required('Terminos y Condiciones Son Requeridos')
      .oneOf([true], 'Terminos y Condiciones Son Requeridos'),
    acceptPrivacy: Yup.bool()
      .required('Privacidad Son Requeridos')
      .oneOf([true], 'Politicas de Privacidad requeridas'),
  });

  function save() {
    var myHeaders: Headers = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var formdata = new FormData();
    formdata.append('sharp', localStorage.getItem('sharp') || '');
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/legal_save`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        localStorage.setItem(
          'accept-terms',
          JSON.parse(result).legal ? '1' : '0',
        );
        acceptTerms && acceptTerms('1');
        history.push('/landing');
      })
      .catch((error) => console.log('error', error));
  }
  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-5">
            <div className="fs-1 text-center my-5">Una cosa más...</div>
            <div className="row">
              <div className="col-md-6 text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/resources/img-terms.svg`}
                  className="img-fluid"
                  width="400"
                />
              </div>
              <div className="col-md-6 content-term">
                <div className="pt-4">
                  ¡Gracias por usar {domainData.name}! <br />
                  Estamos felices de que estés aquí. Antes de continuar te
                  invitamos a que leas y aceptes los{' '}
                  <a target="_blank" href={domainData.terms} className="">
                    Términos y condiciones
                  </a>
                  y la
                  <a target="_blank" href={domainData.policy}>
                    Política de privacidad y uso de datos personales
                  </a>
                  para poder acceder o usar la plataforma.
                </div>
                <div className="my-5">
                  <Formik
                    initialValues={{
                      acceptTerms: false,
                      acceptPrivacy: false,
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      if (values.acceptPrivacy && values.acceptPrivacy) save();
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="Terminos"
                            name="acceptTerms"
                            className="form-check-input"
                          />
                          <label className="form-check-label">
                            Acepto los Términos y condiciones.
                            {errors.acceptTerms && touched.acceptTerms ? (
                              <div className="errors-form">
                                {errors.acceptTerms}
                              </div>
                            ) : null}
                          </label>
                        </div>

                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="privacidad"
                            name="acceptPrivacy"
                            className="form-check-input"
                          />
                          <label className="form-check-label">
                            Acepto la Política de privacidad y uso de datos
                            personales.
                            {errors.acceptPrivacy && touched.acceptPrivacy ? (
                              <div className="errors-form">
                                {errors.acceptPrivacy}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="text-center mb-5 pt-5">
                          <button
                            className="btn btn-primary"
                            type="submit" /* disabled={!accept} */
                          >
                            Aceptar y Continuar
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
