import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import CarouselView from '../../carousel/carousel';
import { domain_country, getDomain } from '../../../config/domain';
import { useGetBannerSectionApiQuery } from '../../../api/userSession';
import { cultureId, landingId } from '../../../modules/services/sections';
import {
  bannersInSection,
  bannersInSectionEcommerce,
  contentInSection,
} from '../../../modules/services/getElements.service';
import { apiMainURL } from '../../../config/environment';
import { TrackingContainer } from './utitities/trcking-container';

export const EcommerceLanding = (props: any) => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  let dataBannersApi: any = {};
  var banners: Element[] = [];

  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  banners = bannersInSectionEcommerce(dataBannersApi);

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tu tiendita
                  </li>
                </ol>
              </nav>
              <div className="title-welcome">Tu tiendita</div>
              <div className="d-flex justify-content-between justify-content-md-end py-0">
                <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 me-md-2">
                  <div className="link-geo ms-md-3" onClick={toggle}>
                    <div>
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="text">
                      {' '}
                      Recoger en
                      <br />
                      <span>Guayaquil</span>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    modalTransition={{ timeout: 1000 }}
                    className="modal-dialog-centered"
                  >
                    <ModalBody>
                      Selecciona el lugar donde quieres reclamar
                      <span>
                        La disponibilidad de los productos varía según el punto
                        de elección
                      </span>
                      <div>
                        <select>
                          <option value="">Seleccionar...</option>
                          <option value="1"> Cervecería Unión </option>
                          <option value="2"> Cervecería de Tocancipá </option>
                          <option value="3">
                            {' '}
                            Cervecería de Barranquilla{' '}
                          </option>
                          <option value="4"> Cervecería del Valle </option>
                          <option value="5"> Cervecería de Bucaramanga </option>
                          <option value="6"> Cervecería de Boyacá </option>
                          <option value="7"> Edificio 127 </option>
                          <option value="8"> Impresora del Sur </option>
                          <option value="9"> Indugral </option>
                          <option value="10"> Malteria Tibitó </option>
                          <option value="11"> Malteria Tropical </option>
                          <option value="12">
                            {' '}
                            R. Centro CD San Fernando{' '}
                          </option>
                          <option value="13"> R. Centro Calle 22 </option>
                          <option value="14"> R. Centro Amerícas </option>
                          <option value="15"> R. Centro AutoSur </option>
                          <option value="16"> R. Andes CD Armenia </option>
                        </select>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
                <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0">
                  <div className="link-geo">
                    <Link to="/e-commerce/shopping-cart">
                      <i className="bi bi-cart3"></i>
                      <span className="d-md-inline-block d-none">
                        Ver carrito
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-8">
                  <div className="Carousel-Ecommerce">
                    <CarouselView content={banners} />
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 main-tracking">
                  <div>
                    <div className="d-table w-100 mt-5">
                      <div className="d-table-cell">
                        <span className="text-Semi-bd">Tu pedido actual</span>
                      </div>
                      <div className="d-table-cell text-end">
                        <Link to="/competitions">Ver detalle</Link>
                      </div>
                    </div>
                    <TrackingContainer></TrackingContainer>
                  </div>
                  <div>
                    <div className="d-table w-100 mt-5">
                      <div className="d-table-cell">
                        <span className="text-Semi-bd">
                          Tu historial de pedidos
                        </span>
                      </div>
                      <div className="d-table-cell text-end">
                        <Link to="/competitions">Ver todo</Link>
                      </div>
                    </div>
                    <div className="tracking-container">
                      <div className="d-table w-100 mb-4">
                        <div className="d-table-cell">
                          <span className="text-Semi-bd">
                            Septiembre 10 de 2021
                          </span>
                        </div>
                        <div className="d-table-cell text-end">No. 528596</div>
                      </div>
                      <div className="d-table w-100 mb-4">
                        <div className="d-table-cell details-order">
                          <img
                            src={`${process.env.PUBLIC_URL}/customs/${domain.color}/img-product.jpg`}
                            alt=""
                            width="115"
                            className="img-fluid"
                          />
                          <span>
                            Stella Artois <br />
                            355 ml <br />
                            Cant. 6 Six <br />
                            <br />
                            Total <br />
                            <span className="text-Semi-bd">$12.000</span>
                          </span>
                        </div>
                        <div className="d-table-cell text-end">
                          <span className="text-Semi-bd">Cantidad</span> <br />
                          <span className="quantity-number">1</span> <br />
                          <span className="txt-secondary text-Semi-bd">
                            Entregado
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="overflow-hidden mt-5">
                    <div className="row row-cols-md-5 mt-sm-4 items-products">
                      <div className="col">
                        <div className="thumbs-products">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/products/full-beer-mug.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                          <div>Premio 1</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbs-products">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                          <div>Premio 2</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbs-products">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                          <div>Premio 3</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbs-products">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                          <div>Premio 4</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbs-products">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                          <div>Premio 5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EcommerceLanding;
