import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { domain_country, getDomain } from '../../config/domain';
import SearchResults from './search-results';
import { useFormik } from 'formik';
const Search = () => {
  const [flag, setFlag] = useState('true');
  const [data, setData] = useState('');
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit: (values) => {
      if (values.text.length > 0) {
        setFlag('false');
        setData(values.text);
      }
    },
  });

  useEffect(() => {}, []);

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/landing"> {domainData.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Buscador
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col col-xl-7 col-lg-4 col-md-3 col-sm-12">
                  <div className="title-welcome fs-2">Buscador</div>
                </div>

                <div className="col col-xl-5 col-lg-8 col-md-9 col-sm-12">
                  <form
                    className="input-group mb-3 search-input"
                    onSubmit={formik.handleSubmit}
                  >
                    <input
                      id="text"
                      name="text"
                      type="text"
                      className="form-control search-text-input"
                      placeholder="Escribe aquí para buscar"
                      aria-label="Text input search "
                      aria-describedby="basic-addon2"
                      onChange={formik.handleChange}
                      value={formik.values.text}
                    />

                    <button
                      className="input-group-append search-button btn "
                      type="submit"
                      data-bs-toggle="popover"
                      title="La búsqueda debe ser matyor a 4 caracteres"
                    >
                      <span
                        className="input-group-text search2"
                        id="basic-addon2"
                      >
                        <i className="bi bi-search search-icon2"></i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
              {flag == 'true' && (
                <div className="section-title mt-5">
                  <img
                    src="https://i.ibb.co/sw34sxm/buscador.png"
                    className="search-image"
                    alt="img-buscador"
                  />
                  <div className="">
                    <p className="search-text">
                      Recuerda escribir arriba la palabra que desea buscar
                    </p>
                  </div>
                </div>
              )}
              {flag == 'false' && data.length > 0 && (
                <SearchResults className="mt-5" content={data} />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Search;
