import React from 'react';
import { useGetContentSectionApiQuery } from '../../api/userSession';
import { contentInSection } from '../../modules/services/getElements.service';
import Sections from '../sections/sections';

function ContentLinks(props: any) {
  const sectionId = props?.id;
  const dataContentApi = useGetContentSectionApiQuery(sectionId).data;
  const contents = contentInSection(dataContentApi);

  if (contents)
    return (
      <div className="">
        <h3 className="section-title">Contenido</h3>
        <Sections content={contents} />
      </div>
    );
  else return <></>;
}

export default ContentLinks;
