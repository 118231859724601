
import { gamificationURL } from './../../../../config/environment';
export interface Tour {
    title: string,
    description: string,
    end_date: string,
    start_date: string,
    id: string,
    image: string,
    media: any[]
}
export const getTourData = (elem: any): any => {
    const { data, included } = elem;
    if (data && data[0]) {
        let a = {
            title: data[0]?.attributes?.title || 'default title',
            description: data[0]?.attributes?.field_description?.value || 'default title',
            end_date: data[0]?.attributes?.field_end_date || 'default end_date',
            start_date: data[0]?.attributes?.field_start_date || 'default start_date',
            id: data[0]?.attributes?.drupal_internal__nid || 'default id',
            image: getImage(data[0]?.relationships.field_image.data.id, included),
            media: getMedia(data[0]?.relationships?.field_media?.data, included),
            link: {
                link: data[0]?.attributes?.field_link?.uri || '',
                title: data[0]?.attributes?.field_link?.title || ''
            },
            csatDescription:data[0]?.attributes?.field_csat_descripcion||'',
            csatBtn:{
                url:data[0]?.attributes?.field_csat_enlace?.uri||'',
                title:data[0]?.attributes?.field_csat_enlace?.title||''
            }
        };
        return a;
    }
    return null
}
export const getStagesData = (elem: any, type: string, tour: any): any[] => {
    const { data, included } = elem;
    let stages: any[] = [];
    data?.map((stageItem: any) => {

        let typeStage = getTypeStage(stageItem?.relationships?.field_type_stage?.data?.id, included);

        if (typeStage == type) {
            stages.push({
                tipo: type,
                titulo: stageItem?.attributes?.title,
                fechaInicio: new Date(stageItem?.attributes?.field_start_date || '').toLocaleDateString('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                }),
                fechaFin: new Date(stageItem?.attributes?.field_end_date || '').toLocaleDateString('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                }),
                closed:new Date(stageItem?.attributes?.field_end_date || '').toISOString(),
                started:new Date(stageItem?.attributes?.field_start_date|| '').toISOString(),
                image: getImage(stageItem?.relationships?.field_image?.data?.id, included),
                actividadesPendientes: getNumberPendingActivities(stageItem?.attributes?.drupal_internal__nid, tour?.status[stageItem?.attributes?.drupal_internal__nid]),
                tuPorcentaje: getGeneralScore(stageItem?.attributes?.drupal_internal__nid, tour?.progress?.stages || []),
                tuPuntaje: getGeneralScore(stageItem?.attributes?.drupal_internal__nid, tour?.points?.stages),
                id: stageItem?.attributes?.drupal_internal__nid,
                colorPrincipal: stageItem?.attributes?.field_color?.color,
                descripcion: stageItem?.attributes?.field_description?.value || ''
            })
        }


    })

    return stages
}
export const getActivityData = (elem: any, tour: any): any[] => {

    const { data, included } = elem;

    let activities: any[] = [];
    data?.map((ActItem: any) => {
        activities.push({

            titulo: ActItem?.attributes?.title || '',
            fechaInicio: new Date(ActItem?.attributes?.field_start_date || '')?.toISOString(),
            fechaFin: new Date(ActItem?.attributes?.field_end_date || '')?.toISOString(),
            image: getImage(ActItem?.relationships?.field_image?.data?.id, included),
            id: ActItem?.attributes?.drupal_internal__nid,
            colorPrincipal: tour?.color,
            descripcion: ActItem?.attributes?.field_description?.value || '',
            detail: ActItem?.attributes?.field_detail?.value || '',
            estado: getStatus(ActItem?.attributes?.drupal_internal__nid, tour?.status[parseInt(tour?.stageId) || 0] || [], ActItem?.attributes?.field_end_date, ActItem?.attributes?.title),
            buttons: getButtons(ActItem?.attributes?.field_buttons),
            puntos: getActivityScore(ActItem?.attributes?.drupal_internal__nid, tour?.points?.activities),
            max_points: ActItem?.attributes?.field_points || '0',
            evidence: getTypeApproval(ActItem?.relationships?.field_type?.data?.id, included),
            stageId:tour?.stageId||'',
            csat:{
               btn:{
                title:ActItem?.attributes?.field_csat_enlace?.title||'',
                url:ActItem?.attributes?.field_csat_enlace?.uri||'',

               },
               image:getImage(ActItem?.relationships?.field_csat_imagen?.data?.id, included)
            }
        })
    });

    return activities
}
function getTypeApproval(idType: string, includes: any[]): boolean {
    let approval = true;
    includes?.map((inc: any) => {
        if (inc?.id == idType) {
            approval = inc?.attributes?.field_require_evidence;
            return approval;
        }
    });
    return approval;
}
function getButtons(buttonData: any[]): any[] {
    let buttons: any[] = [];
    buttonData?.map(({ title, uri }) => {
        buttons.push({
            title, uri
        })
    });
    return buttons
}
function getNumberPendingActivities(idstage: string, activitiesPendingArray: any[]): number {

    let pendientes = 0;
    if (activitiesPendingArray)
        Object?.entries(activitiesPendingArray).map((propObject: any) => {
            if (propObject[1]?.field_status == 'Pendiente') {
                pendientes++;
            }
        })
    return pendientes;
}
function getGeneralScore(idstage: string, stagesProgressArray: any[]) {
    let prog = '0';
    Object?.entries(stagesProgressArray).map((propObject: any) => {
        if (propObject[0] == idstage) {
            prog = propObject[1];
        }
    })
    return prog;
}

export const getAllActivitiesData = (elem: any, idStage: string, statuses: any[], points: any): any => {
    const { data, included } = elem;

    let activities: any[] = [];
    data?.map((act: any) => {
        let stageId = getStageId(act?.relationships?.field_stage?.data?.id, included);
        if (stageId == idStage)
            activities.push({
                titulo: act?.attributes?.title || '',
                descripcion: act?.attributes?.field_description?.value || '',
                // 
                // puntos: act?.attributes?.field_gamification_points || '0',
                puntos: getActivityScore(act?.attributes?.drupal_internal__nid, points?.activities),
                maxPuntos: act?.attributes.field_points || '0',
                fechaFin: new Date(act?.attributes?.field_end_date || '').toLocaleDateString("en-US", {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                }),
                imagen: getImage(act?.relationships?.field_image?.data?.id, included),
                id: act?.attributes?.drupal_internal__nid,
                estado: getStatus(act?.attributes?.drupal_internal__nid, statuses[parseInt(idStage) || 0] || [], new Date(act?.attributes?.field_end_date || '').toLocaleDateString("en-US", {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                }), act?.attributes?.title),
                imagenStatus: 'https://i.ibb.co/X2P7yqt/gamification-pendiente.png',
                tourId: getIdNode(act?.relationships?.field_tour?.data?.id, included),
                stageId: getIdNode(act?.relationships?.field_stage?.data?.id, included)
            });
    })

    return activities;
}

function getActivityScore(idstage: string, stagesProgressArray: any[]) {
    let score = '0';
    Object?.entries(stagesProgressArray).map((propObject: any) => {
        if (propObject[0] == idstage) {
            score = propObject[1];
        }
    })
    return score;
}
function getStatus(idActivity: string, statuses: any[], fecha_fin: string, name: string) {

    let status = 'Por hacer';

    Object?.entries(statuses).map((propObject: any) => {
        if (propObject[0] == idActivity) {

            let fechaCierre = propObject[1]?.end_date;
            let fechaActual = new Date().toISOString();
            status = (fechaActual > fechaCierre && (propObject[1]?.field_status!='Aprobada' && propObject[1]?.field_status!='Pendiente')) ? 'Finalizada' : propObject[1]?.field_status;
        }
    });
    if (new Date(fecha_fin) < new Date() && status == 'Por hacer') status = 'Finalizada';

    return status;
}
function getIdNode(id: string, includes: any[]) {
    let idNode = '';
    includes?.map((inc: any) => {
        if (inc?.id == id) {
            idNode = inc?.attributes?.drupal_internal__nid;
        }
    });
    return idNode;
}
function getTypeStage(stageId: any, includes: any[]) {
    let type = '';

    includes?.forEach((include: any) => {
        if (include?.id == stageId) {
            type = include?.attributes?.name == 'Estaciones de lider' ? 'stations' : 'stages';
            return type;
        }
    });
    return type;

}
function getStageId(actId: any, includes: any[]) {
    let idA = '';
    includes?.forEach((include: any) => {
        if (include?.id == actId) {
            idA = include?.attributes?.drupal_internal__nid;
        }
    });
    return idA;
}
export function getMedia(mediasData: any[], includes: any[]): any[] {
    let media = {};
    let arrayMedia: any[] = [];
    mediasData?.map((m: any) => {
        includes?.forEach((include: any) => {
            if (include.id == m?.id) {
                switch (include?.type) {
                    case 'media--image':
                        media = {
                            type: 'image',
                            src: gamificationURL + getUrlMedia(includes, include?.relationships?.field_media_image?.data?.id)
                        };
                        break;
                    case 'media--video':
                        media = {
                            type: 'video',
                            src: gamificationURL + getUrlMedia(includes, include?.relationships?.field_media_video_file?.data?.id)
                        };
                        break;
                    case 'media--remote_video':
                        media = {
                            type: 'remote-video',
                            src: include?.attributes?.field_media_oembed_video
                        };
                        break;
                    default:
                        break;
                }
            }
        });
        arrayMedia.push(media)
    })
    return arrayMedia;
}
export function getUrlMedia(includes: any, preMedia: string) {
    let media: string = '';
    includes?.forEach((include: any) => {
        if (include.id == preMedia) {
            media = include?.attributes?.uri?.url || '';
        }
    });
    return media;
}





//Get Media Elements
export function getImage(id: string, includes: any[]): string {
    let image = '';
    includes?.forEach((include: any) => {
        if (include.type == 'file--file' && include.id == id) {
            image = include?.attributes?.uri?.url || '';
        }
    });

    return gamificationURL + image;
}
export function getVideo(id: string, includes: any[]): string {
    let idNode = '';
    let idMedia = '';
    let video = '';
    includes?.forEach((include: any) => {
        if (include.type == 'node--video' && include.id == id) {
            idNode = include?.relationships?.field_video?.data?.id || '';
        }
    });
    includes?.forEach((include: any) => {
        if (include.type == 'media--video' && include.id == idNode) {
            idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
        }
    });
    includes?.forEach((include: any) => {
        if (include.type == 'file--file' && include.id == idMedia) {
            video = include?.attributes?.uri?.url || '';
        }
    });

    return gamificationURL + video;
}
