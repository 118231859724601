import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDetailEventWellnessApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { ButtonLink } from '../../../../modules/links/solve_links';
import { getURL } from '../../../../modules/services/getElements.service';
import OtherEvents from '../other-programs-events/other-events';

const Detail_Events = () => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  let dataPillar = useGetDetailEventWellnessApiQuery(
    window.location.href.split('?')[1],
  ).data;
  var details = getDetailPillars(dataPillar);
  function getDetailPillars(section: any): any {
    var elements = {};
    let idImage = section?.data?.relationships?.field_image?.data?.id || '';
    let idMultimediaVideo = '';
    if (section && section.data) {
      section?.data?.relationships?.field_contenido_multimedia?.data?.forEach(
        (media: any) => {
          idMultimediaVideo = media?.id || '';
        },
      );
    }

    let image = '';
    let node_video = '';
    section?.included?.map((include: any) => {
      if (idImage === include.id) {
        image = apiMainURL + include.attributes.uri.url;
      }
    });
    section?.included?.map((include: any) => {
      if (idMultimediaVideo === include.id) {
        node_video = include.relationships?.field_video?.data?.id;
      }
    });
    let media_video = '';
    section?.included?.map((include: any) => {
      if (node_video === include.id) {
        media_video =
          include?.relationships?.field_media_video_file?.data?.id || '';
      }
    });
    let file_file = '';
    section?.included?.map((include: any) => {
      if (media_video === include.id) {
        file_file = apiMainURL + include.attributes.uri.url;
      }
    });
    elements = {
      title: section?.data?.attributes?.title || '',
      image: image,
      video: file_file,
      description: section?.data?.attributes?.body?.value || '',
      link: section?.data?.attributes.path?.alias || '',
      column: section?.data?.attributes.field_column || '3',
      author: section?.data.attributes.field_autor || '',
      address: section?.data.attributes.field_direccion || '',
      date: section?.data.attributes.field_fecha || '',
      phone: section?.data.attributes.field_telefono || '',
      information: section?.data.attributes.field_informacion || '',
      enroll: getURL(section?.data.attributes.field_enlace_externo?.uri || ''),
      nameButton: section?.data.attributes?.field_enlace_externo?.title || '',
    };

    return elements;
  }

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row main_contests">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/wellness">Bienestar</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/programs-events">Programas y Eventos</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {details.title}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">{details.title}</div>
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-7 mb-4">
                  <img
                    src={details.image}
                    className="img-fluid rounded-4"
                    alt="..."
                  />
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="title-welcome fs-4">
                    Información del Evento
                  </div>
                  <div className="info_programs">
                    {details.information}
                    <ul>
                      {details.author != '' && (
                        <li>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/resources/icon_user.svg`}
                              className="img-fluid"
                              width="20"
                              alt="..."
                            />
                          </span>
                          {details.author}
                        </li>
                      )}
                      {details.address != '' && (
                        <li>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/resources/icon_ubi.svg`}
                              className="img-fluid"
                              width="20"
                              alt="..."
                            />
                          </span>
                          {details.address}
                        </li>
                      )}
                      {details.date != '' && (
                        <li>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/resources/icon_cale.svg`}
                              className="img-fluid"
                              width="20"
                              alt="..."
                            />
                          </span>
                          {details.date}
                        </li>
                      )}
                      {details.phone != '' && (
                        <li>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/resources/icon_tele.svg`}
                              className="img-fluid"
                              width="20"
                              alt="..."
                            />
                          </span>
                          {details.phone}
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="text-center">
                    {details?.enroll && (
                      <ButtonLink
                        url={details?.enroll || ''}
                        name={details.nameButton || ''}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="text-generic-r my-5">{details.description}</div>
            </div>
            <div className="col-12 mb-4">
              <a href="">
                {details.video && (
                  <div className="ratio ratio-21x9">
                    <video
                      src={details.video}
                      width="100%"
                      height="500"
                      controls
                    ></video>
                  </div>
                )}
              </a>
            </div>
            <div className="col-12">
              <OtherEvents />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail_Events;
