import React, { useState } from 'react';
import { domain_country, getDomain } from '../../../../config/domain';

export const TrackingContainer = () => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  return (
    <div>
      <div className="tracking-container">
        <div className="d-table w-100 mb-4">
          <div className="d-table-cell">
            <span className="text-Semi-bd">Octubre 10 de 2021</span>
          </div>
          <div className="d-table-cell text-end">No. 528596</div>
        </div>
        <div className="steps d-flex justify-content-between padding-top-2x padding-bottom-1x">
          <div className="step completed">
            <div className="step-icon-wrap">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-check-tra.svg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <h4 className="step-title">Realizado</h4>
          </div>
          <div className="step completed">
            <div className="step-icon-wrap">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/packing-up.svg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <h4 className="step-title">Empacando</h4>
          </div>
          <div className="step">
            <div className="step-icon-wrap">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/claim-it.svg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <h4 className="step-title">Reclámalo</h4>
          </div>
          <div className="step">
            <div className="step-icon-wrap">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/delivered.svg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <h4 className="step-title">Entregado</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
