import classnames from 'classnames';
import { useEffect, useState } from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
} from 'reactstrap';
import { GetBodyInnerHTML } from '../../../../../modules/services/getElements.service';
import CarouselView from '../../../../carousel/carousel';
import MediaComponent from '../../../../mediaComponent/media-component';

export const TabDetailPillar = (props: any) => {
  const [activeTab, setActiveTab] = useState('1');
  const [miniaturas, setMiniaturas] = useState(props?.miniaturas);
  const [data, setData] = useState(props.content);
  // console.log('MINIATURAS EN DETALLE DE REFERENCIAS:  ', miniaturas);
  const toggle: any = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {}, []);
  return (
    <div className="row">
      <CarouselView content={props.banners} />
      <Nav tabs className="item-tam">
        {props?.miniaturas?.map((min: any, index: number) => {
          return (
            <NavItem key={index.toString()} className="nav-item ">
              <NavLink
                className={classnames({
                  active: activeTab === (index + 1).toString(),
                })}
                onClick={() => {
                  toggle((index + 1).toString());
                }}
              >
                <img
                  src={min.src}
                  alt=""
                  className="miniature"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={min.title}
                />
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {props?.content?.map((el: any, index: number) => {
          return (
            <TabPane tabId={'' + (index + 1)} key={index}>
              <br />
              <br />
              <div className="title-welcome text-center">
                <h2>{el.title}</h2>
              </div>
              <GetBodyInnerHTML
                className="text-generic-r my-5"
                content={el.description}
              ></GetBodyInnerHTML>
              <Row>
                <Col sm="12">
                  <div className="row justify-content-center align-items-center align-content-md-around">
                    <div className="col-md-12">
                      <img src={el.BigImage} alt="" className="img-fluid" />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="title-welcome text-center">
                <h2>{el.title} EN IMÁGENES</h2>
                <MediaComponent content={el?.mediaTab} />
              </div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};
