import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import CarouselView from '../../carousel/carousel';
import { domain_country, getDomain } from '../../../config/domain';
import {
  useGetAllProductsQuery,
  useGetBannerSectionApiQuery,
} from '../../../api/userSession';
import { landingId } from '../../../modules/services/sections';
import {
  getCategoryOrTagsProductEcommerce,
  getMediaProductEcommerce,
  getProducts,
} from '../../../modules/services/getElements.service';
import CardItemProduct from './card-item-product';
import LoadSpinner from '../../load-spinner/load-spinner';
import Alerts from '../../../modules/services/alerts';

export const EcommerceCatalogue = (props: any) => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const dataProducts = useGetAllProductsQuery('')?.data;

  const products = getProducts(dataProducts);

  // Modal open state
  const [modal, setModal] = React.useState(false);
  const [nav, setNav] = React.useState('Cervezas');

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  function handleNav(name: string) {
    setNav(name);
  }

  if (products.length > 0)
    return (
      <div>
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/landing">{domainData.name}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/e-commerce/landing">Tu tiendita</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {nav}
                    </li>
                  </ol>
                </nav>
                <div className="title-welcome">{nav}</div>
                <div className="d-flex justify-content-between justify-content-md-end py-0">
                  <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 me-md-2 buttons-actions-ecommerce">
                    <div className="link-geo ms-md-3" onClick={toggle}>
                      <div>
                        <i className="bi bi-geo-alt"></i>
                      </div>
                      <div className="text">
                        {' '}
                        Recoger en
                        <br />
                        <span>Guayaquil</span>
                      </div>
                    </div>
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      modalTransition={{ timeout: 1000 }}
                      className="modal-dialog-centered"
                    >
                      <ModalBody>
                        Selecciona el lugar donde quieres reclamar
                        <span>
                          La disponibilidad de los productos varía según el
                          punto de elección
                        </span>
                        <div>
                          <select>
                            <option value="">Seleccionar...</option>
                            <option value="1"> Cervecería Unión </option>
                            <option value="2"> Cervecería de Tocancipá </option>
                            <option value="3">
                              {' '}
                              Cervecería de Barranquilla{' '}
                            </option>
                            <option value="4"> Cervecería del Valle </option>
                            <option value="5">
                              {' '}
                              Cervecería de Bucaramanga{' '}
                            </option>
                            <option value="6"> Cervecería de Boyacá </option>
                            <option value="7"> Edificio 127 </option>
                            <option value="8"> Impresora del Sur </option>
                            <option value="9"> Indugral </option>
                            <option value="10"> Malteria Tibitó </option>
                            <option value="11"> Malteria Tropical </option>
                            <option value="12">
                              {' '}
                              R. Centro CD San Fernando{' '}
                            </option>
                            <option value="13"> R. Centro Calle 22 </option>
                            <option value="14"> R. Centro Amerícas </option>
                            <option value="15"> R. Centro AutoSur </option>
                            <option value="16"> R. Andes CD Armenia </option>
                          </select>
                        </div>
                      </ModalBody>
                    </Modal>
                  </div>
                  <div className="col-6 col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0">
                    <div className="col-12">
                      <div className="row" style={{ zIndex: 99 }}>
                        <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0"></div>
                        <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                          <div className="link-opt ">
                            <Link
                              to="/e-commerce/shopping-cart"
                              style={{ zIndex: 999 }}
                            >
                              <i className="bi bi-cart3"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                          <div className="link-opt">
                            <Link
                              to="/e-commerce/favorites"
                              style={{ zIndex: 999 }}
                            >
                              <i className="bi bi-heart"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row col-12 tabs-section tabs-news left-section-catalogue">
                      <div className=" col col-lg-8 col-xl-8  start-navs">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li
                            className="nav-item col-6 col-lg-2 col-xl-2"
                            role="presentation"
                          >
                            <button
                              className="nav-link active"
                              id="beers-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#beers"
                              type="button"
                              role="tab"
                              aria-controls="beers"
                              aria-selected="true"
                              onClick={() => handleNav('Cervezas')}
                            >
                              Cervezas
                            </button>
                          </li>
                          <li
                            className="nav-item col-6 col-lg-2 col-xl-2"
                            role="presentation"
                          >
                            <button
                              className="nav-link"
                              id="drinks-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#drinks"
                              type="button"
                              role="tab"
                              aria-controls="drinks"
                              aria-selected="false"
                              onClick={() => handleNav('Bebidas')}
                            >
                              Bebidas
                            </button>
                          </li>
                          <li
                            className="nav-item col-6 col-lg-2 col-xl-2"
                            role="presentation"
                          >
                            <button
                              className="nav-link"
                              id="snacks-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#snacks"
                              type="button"
                              role="tab"
                              aria-controls="snacks"
                              aria-selected="false"
                              onClick={() => handleNav('Snacks')}
                            >
                              Snacks
                            </button>
                          </li>
                          <li
                            className="nav-item col-6 col-lg-2 col-xl-2"
                            role="presentation"
                          >
                            <button
                              className="nav-link"
                              id="foods-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#foods"
                              type="button"
                              role="tab"
                              aria-controls="foods"
                              aria-selected="false"
                              onClick={() => handleNav('Alimentos')}
                            >
                              Alimentos
                            </button>
                          </li>
                          <li
                            className="nav-item col-6 col-lg-2 col-xl-2"
                            role="presentation"
                          >
                            <button
                              className="nav-link"
                              id="gifts-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#gifts"
                              type="button"
                              role="tab"
                              aria-controls="gifts"
                              aria-selected="false"
                              onClick={() => handleNav('Regalitos')}
                            >
                              Regalitos
                            </button>
                          </li>
                        </ul>
                      </div>

                      <div className="tab-content mt-5" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="beers"
                          role="tabpanel"
                          aria-labelledby="beers-tab"
                        >
                          <div className=" ">
                            <div className="row">
                              {products.map((elem: any, index: number) => {
                                if (elem.type == 'Cervezas')
                                  return (
                                    <div
                                      className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product"
                                      key={index}
                                    >
                                      <CardItemProduct
                                        content={elem}
                                        className="card card-product"
                                      ></CardItemProduct>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="drinks"
                          role="tabpanel"
                          aria-labelledby="drinks-tab"
                        >
                          <div className=" ">
                            <div className="row">
                              {products.map((elem: any, index: number) => {
                                if (elem.type == 'Bebidas')
                                  return (
                                    <div
                                      className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product"
                                      key={index}
                                    >
                                      <CardItemProduct
                                        content={elem}
                                        className="card card-product"
                                      ></CardItemProduct>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="snacks"
                          role="tabpanel"
                          aria-labelledby="snacks-tab"
                        >
                          <div className=" ">
                            <div className="row">
                              {products.map((elem: any, index: number) => {
                                if (elem.type == 'Snacks')
                                  return (
                                    <div
                                      className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product"
                                      key={index}
                                    >
                                      <CardItemProduct
                                        content={elem}
                                        className="card card-product"
                                      ></CardItemProduct>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="foods"
                          role="tabpanel"
                          aria-labelledby="foods-tab"
                        >
                          <div className=" ">
                            <div className="row">
                              {products.map((elem: any, index: number) => {
                                if (elem.type == 'Alimentos')
                                  return (
                                    <div
                                      className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product"
                                      key={index}
                                    >
                                      <CardItemProduct
                                        content={elem}
                                        className="card card-product"
                                      ></CardItemProduct>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="gifts"
                          role="tabpanel"
                          aria-labelledby="gifts-tab"
                        >
                          <div className=" ">
                            <div className="row">
                              {products.map((elem: any, index: number) => {
                                if (elem.type == 'Regalitos')
                                  return (
                                    <div
                                      className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product"
                                      key={index}
                                    >
                                      <CardItemProduct
                                        content={elem}
                                        className="card card-product"
                                      ></CardItemProduct>
                                    </div>
                                  );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <LoadSpinner></LoadSpinner>
      // <div className="card-product">
      //     <img src="..." className="card-img-top" alt="..." />
      //     <div className="card-body">
      //         <h5 className="card-title placeholder-glow">
      //             <span className="placeholder col-6"></span>
      //         </h5>
      //         <p className="card-text placeholder-glow">
      //             <span className="placeholder col-7"></span>
      //             <span className="placeholder col-4"></span>
      //             <span className="placeholder col-4"></span>
      //             <span className="placeholder col-6"></span>
      //             <span className="placeholder col-8"></span>
      //         </p>
      //         <a href="#" className="btn btn-primary disabled placeholder col-6"></a>
      //     </div>
      // </div>
    );
  }
};
export default EcommerceCatalogue;
