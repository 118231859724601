import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  deleteSession,
  getUserData,
  useGetBannerSectionApiQuery,
  useGetListEventsWellnessPreviewApiQuery,
} from '../../api/userSession';
import { domain_country, getDomain } from '../../config/domain';
import { apiMainURL } from '../../config/environment';
import { store } from '../../modules/appState/store/store';
import ImageUpload from './rotateImage';
import { bannersInSection, getImage } from '../../modules/services/getElements.service';
import { profileId } from '../../modules/services/sections';
import CarouselView from '../carousel/carousel';

export const Profile = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const dataBannersApi = useGetBannerSectionApiQuery(profileId).data;
  const dataEvents = useGetListEventsWellnessPreviewApiQuery(profileId).data;
  const events: any[] = getEventsPreview(dataEvents);
  function getEventsPreview(dataEvents: any): any[] {
    let events: any[] = [];
    dataEvents?.data?.map( (event: any) => {
      console.log("Event:  ", event || '');
      let image =  getImage(event?.relationships?.field_imagen_preview?.data?.id, dataEvents.included);
      console.log("Image:  ", image);
      events.push({
        date: event?.attributes?.field_fecha || '',
        name: event?.attributes?.title || '',
        image: image || `${process.env.PUBLIC_URL}/customs/${domain.color}/bg-event.jpg`,
        id: event?.id||''
      })
    });
    console.log("Eventos formados:  ", events);
    return events
  }
  var banners = bannersInSection(dataBannersApi);
  const storeApp = store;
  const [user2, setUser2] = useState({
    vp: '',
    name: '',
    sharpId: '',
    email: '',
    nationalId: '',
    position: '',
    image: '',
  });

  useEffect(() => {
    getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
      .then((data) => {
        setUser2({
          name: data?.profile?.name || '',
          email: data?.profile?.email || '',
          nationalId: data?.profile?.national_id || '',
          position: data?.profile?.position_name || '',
          sharpId: data?.profile?.sharp_id || '',
          vp: data?.profile?.vp || '',
          image: data?.profile?.user_image,
        });

        store.dispatch({
          type: data?.profile?.user_image || '',
        });
        if (data?.profile?.position_name)
          localStorage.setItem('position', data?.profile?.position_name || '');
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, []);
  let sharp = localStorage.getItem('sharp') || '';

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Perfil
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <div className="banner_profile">
                <div className="cover-page">
                  <CarouselView content={banners} />
                </div>
                <div className="main-profile">
                  <ImageUpload></ImageUpload>
                  <div className="text-profile">
                    <span>{`${user2.name}`}</span>
                    <span>{`Id: ${user2.sharpId}`}</span>
                    <span>{`${user2.vp}`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="overflow-hidden py-4 px-2">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5">
                  <div className="col">
                    <div className="box-info">
                      TUS DATOS
                      <ul>
                        <li>
                          <span>Correo corporativo</span>
                          {`${user2.email}`}
                        </li>
                        <li>
                          <span> Número de identificación</span>
                          {`${user2.nationalId}`}
                        </li>
                        <li>
                          <span>Posición</span>
                          {`${user2.position}`}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col">
                    <div className="box-info">
                      <div className="overlay-comingsoon">
                        <span>próximamente</span>
                      </div>
                      TUS CONCURSOS
                      <div className="min-banner">
                        <img
                          src={`${process.env.PUBLIC_URL}/customs/${domain.color}/min_banner.jpg`}
                          className="img-fluid"
                          alt="banner"
                        />
                      </div>
                      <div className="min-banner">
                        <img
                          src={`${process.env.PUBLIC_URL}/customs/${domain.color}/min_banner-2.jpg`}
                          className="img-fluid"
                          alt="banner 2"
                        />
                      </div>
                      <div className="text-center d-none">
                        <Link to="/" className="text-l">
                          Ver Todos Los Concursos
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="box-info">
                      {/* <div className="overlay-comingsoon">
                        <span>próximamente</span>
                      </div> */}
                      TUS EVENTOS
                      {events&&events?.map((e: any, indexEvent: number) => {
                        return (
                         <Link key={indexEvent} to={`/welfare-events/${e?.id}`}>
                           <div
                            
                            className="box-events"
                            style={{
                              backgroundImage: `url(${e?.image})`,
                            }}
                          >
                            <div>
                           {e?.name||''}
                              <span className="bi bi-calendar-event">
                               {e?.date||''}
                              </span>
                            </div>
                          </div>
                         </Link>
                        )
                      })}
                      {/* 
                      <div
                        className="box-events"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/customs/${domain.color}/bg-event.jpg)`,
                        }}
                      >
                        <div>
                          Charla sostenibilidad
                          <span className="bi bi-calendar-event">
                            Sept 20 - 3 pm
                          </span>
                        </div>
                      </div>
                      <div
                        className="box-events"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/customs/${domain.color}/bg-event.jpg)`,
                        }}
                      >
                        <div>
                          Charla sostenibilidad
                          <span className="bi bi-calendar-event">
                            Sept 20 - 3 pm
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
