import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiMainURL } from '../../../../../config/environment';
import { getImage } from '../../../../../modules/services/getElements.service';

export const Tarjetas = (props: any) => {
  const tarjetas: any[] = [];
  const [cardsData, setCardsData] = useState<any[]>([]);
  const t: any[] = props.content;
  const cards = getCards();
  async function getCards() {
    var myHeaders: Headers = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (t && t.length > 0 && cardsData.length == 0) {
      for (let tarjeta of props?.content) {
        await fetch(
          `${apiMainURL}/jsonapi/node/tarjeta/${tarjeta.id}?include=field_imagen_destacada, field_banners, field_banners.field_image`,
          requestOptions,
        )
          .then((response) => response.text())
          .then((result: any) => {
            let tar = JSON.parse(result);
            tarjetas.push({
              title: tar?.data?.attributes?.title || '',
              image: getImage(
                tar?.data?.relationships?.field_imagen_destacada?.data?.id,
                tar?.included,
              ),
              id: tar?.data?.id || '',
            });
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
      setCardsData(tarjetas);
    }
  }
  useEffect(() => {}, []);
  if (cardsData.length > 0)
    return (
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
        {cardsData?.map((tarjeta: any, index: number) => {
          return (
            <div
              className="my-4 col-sm-12 col-md-6 col-lg-6 col-xl-4 ml-auto"
              key={index}
            >
              <div className="card cards-pillars text-white ">
                <Link to={`/pillars/details?${tarjeta.id}`}>
                  <img
                    src={tarjeta.image}
                    className="img-card"
                    alt="wellness-section"
                  />
                  <div className="card-content">
                    <h5 className="card-title">{tarjeta.title}</h5>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    );
  else return <></>;
};
