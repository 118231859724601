import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  deleteSession,
  useGetCountViewsNewsFeedApiQuery,
  useGetDetailNewsFeedApiQuery,
} from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { ButtonLink } from '../../../../modules/links/solve_links';
import {
  getImage,
  getAllMultimedia,
  GetBodyInnerHTML,
  toLocaleDate,
} from '../../../../modules/services/getElements.service';
import LoadSpinner from '../../../load-spinner/load-spinner';
import MediaComponent from '../../../mediaComponent/media-component';
import { RelatedContent } from '../related-content/related-content-news';

export const DetailNews = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  const dataNews = useGetDetailNewsFeedApiQuery(
    window.location.href.split('?')[1],
  )?.data;
  const detail = getDetailNews(dataNews);
  const save = saveViews(dataNews?.data?.attributes?.drupal_internal__nid);
  let views = useGetCountViewsNewsFeedApiQuery(
    dataNews?.data?.attributes?.drupal_internal__nid || '0',
  )?.data;

  function saveViews(id: string) {
    if (id) {
      var myHeaders = new Headers();
      myHeaders.append(
        'authorization',
        `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
      );
      myHeaders.append('Content-Type', 'application/json');

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(
        `${apiMainURL}/api/${id}/add-to-planeta-views?_format=json`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {})
        .catch((error) => console.log('error', error));
    }
  }
  function getDetailNews(section: any): any {
    let news: any;
    let idImage = section?.data?.relationships?.field_image?.data?.id || '';
    let image: string = getImage(idImage, section?.included);
    let media: any[] = getAllMultimedia(
      section?.data?.relationships?.field_contenido_multimedia,
      section?.included,
    );

    news = {
      title: section?.data?.attributes?.title || '',
      image: image,
      video: '',
      media: media,
      description: section?.data?.attributes?.body?.processed || '',
      description1: section?.data?.attributes?.field_informacion || '',
      descImage:
        section?.data?.relationships?.field_image?.data?.meta?.title || '',
      link: '/',
      column: '3',
      author: '',
      address: '',
      date: toLocaleDate(section?.data?.attributes?.created) || '',
      phone: '',
      information: section?.data?.attributes?.field_informacion_2 || '',
      enroll: '',
      nameButton: '',
      category: section?.data?.attributes?.field_categoria[0] || '',
    };
    /*         console.log("NOTICIA FORMADA:  ", news); */
    return news;
  }
  if (dataNews)
    return (
      <div>
        <div className="main-content">
          <div className="container-fluid">
            <div className="row main_contests">
              <div className="col-12 content-news">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/landing">{domainData.name}</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/news-feed">Newsfeed</Link>
                    </li>
                    <li
                      className="breadcrumb-item active bold"
                      aria-current="page"
                    >
                      {detail.title.substr(0, 20)}...
                    </li>
                  </ol>
                </nav>
                <div className="article-date">
                  <div className="textDate">
                    {' '}
                    <strong> ARTÍCULO:</strong>
                    {' ' + (detail.date || '')}
                  </div>
                </div>
                <div className="title-welcome">{detail.title}</div>
                <div className="col-md-6 col-lg-3">
                  <div className="row">
                    <div className="row col-md-6 col-lg-6  iconsDate ">
                      <div className=" ">
                        <i className="bi bi-eye"></i>
                        {'  ' + (views?.views || 0) + '    '}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 categoryNews">
                      <button className="btn btn-warning btn-category">
                        <span className="text-category">{detail.category}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6 mb-4 ">
                    <img
                      src={detail.image}
                      className="row img-fluid rounded-4 align-image"
                      alt="..."
                    />
                    <p className="row descImage">
                      <span>{detail.descImage}</span>
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-5">
                    <div className="text-generic-r">
                      <GetBodyInnerHTML content={detail.description} />
                    </div>
                  </div>
                </div>
                <div className="text-generic-r my-5">{detail.description1}</div>
                <div className="mb-4">
                  {detail.media && <MediaComponent content={detail?.media} />}
                </div>
                <div className="text-generic-r my-5">{detail.information}</div>
                <RelatedContent category={detail.category} />
              </div>

              {/* <div className="col-12">
              <OtherPrograms idActive={window.location.href.split('?')[1]} />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
