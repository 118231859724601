import React, { useEffect, useState } from 'react';
import { apiMainURL } from '../../../../config/environment';

export default function ProductItemOrder(props: any) {
  const product = props?.product;
  const f = props?.update;
  // console.log("PRODUCTO EN ITEM ORDER:   ", product);
  const calification: any[] = ['1', '2', '3', '4', '5'];
  const [selectedOption, setSelectedOption] = useState({
    quantity: 1,
    text: 'Cantidad: 1',
  });
  const [show, setShow] = useState(true);
  const options = [
    { quantity: 1, text: 'Cantidad: 1' },
    { quantity: 2, text: 'Cantidad: 2' },
    { quantity: 3, text: 'Cantidad: 3' },
    { quantity: 4, text: 'Cantidad: 4' },
  ];
  useEffect(() => {
    setSelectedOption({
      quantity: product?.quantity,
      text: 'Cantidad: ' + product?.quantity,
    });
  }, []);
  function handleChangeSelected(event: any, idProduct: string) {
    // console.log("ELEMENTO CAMBIADO:   ", event);
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');
    if (idProduct && event) {
      var raw = JSON.stringify({
        quantity: parseInt(event) || 0,
      });

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(
        `${apiMainURL}/api/ecommerce/update-item/${idProduct}`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => console.log('RESULTADO DEL UPDATE:   ', result))
        .catch((error) => console.log('error', error));
    }

    if (product?.internID && parseInt(product?.quantity) && product?.price)
      f(product?.internID, parseInt(product?.quantity), product?.price);
    setSelectedOption({ quantity: event, text: 'Cantidad: ' + event });
  }
  function handlerDeleteItem(idProduct: string, idOrder: string): void {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    if (idProduct && idOrder) {
      var raw = JSON.stringify({
        item_id: idOrder,
      });

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(
        `${apiMainURL}/api/ecommerce/delete-item/${idProduct}`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          console.log('DELETE Response:  ', result);
          let error = JSON.parse(result)?.error;
          if (error && error?.length > 0) {
            console.log('ERROR.!   ', error);
          } else {
            console.log('SUCCESS');
            f(product?.internID, 0, product?.price);
            setShow(false);
          }
        })
        .catch((error) => console.log('error', error));
    }
  }
  if (show) {
    return (
      <div className="row mb-4">
        <div className="col-3">
          <img
            className="full-image"
            src={product?.gallery[0]?.content?.src || ''}
            alt={'product-'}
          />
        </div>
        <div className="col-6">
          <h5>{product?.name || ''}</h5>
          <p className="card-text">
            {calification.map((cal: any, indexC: number) => {
              return (
                <img
                  src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                  alt=""
                  className="star-calification"
                  key={indexC}
                />
              );
            })}
          </p>
          <p className="title-vol-product">{product?.volume || ''}</p>
          <select
            className="btn-outlined-secondary btn-cant-payment dropdown-toggle rounded-pill fw-bolder sel-q"
            value={selectedOption.quantity}
            onChange={(e) =>
              handleChangeSelected(e.target.value, product?.internID)
            }
          >
            {options.map((o: any, io: number) => (
              <option key={io} value={o.quantity} className="">
                {o.quantity}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2 close-section-cart">
          <div className="row">
            <div className="col-8">
              <p className="price-product-cart">Precio</p>
              <p className="price-cart-order">{product?.price || ''}</p>
            </div>
            <div className="col-4">
              <button
                className="close-element-product"
                onClick={() =>
                  handlerDeleteItem(
                    product?.internID,
                    product?.ecommerce_orders_id,
                  )
                }
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
}
