import React, { useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGetEventsListQuery, useGetListEventsWellnessApiQuery } from '../../../api/userSession';
import { apiMainURL } from '../../../config/environment';
import { secondsToDate } from '../../../tools/seconds2time';
import EventList from './event-list';
import FilterControl from './FilterControl';
import { actionCases, eventTypes, initialState, reducer } from './reducer';

const WelfareEvents = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);


  const offset = urlParams.get('offset')||'0';
  const eventsData = useGetEventsListQuery(offset).data;
  const loc=useLocation();

  useEffect(() => {
    if (eventsData) {
      const categories: string[] = [];
      const newEvents: eventTypes[] = eventsData?.data.map(
        ({
          id: eventId,
          attributes: {
            field_fecha,
            title,
            field_direccion,
            field_hora_inicio,
            field_hora_final,
          },
          relationships: {
            field_imagen_destacada: {
              data: { id: imageId },
            },
            field_categoria_evento: { data: categoryData },
          },
        }: {
          id: string;
          attributes: {
            field_fecha: string;
            title: string;
            field_direccion: string;
            field_hora_inicio: number;
            field_hora_final: number;
          };
          relationships: {
            field_imagen_destacada: { data: { id: string } };
            field_categoria_evento: { data?: { id: string } };
          };
        }) => {
          const category = eventsData.included.filter(
            (cat: any | null) => cat.id === (categoryData?.id || ''),
          )[0]?.attributes.name;

          categories.push(category);

          return {
            id: eventId,
            date: field_fecha && new Date(field_fecha + 'T00:00:00'),
            title,
            place: field_direccion,
            image: eventsData.included.reduce(
              (acc: string, cur: any) =>
                imageId === cur.id ? `${acc}${cur.attributes.uri.url}` : acc,
              apiMainURL,
            ),
            category,
            hours: [
              secondsToDate(field_hora_inicio),
              secondsToDate(field_hora_final),
            ],
          };
        },
      );

      dispatch({
        type: actionCases.SET_EVENTS,
        payload: {
          events: newEvents,
          categories: [...new Set(categories)].map((cat) => ({
            label: cat || 'Any',
            value: cat,
          })),
        },
      });
    }
  }, [eventsData,loc]);

  const onInputChange = (
    {
      target: { value },
    }: {
      target: { value: string };
    },
    action: actionCases,
  ) => {
    dispatch({ type: action, payload: { value } });
  };
  const onFilterSubmit = () => {
    dispatch({ type: actionCases.CHANGE_EVENTS_TO_SHOW });
  };

  return (
    <div className="main-content">
      <div className="container-fluid">
        <div
          className="d-flex w-auto h-25 p-4"
          style={{ backgroundColor: '#f1f1f1' }}
        >
          <FilterControl
            {...{ onInputChange, onFilterSubmit }}
            categories={state.categories}
          />
        </div>
        <div className="d-flex flex-column gap-3" style={{ marginTop: '4rem' }}>
          <EventList events={state.eventsToShow!} />
        </div>
        <nav style={{ marginTop: '4rem' }} aria-label="Page navigation example">
          <ul className="pagination justify-content-center gap-1">
          {eventsData?.links?.prev &&(
             <li className="page-item">
              {/* <a className="page-link text-black rounded-pill" href="#">
                Next
              </a> */}
              <Link className="page-link text-black rounded-pill" to={`/welfare-events?offset=${((+offset-4)>=0?((+offset-4)):0)||'0'}`}>Prev</Link>
           </li>
         )}
            <li className="page-item">
              <a className="page-link text-black rounded-circle" href="#">
              {(+offset/4)+1}
              </a>
            </li>
        
            {eventsData?.links?.next &&(
             <li className="page-item">
              {/* <a className="page-link text-black rounded-pill" href="#">
                Next
              </a> */}
              <Link className="page-link text-black rounded-pill" to={`/welfare-events?offset=${(+offset+4)||'4'}`}>Next</Link>
           </li>
         )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default WelfareEvents;
