import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContestResults from './results';
import { getDomain } from '../../../../config/domain';
import { useGetQuestionsQuery } from '../../../../api/userSession';
import CarouselView from '../../../carousel/carousel';

export const ContestQuestions = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  if (!localStorage.getItem('start')) {
    let inicio: number = new Date().valueOf();
    localStorage.setItem('start', inicio.toString());
  }
  useEffect(() => {}, []);
  interface answers {
    answerText: string;
    image: string;
    idAnswer: string;
  }

  interface question {
    questionText: string;
    questionMedia: {
      type: string;
      src: string;
    };
    idQuestion: string;
    answerOptions: answers[];
  }
  //TODO:¨Numero de concurso
  const questionsData = useGetQuestionsQuery(
    window.location.href.split('?')[1],
  );
  const questions2 = getQuestions(questionsData?.data);
  const title_info = decodeURI(window.location.href.split('?')[2]);

  function getQuestions(questionsData: any): any[] {
    let start = new Date().valueOf();
    let q: question[] = [];
    let a: answers[] = [];
    if (questionsData && questionsData?.questions) {
      let jsonObject = questionsData?.questions;

      for (var json in jsonObject) {
        a = [];
        let options1: any[] = jsonObject[json]['options'];

        for (let elem in options1) {
          let option = jsonObject[json]['options'][elem] || '';
          a.push({
            idAnswer: option.id || '',
            answerText: option.title || '',
            image: option.image || '',
          });
        }
        q.push({
          idQuestion: jsonObject[json].id || '',
          questionText:
            jsonObject[json].title.toString().replace('&nbsp;', ' ') || '',
          questionMedia: {
            type: jsonObject[json].banner.type,
            src: jsonObject[json].banner.url,
          },
          answerOptions: a,
        });
      }
    }
    let end = new Date().valueOf();
    let diferencia: any = end - start;
    return q;
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [arrayResults, setArrayResults] = useState<any[]>([]);
  const handleAnswerOptionClick = (question: any, answer: any) => {
    setArrayResults([
      ...arrayResults,
      {
        idQ: question.idQuestion || '',
        idA: answer.idAnswer || '',
      },
    ]);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions2.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/competitions">Concursos</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {title_info || ''}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-12">
              <div className="app competitions-questions">
                {showScore == false && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <CarouselView
                          content={[
                            {
                              altText: '',
                              caption: '',
                              link: '',
                              src: {
                                type: questions2[
                                  currentQuestion
                                ]?.questionMedia?.type.includes('image')
                                  ? 'image'
                                  : 'video',
                                src:
                                  questions2[currentQuestion]?.questionMedia
                                    ?.src || '',
                              },
                            },
                          ]}
                        />
                      </div>
                      <div className="col-md-6 mx-auto text-center mt-4">
                        <div className="question-section mb-4">
                          <div className="question-count">
                            <span>Question {currentQuestion + 1}</span>/
                            {questions2.length}
                          </div>
                          <div className="question-text">
                            {questions2[currentQuestion]?.questionText || ''}
                          </div>
                        </div>
                        <div className="answer-section row">
                          {/* {questions2[currentQuestion]?.answerOptions.map((answerOption: any, index:number) => (
                                                            <div className='col-md-6' key={index}>
                                                                <button
                                                                    onClick={() => handleAnswerOptionClick(questions2[currentQuestion], answerOption)}>{answerOption?.answerText}</button>
                                                            </div>
                                                        ))} */}
                          {questions2[currentQuestion]?.answerOptions.map(
                            (answerOption: any, index: number) => (
                              <div className="col-md-6" key={index}>
                                {answerOption?.image && (
                                  <div
                                    className="answer-image"
                                    onClick={() =>
                                      handleAnswerOptionClick(
                                        questions2[currentQuestion],
                                        answerOption,
                                      )
                                    }
                                  >
                                    <img src={answerOption?.image} />
                                  </div>
                                )}
                                {!answerOption?.image && (
                                  <button
                                    onClick={() =>
                                      handleAnswerOptionClick(
                                        questions2[currentQuestion],
                                        answerOption,
                                      )
                                    }
                                  >
                                    {answerOption?.answerText
                                      ?.toString()
                                      .replace('&nbsp;', ' ')}
                                  </button>
                                )}
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {showScore == true && (
                  <ContestResults
                    score={score}
                    total={questions2.length}
                    result={arrayResults}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContestQuestions;
