import React from 'react';
import FeatureEvent from './feature-event';

type featureEventListProps = {
  events: {
    id: string;
    date: Date;
    hours: string[];
    category: string;
    title: string;
    place: string;
    image: string;
    url: string;
  }[];
};

const FeatureEventList = ({ events }: featureEventListProps) => {
  return (
    <div className="p-4 w-full rounded" style={{ backgroundColor: '#eeeeee' }}>
      <p className="fw-bold fs-5">Feature Event</p>
      {events.map((event) => (
        <FeatureEvent
          key={`feature-event-${event.date}-${event.title}-${event.place}`}
          {...event}
        />
      ))}
    </div>
  );
};

export default FeatureEventList;
