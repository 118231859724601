import { Link } from 'react-router-dom';
import { useGetRelatedNewsQuery } from '../../../../api/userSession';
import { apiMainURL } from '../../../../config/environment';

export const RelatedContent = (props: any) => {
  const category: string = props.category;
  const dataMostRelevantNews = useGetRelatedNewsQuery(category);
  const news = getRelevantNews(dataMostRelevantNews?.data);
  function getRelevantNews(section: any): any[] {
    let news: any[] = [];
    section?.forEach((element: any) => {
      news.push({
        image: apiMainURL + element.field_imagen_destacada || '',
        title: element.title || '',
        description: element.body || '',
        date: element.created || '',
        id: element.uuid || '',
      });
    });
    return news;
  }
  if (news.length > 0)
    return (
      <>
        <div className="title-welcome fs-3">Artículos Relacionados</div>
        <div className="overflow-hidden py-4 px-2 box-programs">
          <div className="row row-cols-1 row-cols-sm-2">
            {news.map((el: any, index: number) => {
              return (
                <div
                  className="col col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4"
                  key={index}
                >
                  <Link className="text-black" to={'/detail-news?' + el.id}>
                    <div className="card shadow-sm">
                      <img
                        src={el.image}
                        className="relevant-images "
                        alt="..."
                      />
                      <div className="card-body">
                        <span className="date-news">{el.date}</span>
                        <h5 className="card-title mt-4">
                          {el.title.substr(0, 36)}...
                        </h5>
                        <p className="card-text">
                          {el.description.substr(0, 100)}...
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  else return <></>;
};
