import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDetailWellnessPillarApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import {
  bannersInSection2Form,
  GetBodyInnerHTML,
} from '../../../../modules/services/getElements.service';
import CarouselView from '../../../carousel/carousel';
import LoadSpinner from '../../../load-spinner/load-spinner';
import { Tarjetas } from './media-pillars/tarjetas';

export const DetailPilar = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const dataPillar = useGetDetailWellnessPillarApiQuery(
    window.location.href.split('?')[1],
  ).data;
  /*   console.log('DATA API PILLARS DESDE DETAIL Pillars:  ', dataPillar); */
  const details = getDetailPillars(dataPillar);
  const banners = bannersInSection2Form(dataPillar);

  function getDetailPillars(section: any): any {
    var elements = {};
    let ids_Infografia: string[] = [];
    let infografias: any[] = [];
    let idsResourcesInfografia: string[] = [];
    let tarjetas: any[] = [];

    if (section?.data[0]) {
      section?.data[0].relationships.field_tarjeta?.data?.forEach(
        (tarjeta: any) => {
          tarjetas.push(tarjeta);
        },
      );
      section?.data[0].relationships.field_contenido_multimedia.data.forEach(
        (media: any) => {
          if (media.type == 'node--infografia') {
            ids_Infografia.push(media.id);
            /*  console.log("IDS INFOGRAFIAS,   ", ids_Infografia); */
          }
        },
      );
    }

    ids_Infografia.map((idInfo: string, indexInfo: number) => {
      section?.included?.map((include: any) => {
        if (idInfo === include.id) {
          let idResource = include.relationships.field_image.data.id;
          idsResourcesInfografia.push(idResource);
        }
      });
    });

    idsResourcesInfografia.map((id: string) => {
      section?.included?.map((resource: any) => {
        if (resource.id == id) {
          /*  console.log(
            'Ruta de infografia:    ',
            apiMainURL + resource.attributes.uri.url,
          ); */
          infografias.push(apiMainURL + resource.attributes.uri.url);
          /*  console.log("Array de Infografias:  ", infografias); */
        }
      });
    });
    section?.data?.map((result: any, i: number) => {
      /*  console.log('Result. Dentro de getDetail Pillars', result); */
      let idImage = result.relationships.field_image.data.id;
      let idMultimediaVideo = '';
      result.relationships.field_contenido_multimedia.data.forEach(
        (media: any) => {
          idMultimediaVideo = media.id;
        },
      );
      let image = '';
      let node_video = '';
      section?.included?.map((include: any) => {
        if (idImage === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });
      section?.included?.map((include: any) => {
        if (idMultimediaVideo === include.id) {
          node_video = include.relationships?.field_video?.data?.id;
        }
      });
      let media_video = '';
      section?.included?.map((include: any) => {
        if (node_video === include.id) {
          media_video = include.relationships.field_media_video_file.data.id;
        }
      });
      let file_file = '';
      section?.included?.map((include: any) => {
        if (media_video === include.id) {
          file_file = apiMainURL + include.attributes.uri.url;
        }
      });
      localStorage.setItem('backIdPillar', window.location.href.split('?')[1]);
      elements = {
        title: result.attributes.field_titulo_contenido || '',
        subtitle: result.attributes.field_subtitulo || '',
        image: image,
        video: file_file,
        description: result.attributes?.body?.value || '',
        link: result.attributes.path?.alias || '',
        column: result.attributes.field_column || '3',
        infographics: infografias || [],
        tarjetas: tarjetas,
        backId: window.location.href.split('?')[1],
      };
    });
    return elements;
  }
  if (dataPillar)
    return (
      <div>
        <section className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/landing">{domainData.name}</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/wellness">Bienestar</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {details.title}
                    </li>
                  </ol>
                </nav>
                <div className="title-welcome text-center">{details.title}</div>
                <div className="text-generic-m text-center my-4">
                  {details.subtitle}
                </div>
                {banners.length > 0 && (
                  <div className="cover-page">
                    <CarouselView content={banners} />
                  </div>
                )}

                <GetBodyInnerHTML
                  className="text-generic-r my-5"
                  content={details.description}
                ></GetBodyInnerHTML>

                <Tarjetas content={details?.tarjetas}></Tarjetas>

                <div className="">
                  {details.video && (
                    <div className="ratio ratio-21x9">
                      <video
                        src={details.video}
                        width="100%"
                        height="500"
                        controls
                      ></video>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  {details?.infographics?.map((info: any, index: number) => {
                    return (
                      <img
                        key={index}
                        src={info}
                        alt="Infogr"
                        className="rounded img-fluid"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
