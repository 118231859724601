import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetBannerSectionApiQuery,
  useGetListWellnessPillarsQuery,
} from '../../../api/userSession';
import { getDomain } from '../../../config/domain';
import { apiMainURL } from '../../../config/environment';
import { SolveLinks } from '../../../modules/links/solve_links';
import {
  bannersInSection,
  getURL,
} from '../../../modules/services/getElements.service';
import { wellnessId } from '../../../modules/services/sections';
import CarouselView from '../../carousel/carousel';
import ContentLinks from '../../content-links/contentLinks';

export const Wellness = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const dataBannersApi = useGetBannerSectionApiQuery(wellnessId).data;
  const dataListWellnessPillarsApi = useGetListWellnessPillarsQuery('').data;
  var ListWellnessPillars = getAllPillars(dataListWellnessPillarsApi);

  var banners = bannersInSection(dataBannersApi);

  function getAllPillars(section: any): Element[] {
    var elements: any[] = [];
    let idSection = '';
    section?.data?.map((result: any, i: number) => {
      idSection = result.id || '';
      let id = result.relationships.field_imagen_destacada.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });
      elements[i] = {
        id: idSection,
        title: result.attributes.title,
        image: image,
        description: (result.attributes?.body?.summary || '').substring(0, 500),
        link: getURL(result.attributes.field_link?.uri || ''),
        column: result.attributes.field_column || '3',
      };
    });
    return elements;
  }

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Bienestar
                  </li>
                </ol>
              </nav>
              <div className="title-welcome fs-2"></div>
              <CarouselView content={banners} />
              <div className="row">
                {ListWellnessPillars?.map((el: any, index: number) => (
                  <div key={index} className={'my-4 col-md-' + 12 / el.column}>
                    <div className="card text-white">
                      <SolveLinks
                        url={'wellness/DetailPilar?' + (el.id || '')}
                        img={el.image}
                        title={el.title}
                        description={el.description}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <ContentLinks id={wellnessId}></ContentLinks>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Wellness;
