import React from 'react';

type eventDateProps = {
  day: string | undefined;
  month: string | undefined;
  weekDay: string | undefined;
};

const EventDate = ({ day, month, weekDay }: eventDateProps): JSX.Element => {
  return (
    <div
      className="d-flex flex-column py-4 mx-3 my-2 align-self-end"
      style={{ minWidth: '125px', maxWidth: 'fit-content' }}
    >
      <div className="d-flex gap-1">
        <p
          className="align-bottom fw-bolder"
          style={{
            color: '#1a9986',
            fontSize: '2rem',
            marginBottom: '0px',
            fontFamily: 'sans-serif',
          }}
        >
          {day}
        </p>
        <p
          className="align-self-end fw-bold ms-1"
          style={{ fontSize: '0.8rem', marginBottom: '0.35rem' }}
        >
          {month}
        </p>
      </div>
      <div className="d-flex flex-column">
        <p
          className="align-self-start text-muted fw-bold"
          style={{
            fontSize: '0.9rem',
            opacity: '0.6',
            marginBottom: '0.35rem',
          }}
        >
          {weekDay}
        </p>
      </div>
    </div>
  );
};

export default EventDate;
