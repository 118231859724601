
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteSession,
  getUserData,
  useGetAppsHubApiQuery,
  useGetBannerSectionApiQuery,
  useGetContentSectionApiQuery,
} from '../../api/userSession';
import { getDomain } from '../../config/domain';
import { apiMainURL } from '../../config/environment';
import { store } from '../../modules/appState/store/store';
import {
  appsInSection,
  bannersInSection,
  contentInSection,
} from '../../modules/services/getElements.service';
import { landingId } from '../../modules/services/sections';
import CarouselView from '../carousel/carousel';
import LoadSpinner from '../load-spinner/load-spinner';
import { LastNewsDate } from '../pages/news-feed/last-news/last-news-date';
import { LastNewsShorts } from '../pages/news-feed/last-news/last-news-short';
import Sections from '../sections/sections';

const MODAL_NPS = 'modal-nps-poll';

export const Landing = (props: any) => {
  const storeApp = store;
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  const [t, i18n] = useTranslation('global');
  const [modalClassName, setModalClassName] = useState(MODAL_NPS);
  const sharp = localStorage.getItem('sharp') || '';
  let dataBannersApi: any = {};
  let dataContentApi: any = {};
  var contents: Element[] = [];
  var banners: Element[] = [];
  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  dataContentApi = useGetContentSectionApiQuery(landingId).data;
  contents = contentInSection(dataContentApi);
  banners = bannersInSection(dataBannersApi);
  const [user, setUser] = useState<any>(localStorage.getItem('user_info'));

  storeApp.subscribe(() => {
    if(localStorage.getItem('user_info')){
      setUser(JSON.parse(localStorage.getItem('user_info') || ''));
    }
     
  });

  useEffect(() => {
    if (sharp) {
      window.test(sharp);
    }
  }, [user, sharp]);
if(user)
  return (
    <div>
      <div className={modalClassName}> </div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="title-welcome fs-2">
                <div>¡{domainData.wellcome},</div>
                <span>{user?.first_name||''}</span>!
              </div>
              <CarouselView content={banners} />
              <h3 className="section-title">¿Qué quieres visitar hoy?</h3>
              <Sections content={contents} />
              <LastNewsShorts btn={1} />
              <LastNewsDate />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  else {
    console.log("USER:  ",user)
    return <LoadSpinner/>
  }
};
