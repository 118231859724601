import React from 'react';
import EventDate from './event-date';
import { useHistory } from 'react-router-dom';

type eventProps = {
  id?: string;
  date?: Date;
  hours?: string[];
  category?: string;
  title?: string;
  place?: string;
  image?: string;
};

const localeDateType = 'en-US'; //'es-CO'

const Event = ({
  id,
  date,
  hours = [],
  category = '',
  title = '',
  place = '',
  image = '',
}: eventProps) => {
  const history = useHistory();

  const month = date?.toLocaleString(localeDateType, { month: 'long' });
  const day = date?.toLocaleString(localeDateType, { day: '2-digit' });
  const weekDay = date?.toLocaleString(localeDateType, { weekday: 'long' });
  const localeDate = date?.toLocaleDateString(localeDateType, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const hasMultipleHours = hours.length > 1 && hours[0];

  let hoursToShow: string;

  if (hasMultipleHours) hoursToShow = hours.join(' - ');
  else hoursToShow = hours[0];

  const onDetailsClickButton = () => {
    history.push(`/welfare-events/${id}`);
  };

  return (
    <div className="d-flex w-100 shadow py-2">
      <EventDate {...{ day, month, weekDay }} />
      <div className="d-flex gap-4" style={{ width: '70%' }}>
        <div
          className="rounded-circle my-auto overflow-hidden justify-content-center text-center"
          style={{ width: '104px', height: '104px' }}
        >
          <img style={{ maxHeight: '104px' }} src={image} alt="list" />
        </div>
        <div className="d-flex flex-column my-auto">
          <p
            className="fw-bolder"
            style={{
              color: '#1a9986',
              fontSize: '0.8rem',
              marginBottom: '0.3rem',
            }}
          >
            {category}
          </p>
          <p className="fw-bold" style={{ marginBottom: '0.8rem' }}>
            {title}
          </p>
          {hoursToShow && (
            <p
              className="text-muted"
              style={{ fontSize: '0.7rem', marginBottom: '0.2rem' }}
            >
              <i className="bi bi-clock me-2" style={{ color: '#1a9986' }} />
              {hasMultipleHours ? (
                hoursToShow
              ) : (
                <>
                  {`${localeDate} @ ${hoursToShow}, `}
                  <a style={{ color: '#1a9986' }} href="#somewhere">
                    more
                  </a>
                </>
              )}
            </p>
          )}
          {place && (
            <p
              className="text-muted"
              style={{ fontSize: '0.65rem', marginBottom: '0.2rem' }}
            >
              <i
                className="bi bi-geo-alt-fill me-2"
                style={{ color: '#1a9986' }}
              />
              {place}
            </p>
          )}
        </div>
      </div>
      <div
        className="d-flex justify-content-end flex-row"
        style={{ width: '20%' }}
      >
        <button
          className="border bg-white align-self-center py-2 px-3 mx-3 fw-bold"
          style={{ fontSize: '0.7rem' }}
          onClick={onDetailsClickButton}
        >
          More Details
        </button>
      </div>
    </div>
  );
};

export default Event;
