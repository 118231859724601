import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const optionsLocations = [
  { val: 1, location: 'Cervecería Unión' },
  { val: 2, location: 'Cervecería de Tocancipá' },
  { val: 3, location: 'Cervecería de Barranquilla' },
  { val: 4, location: 'Cervecería del Valle' },
  { val: 5, location: 'Cervecería de Bucaramanga' },
  { val: 6, location: 'Cervecería de Boyacá' },
  { val: 7, location: 'Edificio 127' },
  { val: 8, location: 'Impresora del Sur' },
  { val: 9, location: 'Indugral' },
  { val: 10, location: 'Malteria Tibitó' },
  { val: 11, location: 'Malteria Tropical' },
  { val: 12, location: 'R. Centro CD San Fernando' },
  { val: 13, location: 'R. Centro Calle 22 ' },
  { val: 14, location: 'R. Centro Amerícas' },
  { val: 15, location: 'R. Centro AutoSur' },
  { val: 16, location: 'R. Andes CD Armenia' },
];
export const ModalLocation = () => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className="col-auto col-md-auto col-lg-2 my-3 py-0 me-md-2">
      <div className="link-geo" onClick={toggle}>
        <div>
          <i className="bi bi-geo-alt"></i>
        </div>
        <div className="text">
          {' '}
          Recoger en
          <br />
          <span>Guayaquil</span>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 1000 }}
        className="modal-dialog-centered"
      >
        <ModalBody>
          Selecciona el lugar donde quieres reclamar
          <span>
            La disponibilidad de los productos varía según el punto de elección
          </span>
          <div>
            <select>
              <option value="">Seleccionar...</option>
              {/* {optionsLocations.map((opt:any, num:number)=>{
                                return <option value={opt.val}> {opt.location} </option>
                            })} */}
            </select>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
