import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getPointsService, getStagesByIdApi, getTourApi } from '../../../../../api/userSession';
import { getDomain } from '../../../../../config/domain';
import { store } from '../../../../../modules/appState/store/store';
import LoadSpinner from '../../../../load-spinner/load-spinner';
import { ActivitiesList } from '../../Activities/activities-list/activitiesList';
import { getStagesData, getTourData } from '../../GamificationServices/gamification-service';
import { ProgressStages } from '../../InfoProgress/Progress';
import { ModalComponentBp } from '../../landing/gamificationLanding';
import { ShortListElements } from '../../short-list-elements/shortListElements';

export const StagesDetail = () => {
    const location = useLocation();
    const storeApp = store;

    const id = location?.search?.split('?')[1] || '0';
    const TOUR_ID = '137';


    const [stages, setStages] = useState<any[]>([]);
    const [user, setUser] = useState<any>(null);
    const [points, setPoints] = useState<any>(null);
    const [opened, setOpened] = useState<boolean>(false);
    const [domainData, setDomainData] = useState(
        getDomain(window.location.hostname + ''),
    );

    storeApp.subscribe(() => {
        setUser(JSON.parse(localStorage.getItem('user_info') || ''));
    });

    const solveType = () => { return location.pathname.includes('stages') ? 'stages' : 'stations'; }
    const breadItems = [
        { label: domainData?.name||'', active: false, link: '/landing' },
        { label: 'Tour de Gente', active: false, link: '/tour-de-gente' },
        { label: solveType() == "stages" ? 'Detalle de Etapa' : 'Detalle de Estación', active: true, link: '' },
    ];
    useEffect(() => {
        if (!user && localStorage.getItem('user_info'))
            setUser(JSON.parse(localStorage.getItem('user_info') || ''));
        if (stages.length < 1 && user && !opened) {
            getStagesByIdApi(id).then((stage: any) => {

                let tourId=getTourId(stage?.included);

                setOpened(true);
                if (user?.band && tourId!='') {
                    getPointsService(tourId, user?.band).then((elem: any) => {

                        if (elem) {
                            setPoints({ ...elem, 'band': user?.band })
                            let t = getStagesData(stage, solveType(), { ...elem, 'band': user?.band });
                            if (t) {
                                document.documentElement.style.setProperty('--custom-color', stage?.data[0]?.attributes?.field_color?.color);
                                setStages(t);
                            }
                        }
                    }).catch((error: any) => console.log("Error query:  ", error));
                }



            }).catch((error: any) => console.log("Error query:  ", error));
        }
    }, [stages, user]);

    function getTourId(includes: any[]): string {
        let id = '';
        includes?.map((inc: any) => {
            if (inc?.type == 'node--tour') {
                id=inc?.attributes?.drupal_internal__nid||'';
            }
        })
        return id;
    }
    if (stages?.length > 0 || opened) {
        return (
            <div className={'main-content'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-12 col-md-8">
                            <Breadcrumb>
                                {breadItems.map(({ label, active, link }) => (
                                    <BreadcrumbItem
                                        key={`payment-breadcrumb-item-${label}`}
                                        {...{ active }}
                                    >
                                        {!active ? <Link to={link}>{label}</Link> : label}
                                    </BreadcrumbItem>
                                ))}
                            </Breadcrumb>
                        </div>
                        <div className="col col-12 col-md-4">
                            {user && (
                                <ModalComponentBp lockBackdrop={true} user={user} />
                            )}
                        </div>
                    </div>
                    <div className="row align-items-center flex">

                        <div className="col-md-12 col-lg-5 o1">
                            <div className="ps-4">
                                <div className="title-welcome fw-bold">Tour de {stages[0]?.titulo}</div>
                                <div
                                    className="info_programs"
                                >
                                    {stages[0]?.descripcion}
                                </div>

                            </div>

                        </div>
                        <div className="col-md-12 col-lg-7 mb-4 o2">
                            <div className="bg-custom box-image-stage">
                                <img
                                    src={stages[0]?.image}
                                    className="img-fluid img-detail-stage mx-auto"
                                    alt="..."
                                />
                            </div>
                        </div>
                        <div className="col-md-12 o3">
                            <ProgressStages text={'Progreso de la etapa'} percent={parseFloat(stages[0]?.tuPorcentaje || '0').toFixed(2)} score={stages[0]?.tuPuntaje} />
                        </div>
                    </div>
                    <ActivitiesList idStage={id} data={points} />
                </div>

            </div>

        )
    }

    else return <LoadSpinner />
}
