import React from 'react';

const OrderList = ({ products }: { products: any[] }) => {
  return (
    <>
      {products?.map((product: any) => {
        return (
          <div className="row my-4 ">
            <div className="col-3">
              <img
                className="full-image"
                src={product?.img || ''}
                alt={`history-product-img-${product.name}-${product.volume}`}
              />
            </div>
            <div className="col-6">
              <h5>{product?.name || ''}</h5>
              <p className="card-text">
                {[...Array(product.score).keys()].map((score: any) => {
                  return (
                    <img
                      src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                      alt="star"
                      className="star-calification"
                      key={`star-score-${score}`}
                    />
                  );
                })}
              </p>
              <p className="title-vol-product">{product?.volume || ''}</p>
              <div className="dropdown">
                <button
                  className="btn btn-outlined-secondary btn-cant-payment rounded-pill pt-2 fw-bolder"
                  type="button"
                  aria-expanded="false"
                  disabled
                >
                  1
                </button>
              </div>
            </div>
            <div className="col-2 close-section-cart">
              <div className="row">
                <div className="col-8">
                  <p className="price-product-cart">Precio</p>
                  <p className="price-cart-order">{product?.price || ''}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrderList;
