import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import { Provider } from 'react-redux';
import { setupStore } from './modules/appState/store/store';
import { domain_country, getDomain } from './config/domain';
//import "semantic-ui-css/semantic.min.css";

const store = setupStore();
const handleFavicon = () => {
  const domainCountry = domain_country(window.location.hostname + '');
  const appName: any = getElement('appName');
  const domainData = getDomain(window.location.hostname + '');

  appName.content = domainData.name || '';
  document.title = domainData.name || '';
  const desc = getElement('description');
  const manifest = (getElement('manifest').href =
    process.env.PUBLIC_URL + `/${domainCountry.color}/favicon.png`);
  if (desc && desc.content) {
    getElement('description').content = domainData.description?.content || '';
  }
  getElement('favicon').href =
    process.env.PUBLIC_URL + `/${domainCountry.color}/favicon-16x16.jpg`;
  const favicon1: any = getElement('favicon1'); // Accessing favicon element
  favicon1.href =
    process.env.PUBLIC_URL + `/${domainCountry.color}/favicon-16x16.jpg`;
  const favicon2: any = getElement('favicon2'); // Accessing favicon element
  favicon2.href =
    process.env.PUBLIC_URL + `/${domainCountry.color}/favicon-16x16.jpg`;
};

function getElement(id: string): any {
  return document.getElementById(id);
}
/* 
import store from "./modules/appState/store/store"; */
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
