import React from 'react';
import { TrackingContainer } from '../../pages/e-commerce/utitities/trcking-container';
import Information from './Information';
import OrderList from './OrderList';
const breadItems = [
  { label: 'Planeta Beerway', active: false, link: '/' },
  { label: 'E-commerce', active: false, link: '/e-commerce/landing' },
  { label: 'Tu Pago', active: true, link: '' },
];
const calification: any[] = ['1', '2', '3', '4', '5'];

const products = [
  {
    img: 'https://i.ibb.co/9p3jYb4/4.jpg',
    name: 'Stella Artois',
    score: 5,
    volume: 'LTA 320 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  },
  {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Corona',
    score: 5,
    volume: 'LTA 335 ml x 4',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  },
];
export const Filled = ({
  productsData: { products, resume },
}: {
  productsData: any;
}) => {
  return (
    <>
      <div className="row">
        <div className="col-7 mt-5">
          <TrackingContainer />
          <OrderList {...{ products }} />
        </div>
        <div className="col-5">
          <Information {...resume} />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 main-tracking"></div>
      </div>
    </>
  );
};
