import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CropImage from "../../../../profile/crop-image";

export const ModalVoucher = (props:any) => {

    const [modal, setModal] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const toggle = () => setModal(!modal);
    const {idActivity, trigger, text, status}=props;
    const event=()=>{
        console.log("Ejecutando CALLBACK ------------------>");
        trigger();
        toggle();
    }

    const closeBtn = (
        <button className="btn close" onClick={toggle} type="button">
            &times;
        </button>
    );

    return (
        <div>
            <Button className={status=='Finalizada'?"btn btn-primary ms-3 disabled":"btn btn-primary ms-3"} onClick={toggle}>
              {text}
            </Button>
            <Modal isOpen={modal} toggle={toggle} className="modal-voucher-activities" >
                <ModalHeader toggle={toggle} close={closeBtn}>
                    Subir comprobante
                </ModalHeader>
                <ModalBody>
                    <CropImage tour={true} idactivity={idActivity} trigger={() => event()}></CropImage>

                    <div>
                        Puedes subir un archivo jpg, jpeg o png de máximo 5MB
                    </div>
                </ModalBody>
                <ModalFooter className="align-self-center">
                    {/* <button className="btn btn-primary" onClick={toggle}>
                        Confirmar
                    </button>
                    <button className="btn btn-outline-secondary" onClick={toggle}>
                        Cancelar
                    </button> */}
                </ModalFooter>
            </Modal>
        </div>
    );
}
