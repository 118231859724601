import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetBannerByIdApiQuery,
  useGetBannerSectionApiQuery,
  useGetDetailDiversityFocusApiQuery,
  useGetDiversityFocusTitleApiQuery,
} from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { bannersInSection } from '../../../../modules/services/getElements.service';
import CarouselView from '../../../carousel/carousel';

export const SectionDiversity = (props: any) => {
  let dataFocus = useGetDetailDiversityFocusApiQuery(
    window.location.href.split('?')[1],
  ).data;
  let dataBannersApi = useGetBannerByIdApiQuery(
    window.location.href.split('?')[1],
  ).data;
  let banners = bannersInSection(dataBannersApi);

  let dataTitleDiversitySection = useGetDiversityFocusTitleApiQuery(
    window.location.href.split('?')[1],
  ).data;

  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  let titleDiversitySection: string = getTitle(dataTitleDiversitySection);
  let descriptionDiversitySection: string = getDescription(
    dataTitleDiversitySection,
  );
  var details = getDetailElements(dataFocus);
  function getTitle(dataTitleDiversitySection: any): string {
    return dataTitleDiversitySection?.data?.attributes?.name || '';
  }
  function getDescription(dataTitleDiversitySection: any): string {
    return (
      dataTitleDiversitySection?.data?.attributes?.description?.value || ''
    );
  }
  function getDetailElements(section: any): any[] {
    var elementsIniciative: any[] = [];
    var elementsBlog: any[] = [];
    var elementsTotal: any[] = [];
    let idSection = '';
    section?.data?.map((result: any, i: number) => {
      let indexInic = 0;
      let indexBlog = 0;
      idSection = result.id || '';
      let id = result.relationships.field_imagen_destacada?.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });

      let temp = {
        id: idSection,
        title: result.attributes.title,
        image: image,
        description: result.attributes?.body?.summary
          ? result.attributes.body.summary
          : '',
        link: '/diversity/details',
        column: result.attributes.field_column || '3',
      };
      if (
        result?.attributes?.field_tipo_articulo &&
        result?.attributes?.field_tipo_articulo == 'Iniciativa'
      ) {
        elementsIniciative.push(temp);
        indexInic++;
      }
      if (
        result?.attributes?.field_tipo_articulo &&
        result?.attributes?.field_tipo_articulo == 'Blog'
      ) {
        elementsBlog.push(temp);
        indexBlog++;
      }
    });
    elementsTotal.push(elementsIniciative);
    elementsTotal.push(elementsBlog);
    return elementsTotal;
  }
  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/diversity">Diversidad</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {titleDiversitySection}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">
                {titleDiversitySection}
              </div>
              <CarouselView content={banners} />
              <div className="tabs-section">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <span className="desc-section">
                    {descriptionDiversitySection}
                  </span>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="iniciativas-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#iniciativas"
                      type="button"
                      role="tab"
                      aria-controls="iniciativas"
                      aria-selected="true"
                    >
                      Iniciativas
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Blog-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Blog"
                      type="button"
                      role="tab"
                      aria-controls="Blog"
                      aria-selected="false"
                    >
                      Blog
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="iniciativas"
                    role="tabpanel"
                    aria-labelledby="iniciativas-tab"
                  >
                    <div className="overflow-hidden py-4 px-2 box-programs">
                      <div className="row row row-cols-1 row-cols-sm-2 row-cols-lg-3">
                        {details[0]?.map((element: any, index: number) => {
                          return (
                            <div className="col text-sm-center">
                              <div className="card" key={index}>
                                <Link to={`/diversity/details?${element.id}`}>
                                  <img
                                    src={element.image}
                                    className="img-fluid img-border"
                                    alt="..."
                                  />
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {element.title}
                                    </h5>
                                    <p className="card-text">
                                      {element.description}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Blog"
                    role="tabpanel"
                    aria-labelledby="Blog-tab"
                  >
                    <div className="overflow-hidden py-4 px-2 box-programs">
                      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
                        {details[1]?.map((element: any, index: number) => {
                          return (
                            <div className="card" key={index}>
                              <Link to={`/diversity/detailsBlog?${element.id}`}>
                                <img
                                  src={element.image}
                                  className="img-fluid img-border"
                                  alt="..."
                                />
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {element.title}
                                  </h5>
                                  <p className="card-text">
                                    {element.description}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SectionDiversity;
