import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  useGetBannerByIdApiQuery,
  useGetBannerSectionApiQuery,
} from '../../../../api/userSession';
import {
  bannersInSection,
  translateNetwork,
} from '../../../../modules/services/getElements.service';
import CarouselView from '../../../carousel/carousel';
import MediaComponent from '../../../mediaComponent/media-component';
export default function TabDetail(props: any) {
  const data = props.content;
  var banners: any;
  var dataBannersApi: any;
  dataBannersApi = useGetBannerByIdApiQuery(props.idMark).data;
  banners = bannersInSection(dataBannersApi);
  const [activeTab, setActiveTab] = useState('1');
  const [media, setMedia] = useState({});

  const toggle: any = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {}, []);
  return (
    <div className="row">
      <CarouselView content={banners} />
      <Nav className="miniatures-center" tabs>
        {props.miniaturas.map((min: any, index: number) => {
          return (
            <NavItem key={index.toString()} className="nav-item">
              <NavLink
                className={
                  classnames({
                    active: activeTab === (index + 1).toString(),
                  }) + ' miniatures-tam'
                }
                onClick={() => {
                  toggle((index + 1).toString());
                }}
              >
                <img src={min} alt="" className="img-fluid" />
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent activeTab={activeTab}>
        {data?.map((el: any, index: number) => {
          return (
            <TabPane tabId={'' + (index + 1)} key={index}>
              <br />
              <br />
              <div className="title-welcome text-center">
                <h2>{el.title}</h2>
              </div>

              <div className="text-center mt-4">{el.description}</div>

              {el.socialNetworks.map((sn: any, i: number) => {
                return (
                  <div className="icons_social">
                    Síguenos en:
                    <div>
                      <a href={sn} key={i} target="_blank">
                        <i className={translateNetwork(sn)}></i>
                      </a>
                    </div>
                  </div>
                );
              })}

              <Row>
                <Col sm="12">
                  <div className="row justify-content-center align-items-center align-content-md-around">
                    <div className="col-md-6">
                      <img src={el.BigImage} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                      {el.attributesRef.map((ref: any, indexRef: number) => {
                        return (
                          <div className="" key={indexRef}>
                            <div className="row brand-items">
                              <div className="col-md-3">
                                <div className="icon-brand">
                                  {/* <img
                              src={`${process.env.PUBLIC_URL}/resources/icon_b.jpg`}
                              alt=""
                              className="img-fluid"
                            /> */}
                                  <div className="brand_type">
                                    <span>
                                      {ref.field_referencia_atributo_titulo}
                                    </span>
                                    {ref.field_referencia_atributo_valor}
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="text-brand">
                                  <span>
                                    {ref.field_ref_atributo_titulo_descri}
                                  </span>
                                  {ref.field_ref_atributo_descripcion}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="title-welcome text-center">
                <h2>{el.title} EN IMÁGENES</h2>
                <MediaComponent content={el?.mediaTab} />
              </div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
}
