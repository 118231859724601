import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useGetDetailTradeMarkApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import {
  getInfographic,
  getMultimedia,
  getVideo,
  Media,
} from '../../../../modules/services/getElements.service';
import MediaComponent from '../../../mediaComponent/media-component';
import TabDetail from './tab-detail';

export const DetailsBrand = (props: any) => {
  const [t, i18n] = useTranslation('global');
  const history = useHistory();
  const dispatch = useDispatch();
  let idMark = '';
  let Infografia = '';

  let arraySmallImages: any[] = [];
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const dataSection = useGetDetailTradeMarkApiQuery(
    window.location.href.split('?')[1],
  );
  const details = getDetailMarks(dataSection);
  const multimediaSection = getMultimedia(dataSection?.data?.included);
  function getDetailMarks(section: any): any[] {
    let elements: any[] = [];
    let dataMediaTabs: Media[] = [];
    section?.data?.data?.map((result: any, i: number) => {
      dataMediaTabs = [];
      let idfield_image = result.relationships.field_image.data.id || '';
      let idfield_imagen_miniatura =
        result.relationships.field_imagen_miniatura.data.id || '';
      let idfield_infografia =
        result.relationships.field_imagen_miniatura.data.id || '';
      let idField_ref: number[] = [];
      let fieldRefArray: any[] = [];
      let id_Infografia = '';
      let fieldMarcaId = result.relationships.field_marca.data.id;
      idMark = result.relationships.field_marca.data.id + '';
      result.relationships.field_referencia_atributos.data.forEach(
        (ref: any) => {
          idField_ref.push(ref.id);
        },
      );
      result.relationships.field_contenido_multimedia.data.forEach(
        (cont: any) => {
          if (cont.type == 'node--infografia') {
            let info = getInfographic(cont.id, section?.data?.included);
            dataMediaTabs.push({ type: 'infografia', src: info });
          }
          if (cont.type == 'node--video') {
            let info = getVideo(cont.id, section?.data?.included);
            dataMediaTabs.push({ type: 'video', src: info });
          }
        },
      );

      let BigImage = '';
      let SmallImage = '';
      let socialNetworks: any[] = [];
      let field_ref_atributo_descripcion = '';
      let field_ref_atributo_titulo_descri = '';
      let field_referencia_atributo_titulo = '';
      let field_referencia_atributo_valor = '';
      let field_info = '';
      idField_ref.map((idRef: any) => {
        section?.data?.included?.map((include: any) => {
          if (idRef == include.id) {
            let objectReferencia = {
              field_referencia_atributo_titulo:
                include?.attributes.field_referencia_atributo_titulo || '',
              field_referencia_atributo_valor:
                include?.attributes.field_referencia_atributo_valor || '',
              field_ref_atributo_titulo_descri:
                include?.attributes?.field_ref_atributo_titulo_descri || '',
              field_ref_atributo_descripcion:
                include?.attributes?.field_ref_atributo_descripcion || '',
            };
            fieldRefArray.push(objectReferencia);
          }
        });
      });
      section?.data?.included?.map((include: any) => {
        if (idfield_image === include.id) {
          BigImage = apiMainURL + include.attributes.uri.url;
        }
        if (idfield_imagen_miniatura === include.id) {
          SmallImage = apiMainURL + include.attributes.uri.url;
          arraySmallImages.push(SmallImage);
        }
        if (id_Infografia == include.id) {
          let idResource = include.relationships.field_image.data.id;
          section?.data?.included?.map((resource: any) => {
            if (resource.id == idResource) {
              field_info = apiMainURL + resource.attributes.uri.url;
              Infografia = apiMainURL + resource.attributes.uri.url;
            }
          });
        }

        if (include.type == 'paragraph--social') {
          /* 
                        console.log("RED SOCIAL:  ",include?.attributes?.field_social_url?.uri); */
          socialNetworks.push(include?.attributes?.field_social_url?.uri);
          const requestOptions = {
            method: 'POST',
            headers: new Headers({
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }),
          };
        }
      });

      elements[i] = {
        title: result.attributes.title,
        BigImage: BigImage || '',
        smallImage: SmallImage || '',
        description: result.attributes.body?.value || '',
        link: result.attributes.path.alias || '',
        socialNetworks: socialNetworks,
        attributesRef: fieldRefArray,
        mediaTab: dataMediaTabs,
      };
    });
    return elements;
  }
  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/trademarks">{domainData.marks}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Detalle
                  </li>
                </ol>
              </nav>
              <div className="tabs-brand">
                <TabDetail
                  content={details}
                  idMark={idMark}
                  infografia={Infografia}
                  miniaturas={arraySmallImages}
                  media={multimediaSection}
                />
              </div>
            </div>
            {/* <div className="col-12 text-center">
                            <img src={`${process.env.PUBLIC_URL}/resources/info-2.jpg`} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-12 my-4 text-center">
                            <span className="fs-3 medium">Corona en imágenes</span>
                        </div>
                        <div className="col-12 text-center">
                            <img src={`${process.env.PUBLIC_URL}/resources/img-1.jpg`} alt="" className="img-fluid"/>
                        </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
