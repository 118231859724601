import React, { memo, useEffect } from 'react';
import { apiMainURL } from '../../../../config/environment';
import styles from './Button.module.scss';
export default memo(function FavHeart(props: any) {
  const id = props?.content;
  useEffect(() => {});
  function handleClick(event: any) {
    console.log('Evento entrante:  ', event);
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      `${apiMainURL}/api/${id}/add-to-planeta-favorites?_format=json`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        console.log('FAV Response:  ', result);
      })
      .catch((error) => console.log('error', error));
    if (event?.target?.classList?.contains(styles?.liked)) {
      //FETCH AGREGAR FAVORITOS

      event?.target?.classList?.remove(styles?.liked);
    } else {
      event?.target?.classList?.add(styles?.liked);
      //FETCH ELIMINAR DE FAVORITOS
    }
  }
  return (
    <div className={styles.tam_heart}>
      <div
        className={styles.heart_like_button}
        onClick={(e) => handleClick(e)}
      ></div>
    </div>
  );
});
