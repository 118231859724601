import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  useGetBannerSectionApiQuery,
  useGetContentSectionApiQuery,
  useGetListTradeMarksApiQuery,
  useGetUserQuery,
} from '../../../../../api/userSession';
import { getDomain } from '../../../../../config/domain';
import { apiMainURL } from '../../../../../config/environment';
import { Section } from '../../../../../models/section.model';
import {
  bannersInSection,
  contentInSection,
} from '../../../../../modules/services/getElements.service';
import { trademarksId } from '../../../../../modules/services/sections';
import CarouselView from '../../../../carousel/carousel';
import Sections from '../../../../sections/sections';

export const MediaPillar = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [t, i18n] = useTranslation('global');
  /*  const count = useSelector((state: any) => state.language.value);
    const user = useSelector((state: any) => state.image); */
  const history = useHistory();
  const dispatch = useDispatch();

  /*   const { data, error, isLoading } = useGetUserQuery(""); */
  const user = useGetUserQuery('').data;
  let section: Section;
  const dataBannersApi = useGetBannerSectionApiQuery(trademarksId).data;
  const dataContentApi = useGetContentSectionApiQuery(trademarksId).data;

  const dataListTrademarksApi = useGetListTradeMarksApiQuery('').data;
  var contents = contentInSection(dataContentApi);
  var banners = bannersInSection(dataBannersApi);
  var ListTrademarks = getAllMarks(dataListTrademarksApi);

  function getAllMarks(section: any): Element[] {
    var elements: any[] = [];
    section?.data?.map((result: any, i: number) => {
      let id = result.relationships.field_banner_imagen.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });
      elements[i] = {
        title: result.attributes.name,
        image: image,
        description: result.attributes?.description?.value
          ? result.attributes.description.value
          : '',
        link: `/pillars/details?${result.id}`,
        column: result.attributes.field_column,
      };
    });
    return elements;
  }
  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/landing">{domainData.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Categoria pilares
              </li>
            </ol>
          </nav>
          <h3 className="section-title">Categoria Pilares</h3>
          <div className="row">
            <div className="col-12">
              <div className="title-welcome fs-2"></div>
              <CarouselView content={banners} />
              <Sections content={ListTrademarks} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
