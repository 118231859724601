import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { domain_country, getDomain } from './config/domain';
import { apiMainURL } from './config/environment';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './scss/App.scss';
import { Games } from '@material-ui/icons';
import { deleteSession } from './api/userSession';
import Agents from './components/agents/agents';
import Calendar from './components/calendar/calendar';
import Callback from './components/CallBack/callback';
import PageError from './components/error/error';
import Favorites from './components/favorites/favorites';
import FooterTerms from './components/footer-terms/footer-terms';
import { Landing } from './components/home/landing';
import LandingUserWithSession from './components/home/LandingUserWithoutSession';
import { Legal } from './components/legal/legal';
import Littleshop from './components/little-shop/little-shop';
import LoadSpinner from './components/load-spinner/load-spinner';
import LateralMenu from './components/menu/lateral-menu/lateralMenu';
import Notifications from './components/notifications.tsx/notifications';
import ContestDetails from './components/pages/competitions/details';
import ContestStart from './components/pages/competitions/start';
import ContestFinish from './components/pages/competitions/competition_finalized';
import { Culture } from './components/pages/culture/culture';
import DetailsDiversity from './components/pages/diversity/details/details-diversity';
import { DetailDiversityBlog } from './components/pages/diversity/details/detailsDiversityBlog';
import Diversity from './components/pages/diversity/diversity';
import SectionDiversity from './components/pages/diversity/section/section-diversity';
import { AppsHub } from './components/pages/ex-hub/apps-hub';
import Newsfeed from './components/pages/news-feed/news-feed';
import Detail_Events from './components/pages/programs-events/details/details-events';
import Detail_Programs from './components/pages/programs-events/details/details-programs';
import Programs_Events from './components/pages/programs-events/programs-events';
import { DetailsBrand } from './components/pages/trade-marks/details/detail-brand';
import Trademarks from './components/pages/trade-marks/trade-marks';
import { DetailPilar } from './components/pages/wellness/details/detailWellnessPilar';
import Wellness from './components/pages/wellness/wellness';
import { Profile } from './components/profile/profile';
import Support from './components/support/support';
import { DetailNews } from './components/pages/news-feed/detail-news/detail-news';
import EcommerceLanding from './components/pages/e-commerce/landing';
import { MediaPillar } from './components/pages/wellness/details/media-pillars/media-pillar';
import { DetailPillar } from './components/pages/wellness/details/media-pillars/detail-media';
import ContestQuestions from './components/pages/competitions/questions/questions';
import GlobalNews from './components/pages/news-feed/global-news/global-news';
import { PreCompetitions } from './components/pages/competitions/questions/pre-list-competitions';
import { Default } from './components/default-section/default';
import EcommerceCatalogue from './components/pages/e-commerce/catalogue';
import DetailProduct from './components/pages/e-commerce/detail-item-product';
import Search from './components/search/search';
import { ShoppingCart } from './components/pages/e-commerce/shoppingCart/shopping-cart';
import { FavoriteProducts } from './components/pages/e-commerce/favorites';
import { PaymentProduct } from './components/pages/e-commerce/shoppingCart/payment-product';
import { ShoppingHistory } from './components/pages/e-commerce/shoppingCart/order-history';
import { CurrentOrder } from './components/pages/e-commerce/shoppingCart/current-order';
import WelfareEvents from './components/pages/welfare-events';
import DetailedEvent from './components/pages/detailed-event/detailed-event';
import UploadPdf from './components/UploadPDF';
import { GamificationLanding } from './components/pages/gamification/landing/gamificationLanding';
import { StagesDetail } from './components/pages/gamification/Stages/stages-detail/stagesDetail';
import { StationsDetail } from './components/pages/gamification/Stations/stations-detail/stationsDetail';
import { ActivitiesDetail } from './components/pages/gamification/Activities/activities-detail/activitiesDetail';

function getFaviconEl(id: string) {
  return document.getElementById(id);
}

const App = () => {
  const [accept, setAccept] = useState('-1');
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const root = () => {
    setDomainData(getDomain(window.location.hostname + ''));
    document.documentElement.style.setProperty(
      '--font-family-mon',
      domain.fonts,
    );
    document.title = domainData.name || '';
  };
  useEffect(() => {
    root();
    /* handleFavicon(); */
    if (localStorage.getItem('code_verifier')) {
      if (localStorage.getItem('localToken')) {
        getLegalValidate();
      }
    }
  }, []);
  async function  getLegalValidate(){
  var myHeaders = new Headers();
        myHeaders.append(
          'authorization',
          `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
        );
        var formdata = new FormData();
        let sharp: string = localStorage.getItem('sharp')?.toString() || '';
        formdata.append('sharp', sharp);
        var requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow',
        };
        await fetch(`${apiMainURL}/api/legal_validate`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            if (JSON.parse(result)?.legal === '1') {
              setAccept('1');
            } else {
              setAccept('0');
            }
            localStorage.setItem(
              'accept-terms',
              JSON.parse(result)?.legal?.toString() || '0',
            );
          })
          .catch((error) => {
            console.log('error', error);
            deleteSession(window.location.href);
          });
}
  const handleFavicon = () => {
    const appName: any = getFaviconEl('appName');

    appName.content = domainData.name || '';

    document.title = domainData.name || '';
    const favicon: any = getFaviconEl('favicon'); // Accessing favicon element appName

    favicon.href =
      process.env.PUBLIC_URL + `/${domain.color}-favicon-32x32.png`;
    const favicon1: any = getFaviconEl('favicon1'); // Accessing favicon element
    favicon1.href =
      process.env.PUBLIC_URL + `/${domain.color}-favicon-32x32.png`;
    const favicon2: any = getFaviconEl('favicon2'); // Accessing favicon element
    favicon2.href =
      process.env.PUBLIC_URL + `/${domain.color}-favicon-32x32.png`;
  };

  if (localStorage.getItem('code_verifier')) {
    if (accept === '0') {
      return (
        <div className={domain.color}>
          <BrowserRouter forceRefresh>
            <Route path="/auth/callback" exact component={Callback} />
            <Route
              path="*"
              component={() => (
                <Legal
                  acceptTerms={(a: string) => {
                    console.log(a);
                    setAccept(a);
                  }}
                />
              )}
            />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else if (accept === '1') {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <LateralMenu />
            <Switch>
              <Route path="/landing" exact component={Landing} />
              <Route path="/trademarks" exact component={Trademarks} />
              <Route path="/search" exact component={Search} />
              <Route
                path="/trademarks/details"
                exact
                component={DetailsBrand}
              />
              <Route path="/diversity" exact component={Diversity} />
              <Route
                path="/diversity/details"
                exact
                component={DetailsDiversity}
              />
              <Route
                path="/diversity/detailsBlog"
                exact
                component={DetailDiversityBlog}
              />
              <Route
                path="/diversity/section"
                exact
                component={SectionDiversity}
              />
              <Route path="/culture" exact component={Culture} />
              <Route
                path="/welfare-events/:eventId"
                component={DetailedEvent}
              />
              <Route path="/welfare-events" component={WelfareEvents} />
              <Route path="/wellness" exact component={Wellness} />
              <Route
                path="/wellness/DetailPilar"
                exact
                component={DetailPilar}
              />
              <Route path="/competitions" exact component={PreCompetitions} />
              <Route
                path="/competitions/ContestDetails"
                exact
                component={ContestDetails}
              />
              <Route
                path="/competitions/ContestStart"
                exact
                component={ContestStart}
              />
              <Route
                path="/competitions/ContestQuestions"
                exact
                component={ContestQuestions}
              />
              <Route
                path="/competitions/ContestFinish"
                exact
                component={ContestFinish}
              />
              <Route path="/news-feed" exact component={Newsfeed} />
              <Route path="/news-feed/global" exact component={GlobalNews} />
              <Route path="/ex-hub" exact component={AppsHub} />
              <Route path="/notifications" exact component={Notifications} />
              <Route path="/calendar" exact component={Calendar} />
              <Route path="/shop" exact component={Littleshop} />
              <Route path="/games" exact component={Games} />
              <Route path="/support" exact component={Support} />
              <Route path="/agents" exact component={Agents} />
              <Route path="/favorites" exact component={Favorites} />
              <Route path="/auth/callback" exact component={Callback} />
              <Route path="/profile" exact component={Profile} />
              <Route
                path="/programs-events"
                exact
                component={Programs_Events}
              />
              <Route
                path="/programs-events/details-programs"
                exact
                component={Detail_Programs}
              />
              <Route path="/legal" exact component={Legal} />
              <Route path="/detail-news" exact component={DetailNews} />
              <Route
                path="/programs-events/details-events"
                exact
                component={Detail_Events}
              />
              <Route
                path="/e-commerce/landing"
                exact
                component={EcommerceLanding}
              />
              <Route
                path="/e-commerce/catalogue"
                exact
                component={EcommerceCatalogue}
              />
              <Route
                path="/e-commerce/detail"
                exact
                component={DetailProduct}
              />
              <Route
                path="/e-commerce/shopping-cart"
                exact
                component={ShoppingCart}
              />
              <Route
                path="/e-commerce/payment"
                exact
                component={PaymentProduct}
              />
              <Route
                path="/e-commerce/favorites"
                exact
                component={FavoriteProducts}
              />
              <Route
                path="/e-commerce/payment"
                exact
                component={PaymentProduct}
              />
              <Route
                path="/e-commerce/history"
                exact
                component={ShoppingHistory}
              />
              <Route
                path="/e-commerce/current-order"
                exact
                component={CurrentOrder}
              />

              <Route path="/media-pillar" exact component={MediaPillar} />
              <Route path="/pillars/details" exact component={DetailPillar} />
              <Route path="/contenido" exact component={Default} />
              <Route path="/" exact component={Landing} />
              <Route path="/test-page" exact component={UploadPdf} />
            
            {/* Gamification */}
            <Route path="/tour-de-gente" exact component={GamificationLanding} />
            <Route path="/tour-de-gente/stages" exact component={StagesDetail} />
            <Route path="/tour-de-gente/stations" exact component={StagesDetail} />
            <Route path="/tour-de-gente/activities/detail" exact component={ActivitiesDetail} />
            


              <Route path="*" exact component={PageError} />
            </Switch>
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <Route path="/auth/callback" exact component={Callback} />
            <Route path="*" exact component={LoadSpinner} />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    }
  } else {
    return (
      <div className={domain.color}>
        <LandingUserWithSession />
        {/*  <button onClick={handleFavicon}>FAV</button> */}
        <FooterTerms content={domainData} />
      </div>
    );
  }
};

export default App;
