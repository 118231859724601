import React from 'react'
import { capitalizeFirstLetter } from '../../../../../modules/services/getElements.service';

export const ActivitiesCard = (props: any) => {
    const { element } = props;
    return (
        <div className="activities-card" >
            <div className="row align-items-center g-2">
                <div className="col-3 ac">
                    <div className="img-activity tamano-1">
                        <img
                            src={element.imagen}
                            className="img-fluid img-act-preview"
                            alt="..."
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="card-body my-3 card-body-act">
                        <h5 className="card-title">
                            {element.titulo}
                        </h5>
                        <p className="card-text textLimited">
                            {element.descripcion}
                        </p>
                        <h5 className="card-title">
                            Puntaje máximo: <span>{element?.maxPuntos||'0'}
                                <img
                                    src={`${process.env.PUBLIC_URL}/resources/points-tg.svg`}
                                    className="img-fluid ms-2 mb-1"
                                    alt="..." width="20"
                                />
                            </span>
                        </h5>
                        <h5 className="card-title">
                            Fecha final: <span>{new Date(element?.fechaFin)?.toLocaleDateString() || ''}</span>
                        </h5>
                    </div>
                </div>
                <div className="col-3 text-center">
                    <div>
                    {element?.estado == 'Aprobada' && (
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL}/resources/Aprobada.png`}
                                    className="img-fluid"
                                    alt="img-Aprobada"
                                />
                                <button className='btn btn-status fs-'> {(element?.puntos||element?.puntos!='0')?capitalizeFirstLetter(element?.puntos+' Puntos'):'Aprobada'}</button>
                            </div>
                        )}
                          {element?.estado == 'Finalizada-Pendiente' && (
                            <div>
                               <img
                            src={`${process.env.PUBLIC_URL}/resources/Pendiente.png`}
                            className="img-fluid"
                            alt="img-Pendiente"
                        />
                                <button className='btn btn-status fs-'> {'Pendiente'}</button>
                            </div>
                        )}
                        {element?.estado == 'Pendiente'      && <img
                            src={`${process.env.PUBLIC_URL}/resources/Pendiente.png`}
                            className="img-fluid"
                            alt="img-Pendiente"
                        />}
                        {element?.estado == 'Rechazada' && <img
                            src={`${process.env.PUBLIC_URL}/resources/Rechazada.png`}
                            className="img-fluid"
                            alt="img-Rechazada"
                        />}
                        {element?.estado == 'Por hacer' && <img
                            src={`${process.env.PUBLIC_URL}/resources/Por_hacer.png`}
                            className="img-fluid"
                            alt="img-Rechazada"
                        />}
                        {(element?.estado == 'Finalizada' || element?.estado == 'Finalizado') && <img
                            src={`${process.env.PUBLIC_URL}/resources/Finalizada.png`}
                            className="img-fluid"
                            alt="img-Rechazada"
                        />}
                    </div>
                    {
                        element?.estado != 'Aprobada'  && element?.estado != 'Finalizada-Pendiente' && (
                            <button className='btn btn-status fs-'> {capitalizeFirstLetter(element?.estado || '')}</button>
                        )
                    }
                </div>
            </div>

        </div>
    )
}
