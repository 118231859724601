import React, { useState } from 'react';
import Menu from '../menu';
import { deleteSession } from '../../../api/userSession';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getDomain } from '../../../config/domain';

const LateralMenu = (props: any) => {
  function logout() {
    deleteSession();
  }
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  return (
    <div>
      <Menu />
      <div className="lateralmenu">
        <NavLink
          className="icon-menu d-md-none"
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <i className="bi bi-list nav_icon"></i>
        </NavLink>

        <div className="NavIconSidebar">


            <NavLink
                className="icon-menu"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
            >
              <i className="bi bi-list nav_icon"></i>
            </NavLink>
          <Nav navbar>
            <NavItem>
              <Link to="/" className="nav-link">
                <i className="bi bi-house-door-fill nav_icon"></i>
              </Link>
            </NavItem>
            <NavItem>
              <NavLink href={domainData.linkSupport} target="_blank">
                <i className="bi bi-headset nav_icon"></i>
              </NavLink>
            </NavItem>

            <NavItem className="last-item">
              <NavLink onClick={logout} className="logout">
                <i className="bi bi-door-open-fill nav_icon"></i>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div
          className="offcanvas offcanvas-start"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel" data-bs-scroll="true"
        >
          <div className="offcanvas-header">
            <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
          </div>
          <Nav navbar>
            <NavItem>
              <Link to="/landing" className="nav-link">
                <i className="bi bi-house-door-fill nav_icon"></i>
                <span>Inicio</span>
              </Link>
            </NavItem>
            <NavItem>
              <NavLink href={domainData.linkSupport} target="_blank">
                <i className="bi bi-headset nav_icon"></i>
                <span>Soporte</span>
              </NavLink>
            </NavItem>

            <NavItem className="last-item">
              <NavLink onClick={logout} className="logout">
                <i className="bi bi-door-open-fill nav_icon"></i>
                <span>Cerrar Sesión</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
      {/*Menu Footer*/}
      <div className="menu-footer d-md-none">
        <Nav>
          <NavItem>
            <NavLink href="/landing">
              <i className="bi bi-house-door-fill nav_icon"></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/welfare-events">
              <i className="bi bi-calendar-event nav_icon"></i>
            </NavLink>
          </NavItem>
          <div className="nav_link">
            <div className="icon-notifications">
              <i className="bi bi-bell-fill nav_icon"></i>
              {/* <span>3</span>*/}
            </div>
          </div>
          <NavItem>
            <NavLink href={domainData.linkSupport} target="_blank">
              <i className="bi bi-headset nav_icon"></i>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default LateralMenu;
