import React from 'react';
import { Link } from 'react-router-dom';

const filters = [
  { name: 'En-curso', status: false },
  { name: 'Tus-concursos', status: false },
];
interface IProps {
  onClickAll?: () => void;
  all?: boolean;
  onClick?: (e: any) => void;
  filters?: { name: string; status: boolean }[];

  imgs?: {
    author: string;
    tag: string;
    src: string;
  }[];
}

const Filters = ({ onClickAll, all, onClick, filters }: IProps) => (
  <form className="items_list_competitions">
    <ul className="items">
      <li onClick={onClickAll}>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" checked={all} />
          <label className="form-check-label" htmlFor="all">
            All
          </label>
        </div>
      </li>
      {/* {filters?.map((filter: any, i: number) => (
                <li key={i} data-index={i} onClick={onClick}>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id={filter.name} type="checkbox" checked={filter.status} />
                        <label className="form-check-label" htmlFor={filter.name}>{filter.name}</label>
                    </div>
                </li>
            ))} */}
    </ul>
  </form>
);

const Cards = ({ imgs }: IProps) => (
  <div className="row items_competitions">
    {imgs?.map((img: any, i: number) => (
      <div className="col-md-6 mb-4" key={i}>
        <figure>
          <div className="card competition-card">
            <div className="img-competition">
              <div className="content-box mx-auto">
                <img
                  src={img.src}
                  alt={img.author}
                  className="card-img-top img-ee "
                />
              </div>
            </div>
            <div className="card-body">
              <div className="text-inf">
                <h5 className="card-title">{img.title_info}</h5>
                <p className="card-text">{img.text_info}</p>
                <div className="text-end">
                  <Link to={`/competitions/ContestDetails?${img.id}`}>
                    Ver más
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </figure>
      </div>
    ))}
  </div>
);
type MyProps = {
  imgs: any[];
};
export class Competitions extends React.Component<MyProps> {
  imgs: any = this.props.imgs;

  state = {
    imgs: this.props.imgs,
    filters,
    all: true,
  };
  componentDidMount() {}
  componentDidUpdate() {
    // console.log("Propiedades entrantes a el Segundo componente:   ",this.props)
    filters.forEach((filter) => {
      filter.status = false;
    });
  }
  setFilter = (e: any) => {
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[index].status = !filters[index].status;
    this.setState({
      filters,
    });

    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters, all } = this.state;
    let newImgs: any[] = [];
    let a = 0;

    this.imgs.forEach((img: any, imgKey: number) => {
      filters.forEach((filter, filterKey) => {
        if (img.tag == filter.name && filter.status == true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }

  render() {
    const { filters, all } = this.state;

    return (
      <div>
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{localStorage.getItem('appName')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Concursos
                  </li>
                </ol>
              </nav>
              <div className="col-12">
                <Filters
                  onClickAll={this.setAll}
                  all={all}
                  onClick={this.setFilter}
                  filters={filters}
                />
                {all ? (
                  <Cards
                    imgs={
                      this.imgs
                        ? this.imgs
                        : [
                            {
                              author: '',
                              tag: '',
                              src: '',
                            },
                          ]
                    }
                  />
                ) : (
                  <Cards imgs={this.state.imgs} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
