import React, { ChangeEvent, useState } from 'react';
import { Document, Page } from 'react-pdf';
import PDFViewer from './PDFViewer';

import './UploadPDF.scss';

const UploadPdf = () => {
  const [selectedFile, setSelectedFile] = useState<any[]>([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  // const [first, setfirst] = useState(second);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile((prevFile) => {
      const prevState = [...prevFile];
      const files = event.target.files;

      files && prevState.push(files[0]);

      return prevState;
    });
    setIsFilePicked(true);
  };

  const handleSubmission = () => {};

  console.log(selectedFile);

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div>
          <h1>UploadPdf</h1>
          <div>
            <label
              className="upload-input-label"
              role="button"
              htmlFor="inputFile"
            >
              <p className="mb-1">Upload PDF Items</p>
              <i className="bi bi-cloud-arrow-up" />
              <input
                id="inputFile"
                className="d-none"
                type="file"
                name="file"
                onChange={changeHandler}
                accept="application/pdf"
              />
            </label>
            <div className="mb-3">
              {isFilePicked ? (
                <div className="row">
                  <div className="col-4 pe-0">
                    {selectedFile.map(({ name = '' }) => (
                      <div
                        key={`file-${name}`}
                        role="button"
                        className="upload-tabs"
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                  <div className="col-8 px-0">
                    <div className="visualize-upload-pdf">
                      <PDFViewer fileUrl={selectedFile[0]} />
                    </div>
                  </div>
                </div>
              ) : (
                <p>Select a file to show details</p>
              )}
            </div>

            <div>
              <button onClick={handleSubmission}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UploadPdf;
