import { useTranslation } from 'react-i18next';
import {
  deleteSession,
  useGetDataDiversitySearchQuery,
  useGetDataMarkSearchQuery,
  useGetDataNewsSearchQuery,
  useGetDataPillarSearchQuery,
  useGetDataProgramsEventsSearchQuery,
  useGetUserQuery,
} from '../../api/userSession';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { domain_country, getDomain } from '../../config/domain';
import { linksSection } from '../../modules/services/getElements.service';
import { apiMainURL } from '../../config/environment';

const FichasResults = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const elems = props.content;

  useEffect(() => {}, []);

  return (
    <div>
      {elems.length > 0 &&
        elems?.map((elem: any, index: number) => {
          return (
            <div className="mt-5" key={index}>
              <Link to={`/pillars/details?${elem?.id}`}>
                <h1 className="search-title-result mb-4"> {elem?.title}</h1>
              </Link>
              <p className="search-desc-result">{domainData.name} / Buscador</p>
              <p className="search-desc-result mb-4">
                {elem?.body?.substr(0, 300) || ''}
              </p>
              <hr />
            </div>
          );
        })}
    </div>
  );
};
export default FichasResults;
