export type eventTypes = {
  id?: string;
  date?: Date;
  hours?: string[];
  type?: string;
  title?: string;
  place?: string;
  image?: string;
  category?: string;
};

type stateTypes = {
  startDate?: string;
  endDate?: string;
  value?: string;
  category?: any;
  events?: eventTypes[] | [];
  eventsToShow?: eventTypes[] | [];
  categories?: { label: string; value?: string | number }[];
};

export enum actionCases {
  SET_START_DATE = 'SET_START_DATE',
  SET_END_DATE = 'SET_END_DATE',
  SET_CATEGORY = 'SET_CATEGORY',
  SET_EVENTS = 'SET_EVENTS',
  CHANGE_EVENTS_TO_SHOW = 'CHANGE_EVENTS_TO_SHOW',
}

type actionTypes = {
  type: actionCases;
  payload?: stateTypes;
};

export const initialState: stateTypes = {
  category: '',
  categories: [{ label: '', value: '' }],
  events: [],
  eventsToShow: [],
};

export const reducer = (state: stateTypes, action: actionTypes) => {
  switch (action.type) {
    case 'SET_START_DATE': {
      const { value } = action.payload!;
      return { ...state, startDate: value };
    }

    case 'SET_END_DATE': {
      const { value } = action.payload!;
      return { ...state, endDate: value };
    }

    case 'SET_CATEGORY': {
      const { value } = action.payload!;
      return { ...state, category: value };
    }

    case 'SET_EVENTS': {
      const { events, categories } = action.payload!;
      return { ...state, events, eventsToShow: events, categories };
    }

    case 'CHANGE_EVENTS_TO_SHOW': {
      const { startDate, endDate, category: stateCategory, events } = state;
      const from = startDate && new Date(startDate);
      const to = endDate && new Date(endDate);

      let eventsToShow =
        !stateCategory || stateCategory === 'Any'
          ? events
          : events?.filter(({ category }) => category === stateCategory);

      if (!to && from)
        eventsToShow = eventsToShow?.filter(({ date }) => date! >= from);
      else if (to && !from)
        eventsToShow = eventsToShow?.filter(({ date }) => date! <= to);
      else if (to && from)
        eventsToShow = eventsToShow?.filter(
          ({ date }) => date! >= from! && date! <= to!,
        );

      return { ...state, eventsToShow };
    }

    default:
      return initialState;
  }
};
