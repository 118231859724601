import {
  APP_URL,
  APP_URL_EC,
  APP_URL_MX,
  APP_URL_PE,
  fontEC,
  fontMX,
  fontPE,
} from './environment';

interface Map {
  [key: string]: string | undefined;
}

//CONFIGURE DEFAULT
let objectCustom = {
  color: 'main-ec',
  fonts: fontEC,
};
function setObjectCustom(color: string, font: string): any {
  return {
    color: color,
    fonts: font,
  };
}

//CONFIGURE CUSTOM COLORS && FONTS
export const domain_country = (str: string) => {
  if (equalURL(str, APP_URL_MX || '')) {
    return setObjectCustom('main-mx', fontMX);
  }
  if (equalURL(str, APP_URL_EC || '')) {
    return setObjectCustom('main-ec', fontEC);
  }
  if (equalURL(str, APP_URL_PE || '')) {
    return setObjectCustom('main-pe', fontPE);
  }
  if (equalURL(str, APP_URL || '')) {
    return setObjectCustom('main-mx', fontEC);
  }
};
export const getDomain = (str: string) => {
  localStorage.setItem('str', str);
  if (equalURL(str, APP_URL_MX || '')) {
    localStorage.setItem('appName', 'Embajadores Modelo');
    return {
      policy:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2022-11/080921%20T%C3%A9rminos%20y%20Condiciones%20SOMOS%20MODELO.pdf',
      terms:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2022-11/080921%20T%C3%A9rminos%20y%20Condiciones%20SOMOS%20MODELO.pdf',
      country: 'MEX',
      countryGammification:'MEX',
      name: 'Somos Modelo',
      title: 'El lado cerveza de la vida',
      wellcome: 'Te damos la bienvenida',
      appURL: APP_URL_MX,
      marks: 'Nuestras Marcas',
      programs: 'Programas',
      titlePrograms: 'Programas',
      descPrograms:
        ' Para generar un cambio genuino, necesitamos medir nuestro impacto y avances a nivel compañí­a. Aquí­ podrás ver nuestras cifras de diversidad dentro de Cerveceria Nacional. Recuerda que generar un ambiente mÃ¡s inclusivo es tarea de todos.',
      diversityTitle: 'Diversidad, Equidad e inclusión',
      diversityMedia: {
        video: null,
        info: `https://i.ibb.co/NSvMw8P/Ingografia-Diversidade-Inclusion.jpg`,
      },
      linkSupport: 'https://soporte.somosmaz.com/pqr/SomosModelo',
      description: {
        id: 'description-MX',
        content: 'Esta es la descripcion de MEXICO',
      },
      gaCode: 'GTM-N8KJNN2',
    };
  }
  if (equalURL(str, APP_URL_EC || '')) {
    localStorage.setItem('appName', 'Planeta Beerway');

    return {
      policy:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2022-12/Aviso%20integral%20de%20privacidad%20PLANETA%20BEERWAY-Ecuador.pdf',
      terms:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2021-09/TERMINOS%20Y%20CONDICIONES%20PLANETA%20BEERWAY.pdf',
      country: 'ECU',
      countryGammification:'EC',
      name: 'Planeta Beerway',
      title: 'El lado cerveza de la vida',
      wellcome: 'Te damos la bienvenida',
      appURL: APP_URL_EC,
      marks: 'Nuestras Marcas',
      programs: 'Programas',
      titlePrograms: 'Programas',
      descPrograms:
        ' Para generar un cambio genuino, necesitamos medir nuestro impacto y avances a nivel compañía. Aquí­ podrás ver nuestras cifras de diversidad dentro de Cerveceria Nacional. Recuerda que generar un ambiente mÃ¡s inclusivo es tarea de todos.',
      diversityTitle: 'Diversidad',
      diversityMedia: {
        video: null,
        info: `${process.env.PUBLIC_URL}/resources/imginfodiversidad.jpg`,
      },
      linkSupport: 'https://soporte.somosmaz.com/pqr/PlanetaBeerway',
      description: {
        id: 'description-EC',
        content: 'Esta es la descripcion de ECUADOR',
      },
      gaCode: 'GTM-WTBN84W',
    };
  }
  if (equalURL(str, APP_URL_PE || '')) {
    localStorage.setItem('appName', 'Beer Passion');

    return {
      policy:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2021-10/TERMINOS%20Y%20CONDICIONES%20BEER%20PASSION%20%2826.10.2021%29.pdf',
      terms:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2021-10/TERMINOS%20Y%20CONDICIONES%20BEER%20PASSION%20%2826.10.2021%29.pdf',
      country: 'PER',
      countryGammification:'PE',

      name: 'Beer Passion',
      title: 'Nuestra cultura a un solo clic',
      wellcome: 'Te damos la bienvenida a nuestra Intranet BU Perú',
      appURL: APP_URL_PE,
      marks: 'Reconocimientos',
      titlePrograms: 'Conócelos aquí­',
      programs: 'Nuestros programas',
      descPrograms: '',
      diversityTitle: 'Diversidad,',

      diversityMedia: {
        video: `${process.env.PUBLIC_URL}/resources/diversity_pe.mp4`,
        info: null,
      },
      linkSupport: 'https://soporte.somosmaz.com/pqr/BeerPassion',
      description: {
        id: 'description-PE',
        content: 'Esta es la descripcion de PERU',
      },
      gaCode: 'GTM-K3LR3GJ',
    };
  }
  if (equalURL(str, APP_URL || '')) {
    localStorage.setItem('appName', 'App Local');

    return {
      policy:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2022-11/080921%20T%C3%A9rminos%20y%20Condiciones%20SOMOS%20MODELO.pdf',
      terms:
        'https://api-beerambassador.somosmaz.com/sites/default/files/2022-11/080921%20T%C3%A9rminos%20y%20Condiciones%20SOMOS%20MODELO.pdf',
      country: 'CO',
      countryGammification:'MEX',
      name: 'Somos Modelo Local',
      title: 'El lado cerveza de la vida local',
      wellcome: 'Te damos la bienvenida a nuestra LOCAL  BU',
      appURL: APP_URL,
      marks: 'Reconocimientos',
      titlePrograms: 'ConÃ³celos aquÃ­',
      programs: 'Nuestros programas',
      descPrograms: '',
      diversityTitle: 'Diversidad, Equidad e inclusiÃ³n',
      diversityMedia: {
        video: `${process.env.PUBLIC_URL}/resources/diversity_pe.mp4`,
        info: `https://i.ibb.co/NSvMw8P/Ingografia-Diversidade-Inclusion.jpg`,
      },
      linkSupport: 'https://soporte.somosmaz.com/pqr/SomosModelo',
      description: {
        id: 'description-LOCAL',
        content: 'Esta es la descripcion de LOCAL',
      },
      supportLink: '',
    };
  }
  return {
    policy: '',
    terms: '',
    country: 'PER',
    name: 'Embajadores Modelo',
    title: 'El lado cerveza de la vida',
    wellcome: 'Te damos la bienvenida',
    appURL: APP_URL_MX,
    marks: 'Nuestras Marcas',
    titlePrograms: 'ConÃ³celos aquÃ­',
    programs: 'Programas',
    descPrograms:
      ' Para generar un cambio genuino, necesitamos medir nuestro impacto y avances a nivel compaÃ±Ã­a. AquÃ­ podrÃ¡s ver nuestras cifras de diversidad dentro de Cerveceria Nacional. Recuerda que generar un ambiente mÃ¡s inclusivo es tarea de todos.',
    diversityTitle: 'Diversidad',
    diversityMedia: {
      video: null,
      info: null,
    },
    linkSupport:
      'https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NsdUdCsBLUdBqkxYWsb-alhUMDlCNTNVMVlXNkZNWVlFV0VSMEwzNDNURy4u',
    description: {
      id: 'description-LOCAL',
      content: 'Esta es la descripcion de LOCAL',
    },
    gaCode: 'GTM-K3LR3GJ',
    supportLink: '',
  };
};

export const equalURL = (str: string, str2: string) => {
  var str1 = str.substring(0, str.length - 1);
  return new RegExp(str1, 'i').test(str2);
};
