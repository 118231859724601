import { useState } from 'react';

const OrderResume = (props: any): JSX.Element => {
  const type = props?.type;
  const summary = props?.summary;
  const [selectedOption, setSelectedOption] = useState('1');
  const options = ['1', '2', '3', '4', '5', '6', '7', '8', '8', '10'];
  const mediaMatch = window.matchMedia('(min-width: 576px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  console.log('####ORDER-  Type:  ', type);
  console.log('####ORDER-  Summary:  ', summary);
  return (
    <div>
      <p className="fs-5 mb-3">Resumen del Pedido</p>
      {type == '3' && (
        <div className="mb-5" style={{ marginTop: matches ? '0' : '20px' }}>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item rounded-4 border-0 shadow-none ">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button rounded-4 ba-qr"
                  style={{
                    fontSize: '12px',
                    color: 'white',
                  }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Ver código QR
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="mx-auto border rounded-4 p-2 qr-card">
                    <img
                      className="qr-image"
                      alt="Order Beer QR"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(type == '2' || type == '3') && (
        <div className="d-flex flex-column gap-2 mb-4">
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Tipo de Pago
            </p>
            <div className="d-flex align-items-center">
              <p
                className="mb-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Descuento de Nomina
              </p>
              <button
                type="button"
                className="btn dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Tu cupo de credito
            </p>
            <p
              className="mb-0"
              style={{ fontSize: '14px', marginRight: '2em' }}
            >
              $10.00
            </p>
          </div>
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              No de cuotas
            </p>
            <div className="d-flex align-items-center">
              <p
                className="mb-0"
                style={{
                  fontSize: '14px',
                }}
              >
                1
              </p>
              <button
                type="button"
                className="btn dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Descuento de Nomina
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Lugar de retiro
            </p>
            <p
              className="mb-0"
              style={{ fontSize: '14px', marginRight: '2em' }}
            >
              Guayaquil
            </p>
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between text-muted">
          <p style={{ fontSize: '12px' }}>Subtotal</p>
          <p style={{ fontSize: '12px' }}> {summary?.total}</p>
        </div>
        {/* <div className="d-flex justify-content-between text-muted">
            <p style={{ fontSize: "12px" }}>Entrega/Envio</p>
            <p style={{ fontSize: "12px" }}>Gratis</p>
          </div> */}
      </div>
      <hr className="m-0 mb-3" />
      <div className="d-flex justify-content-between mb-2">
        <p className="fw-bolder" style={{ fontSize: '12px' }}>
          Total
        </p>
        <p className="fw-bolder" style={{ fontSize: '12px' }}>
          {summary?.total}
        </p>
      </div>
    </div>
  );
};

export default OrderResume;
