import React from 'react'

export const FeedbackTour = (props: any) => {
    const { description, btn } = props;
    console.log("PROPIEDADES EN FEEDBACK:  ", props);
    if (description && btn)
        return (
            <div className='row'>
                <div className="col col-12 col-xl-9">
                    {description}
                </div>
                <div className="col col-12 col-xl-3 right-btn ">
                    <button className='btn btn-opinion' onClick={() => window.open(btn?.url, '_blank', 'noopener,noreferrer')}><span><i className="bi bi-heart-fill mx-1"></i>{btn?.title}</span> </button>
                </div>
                {/* <img
                                    src={`${process.env.PUBLIC_URL}/resources/points-tg.svg`}
                                    className="img-fluid ms-2 mb-1"
                                    alt="..." width="20"
                                /> */}
            </div>
        )
    else return <></>
}
