import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  CarouselItem,
} from 'reactstrap';
import CarouselView from '../../carousel/carousel';
import { domain_country, getDomain } from '../../../config/domain';
import Carousel from '../../carousel/carousel';
import {
  deleteSession,
  useGetBannerSectionApiQuery,
  useGetContentMediaSectionApiQuery,
  useGetContentSectionApiQuery,
} from '../../../api/userSession';
import { cultureId, landingId } from '../../../modules/services/sections';
import {
  bannersInSection,
  bannersInSectionEcommerce,
  contentInSection,
} from '../../../modules/services/getElements.service';
import { apiMainURL } from '../../../config/environment';
import CardItemProduct from './card-item-product';

export const DetailProduct = (props: any) => {

  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const calification: any[] = ['1', '2', '3', '4', '5'];

  const product = {
    img: [
      'https://i.ibb.co/9p3jYb4/4.jpg',
      'https://i.ibb.co/Qkhg6M1/stella-2.jpg',
      'https://i.ibb.co/9p3jYb4/4.jpg',
    ],
    name: 'Stella Artois2',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '2.000',
    id: '34',
    like: false,
    type: 'beer',
  };
  const [mainImage, setMainImage] = useState<string>(product?.img.pop()||'');
  const elem = {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Stella Artois',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  };
  const elem2 = {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Corona',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
    gallery:[
      {
          "type": "image",
          "content": {
              "src": "https://api-beerambassador-dev.somosmaz.com/sites/default/files/2022-03/Captura%20de%20Pantalla%202022-03-31%20a%20la%28s%29%202.30.02%20p.m..png"
          }
      },
      {
          "type": "image",
          "content": {
              "src": "https://api-beerambassador-dev.somosmaz.com/sites/default/files/2022-03/Captura%20de%20Pantalla%202022-03-31%20a%20la%28s%29%202.30.54%20p.m..png"
          }
      },
      {
          "type": "image",
          "content": {
              "src": "https://api-beerambassador-dev.somosmaz.com/sites/default/files/2022-03/Captura%20de%20Pantalla%202022-03-31%20a%20la%28s%29%202.31.06%20p.m..png"
          }
      },
      {
          "type": "video",
          "content": {
              "src": "https://api-beerambassador-dev.somosmaz.com/sites/default/files/2022-03/240078301_1192630394538302_3128023314167321620_n.mp4"
          }
      }
  ]
  };
  function handleImage(e: any) {
    setMainImage(e);
  }
  let dataBannersApi: any = {};
  var banners: Element[] = [];

  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  banners = bannersInSectionEcommerce(dataBannersApi);

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/e-commerce/catalogue">Cervezas</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {product?.name}
                  </li>
                </ol>
              </nav>
              <div className="d-flex justify-content-between justify-content-md-end py-0">
                <div className="col-auto col-md-auto col-lg-2 my-3 px-3 py-0 me-md-2">
                  <div className="link-geo ms-md-3" onClick={toggle}>
                    <div>
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="text">
                      {' '}
                      Recoger en
                      <br />
                      <span>Guayaquil</span>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    modalTransition={{ timeout: 1000 }}
                    className="modal-dialog-centered"
                  >
                    <ModalBody>
                      Selecciona el lugar donde quieres reclamar
                      <span>
                        La disponibilidad de los productos varía según el punto
                        de elección
                      </span>
                      <div>
                        <select>
                          <option value="">Seleccionar...</option>
                          <option value="1"> Cervecería Unión </option>
                          <option value="2"> Cervecería de Tocancipá </option>
                          <option value="3">
                            {' '}
                            Cervecería de Barranquilla{' '}
                          </option>
                          <option value="4"> Cervecería del Valle </option>
                          <option value="5"> Cervecería de Bucaramanga </option>
                          <option value="6"> Cervecería de Boyacá </option>
                          <option value="7"> Edificio 127 </option>
                          <option value="8"> Impresora del Sur </option>
                          <option value="9"> Indugral </option>
                          <option value="10"> Malteria Tibitó </option>
                          <option value="11"> Malteria Tropical </option>
                          <option value="12">
                            {' '}
                            R. Centro CD San Fernando{' '}
                          </option>
                          <option value="13"> R. Centro Calle 22 </option>
                          <option value="14"> R. Centro Amerícas </option>
                          <option value="15"> R. Centro AutoSur </option>
                          <option value="16"> R. Andes CD Armenia </option>
                        </select>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
                <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 ml-5">
                  <div className="row">
                    <div className="col col-lg-6 col-xl-6 col-md-6 col-sm-12 continuous-buttons">
                      <div className="link-geo">
                        <Link to="/e-commerce/shopping-cart">
                          <i className="bi bi-cart3"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="col col-lg-6 col-xl-6 col-md-6 col-sm-12 continuous-buttons">
                      <div className="link-geo">
                        <Link to="/e-commerce/favorites">
                          <i className="bi bi-heart fav-icon-m"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row col-12 col-lg-6 col-xl-8">
                  <div className=" col-12 col-lg-3 col-xl-3">
                    {product?.img?.map((e: any, i: number) => {
                      return (
                        <img
                          className="img-product-main"
                          src={e}
                          alt="img-product-main"
                          onClick={() => handleImage(e)}
                        />
                      );
                    })}
                  </div>
                  <div className="col-12 col-lg-9 col-xl-9">
                    <div className="Carousel-Ecommerce">
                      <img
                        className="img-product-main"
                        src={mainImage}
                        alt="img-product-main"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 main-tracking">
                  <div>
                    <div className="d-table w-100 mt-5">
                      <div className="title-detail-product mb-1">
                        Stella Artois
                      </div>
                      <p className="title-vol-product">LTA 335 ml x 6</p>
                      <p className="card-text">
                        {calification.map((cal: any, indexC: number) => {
                          return (
                            <img
                              src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                              alt=""
                              className="star-calification"
                              key={indexC}
                            />
                          );
                        })}
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="product-legend-price">Precio:</div>
                      <div className="product-price">{product.price}</div>
                      <div className="mt-5">
                        <button className="btn btn-primary btn-detail-operation">
                          Agregar a carrito de compras
                        </button>
                        <div className="">
                          <button className="btn mt-3 btn-detail-operation btn-fav">
                            Favoritos<i className="bi bi-heart fav-icon-m"></i>
                          </button>
                        </div>
                        <div className="mt-4 text-points-info">
                          Gana 48000 puntos con esta compra
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row related-content-color">
                <div className="col-12">
                  <div className="overflow-hidden mt-5">
                    <div className="title-welcome">Contenido relacionado</div>
                    <div className="row  mt-sm-4 items-products">
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xl-4 col-lg-4 cl-md-12 col-sm-12 mb-3">
                        <div className="card related-card">
                          <div className="row">
                            <div className="col col-lg-6">
                              <img
                                src="https://i.ibb.co/9p3jYb4/4.jpg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col related-text">
                              <div className="title-related-card">
                                Proceso de la cerveza
                              </div>
                              <div className="description-related-card mt-2">
                                Aprende todo sobre el proceso cervecero, los
                                ingredientes, procesos, tipos de cerveza,
                                maridaje y mucho más!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="overflow-hidden mt-5">
                    <div className="title-welcome">
                      Tambien podria interesarte
                    </div>
                    <div className="row  mt-sm-4 items-products">
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-3">
                        <CardItemProduct
                          content={elem2}
                          className="card card-product"
                        ></CardItemProduct>
                      </div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-3">
                        <CardItemProduct
                          content={elem2}
                          className="card card-product"
                        ></CardItemProduct>
                      </div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-3">
                        <CardItemProduct
                          content={elem2}
                          className="card card-product"
                        ></CardItemProduct>
                      </div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-3">
                        <CardItemProduct
                          content={elem2}
                          className="card card-product"
                        ></CardItemProduct>
                      </div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-3">
                        <CardItemProduct
                          content={elem2}
                          className="card card-product"
                        ></CardItemProduct>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailProduct;
