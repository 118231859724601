import React, { memo, RefObject } from 'react';

import { Popover, PopoverBody } from 'reactstrap';

type AppsPopoverProps = {
  isOpen: boolean;
  target: string | HTMLElement | RefObject<HTMLElement>;
  toggle: () => void;
  elements: { url: string; img: string; label: string }[];
};

export const AppsPopover = memo(
  ({ target, isOpen, toggle, elements }: AppsPopoverProps) => {
    return (
      <Popover
        {...{ toggle, isOpen, target }}
        placement="bottom"
        className="app-menu-popover"
      >
        <PopoverBody className="app-menu-popover-body">
          <div className="row px-3 row-cols-3">
            {elements.map(({ img, label, url }) => (
              <div
                key={`img-${img}-${label}-${Math.random()}`}
                className="col d-flex"
              >
                <a href={url} target="_blank" rel="noreferrer">
                  <img className="mx-auto" src={img} alt={img} />
                  <p className="mb-0">{label}</p>
                </a>
              </div>
            ))}
          </div>
        </PopoverBody>
      </Popover>
    );
  },
);

export default AppsPopover;
