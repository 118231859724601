import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { actionCases } from '../reducer';

type FilterControlProps = {
  onInputChange: (
    e: { target: { value: string } },
    action: actionCases,
  ) => void;
  onFilterSubmit: () => void;
  categories?: { label: string; value?: string | number }[];
};

const FilterControl = ({
  onInputChange,
  onFilterSubmit,
  categories,
}: FilterControlProps) => {
  const onControllerSubmit = (e: any) => {
    e.preventDefault();

    onFilterSubmit();
  };

  const onCategoryChange = (e: any) => {
    console.log(e.target.value);
  };

  return (
    <div className="w-100">
      <Form onSubmit={onControllerSubmit}>
        <div className="row row-cols-sm-2 row-cols-lg-4 gap-y-1">
          <FormGroup>
            <div className="col mb-2">
              <Label
                for="event-start-date"
                className="fw-bolder mb-2 text-muted"
              >
                Start Date
              </Label>
              <Input
                className="text-muted"
                id="event-start-date"
                name="date"
                placeholder="Choose Date"
                type="date"
                onChange={(e) => onInputChange(e, actionCases.SET_START_DATE)}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col mb-2">
              <Label for="event-end-date" className="fw-bolder mb-2 text-muted">
                End Date
              </Label>
              <Input
                className="text-muted"
                id="event-end-date"
                name="date"
                placeholder="Choose Date"
                type="date"
                onChange={(e) => onInputChange(e, actionCases.SET_END_DATE)}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col mb-2">
              <Label
                for="event-categories"
                className="fw-bolder mb-2 text-muted"
              >
                Category
              </Label>
              <Input
                id="event-categories"
                name="select"
                type="select"
                onChange={(e) => onInputChange(e, actionCases.SET_CATEGORY)}
              >
                {categories?.map(({ label, value }) => (
                  <option key={`category-option-${label}`} {...{ value }}>
                    {label}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
          <div className="col mb-2 d-flex align-items-end">
            <button
              className="mb-0 border w-100 p-2 rounded-1 mt-2"
              style={{
                backgroundColor: '#13bca3',
                borderColor: '#13bca3',
                color: 'white',
                marginBottom: '1.05rem',
              }}
              type="submit"
            >
              FIND EVENT
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FilterControl;
