import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getActivitiesApi, getStagesApi } from '../../../../api/userSession';
import { store } from '../../../../modules/appState/store/store';
import { getAllActivitiesData, getStagesData } from '../GamificationServices/gamification-service';
import LoadSpinner from '../../../load-spinner/load-spinner';
import { Opacity } from '@material-ui/icons';

export const ShortListElements = (props: any) => {
    const { type, tour, title } = props;
    const [stages, setStages] = useState<any[]>([]);
    const [intoStage, setIntoStage] = useState<boolean>(false);
    useEffect(() => {
        if (tour?.id && tour?.band && !intoStage) {
            getStagesApi(tour?.id, tour?.band).then((elem: any) => {
                let t = getStagesData(elem, type, tour);
                setIntoStage(true);
                if (t) setStages(t);
            }).catch((error: any) => console.log("Error query:  ", error));
        }
    }, [stages, intoStage]);
    if (intoStage) {
        if (stages?.length > 0)
            return (
                <div className="">
                    <h3 className='fw-bold pt-5'>{title}</h3>
                    <div className="list-stages">
                        <div className="row gy-4 justify-content-center justify-content-md-start">
                            {stages?.map((el: any, indexEl: number) => {
                                return (
                                    <CardStageList type={type} el={el} tour={tour} />

                                )
                            })}
                        </div>
                    </div>
                </div>


            )
        else return <></>
    }
    else {
        return <LoadSpinner />
    }
}


export const CardStageList = (props: any) => {
    const { type, el, tour } = props;
    const [activities, setActivities] = useState<any[]>([]);
    const [pendientes, setPendientes] = useState<number>(0);
    // const [isOpen, setIsOpen] = useState<boolean>(true);
    useEffect(() => {
        // if (!isOpen)
        //     getActivitiesApi(el?.id).then((elem: any) => {
        //         let t = getAllActivitiesData(elem, el?.id, tour?.status, tour?.points);
        //         if (t) {
        //             setActivities(t);
        //             setIsOpen(true)
        //         }
        //     }).catch((error: any) => console.log("Error query:  ", error));

    }, [activities]);
    function getPendientes(statuses: any[], idStage: string) {
        let contPendientes = 0;
        Object?.entries(statuses)?.map((propObject: any) => {
            if (propObject[0] == idStage) {
                if (propObject[1])
                    Object?.entries(propObject[1])?.map((propObject2: any) => {
                        let fechaCierre = propObject2[1]?.end_date;
                        let fechaActual = new Date().toISOString();
                        if ((propObject2[1]?.field_status == 'Por hacer' || propObject2[1]?.field_status == 'Pendiente')) {
                            //TODO  if ((propObject2[1]?.field_status == 'Por hacer' || propObject2[1]?.field_status == 'Pendiente') && (fechaActual < fechaCierre)) {
                            contPendientes++;
                        }
                    });
            }
        });
        return contPendientes;
    }
    function getFinalizada(el:any):boolean {
        return new Date().toISOString()>el?.closed;
    }
    function getProximamente(el:any):boolean {
        return new Date().toISOString()<el?.started;
    }

    return (
        <div className="col-auto col-sm-6 col-md-4 col-xl-3" >
            {!getProximamente(el) &&(
                <Link to={type == 'stages' ? '/tour-de-gente/stages?' + el?.id : '/tour-de-gente/stations?' + el?.id}>
                <div className={'card '} style={{
                    border: `1px solid ${el?.colorPrincipal}`,
                    opacity: getFinalizada(el)?0.5:1
                }}
                >
                    <div className="card-header" style={{
                        color: 'white',
                        backgroundColor: `${el?.colorPrincipal}`
                    }} >
                        <div className="title-box-tg">
                            <h5>{el?.tipo == 'stages' ? 'Tour de ' : 'Estación '}</h5>
                            <span>{el?.titulo}</span>
                            <p>{`${el?.fechaInicio} - ${el?.fechaFin}`}</p>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <div className="h-100 d-flex">
                                    <img src={el?.image} className="my-auto card-image pl-2 mx-auto h-auto " alt="..." style={{
                                        width: '80%'
                                    }} />
                                </div>
                            </div>
                            <div className="col-6 text-center pt-3"
                            >
                                <div className="numberActivities">
                                    <p style={{
                                        color: `${el?.colorPrincipal}`
                                    }}>
                                        {getPendientes(tour?.status, el?.id)}
                                    </p>
                                    <span> Actividades pendientes</span>
                                </div>

                            </div>

                            <div className="col-6 text-center points-card">
                                <p style={{
                                    color: `${el?.colorPrincipal}`
                                }}>{parseFloat(el?.tuPorcentaje || '0').toFixed(1)}%</p>
                                <span>Tu porcentaje</span>
                            </div>
                            <div className="col-6  text-center points-card">
                                <p style={{
                                    color: `${el?.colorPrincipal}`
                                }}> {parseFloat(el?.tuPuntaje || '0').toFixed(0)}</p>
                                <span>Tu puntaje</span>
                            </div>
                            <div className="col-12 text-center pt-3 ">
                                <button className='btn btn-act'
                                    style={{
                                        backgroundColor: `${el?.colorPrincipal}`
                                    }}>{getFinalizada(el)?'Finalizada':(getProximamente(el)?'Próximamente':'Ir a tus actividades')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            )}
            {getProximamente(el) &&(

                <div className={'card '} style={{
                    border: `1px solid ${el?.colorPrincipal}`,
                    opacity:( getFinalizada(el)||getProximamente(el))?0.5:1
                }}
                >
                    <div className="card-header" style={{
                        color: 'white',
                        backgroundColor: `${el?.colorPrincipal}`
                    }} >
                        <div className="title-box-tg">
                            <h5>{el?.tipo == 'stages' ? 'Tour de ' : 'Estación '}</h5>
                            <span>{el?.titulo}</span>
                            <p>{`${el?.fechaInicio} - ${el?.fechaFin}`}</p>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <div className="h-100 d-flex">
                                    <img src={el?.image} className="my-auto card-image pl-2 mx-auto h-auto " alt="..." style={{
                                        width: '80%'
                                    }} />
                                </div>
                            </div>
                            <div className="col-6 text-center pt-3"
                            >
                                <div className="numberActivities">
                                    <p style={{
                                        color: `${el?.colorPrincipal}`
                                    }}>
                                        {getPendientes(tour?.status, el?.id)}
                                    </p>
                                    <span> Actividades pendientes</span>
                                </div>

                            </div>

                            <div className="col-6 text-center points-card">
                                <p style={{
                                    color: `${el?.colorPrincipal}`
                                }}>{parseFloat(el?.tuPorcentaje || '0').toFixed(1)}%</p>
                                <span>Tu porcentaje</span>
                            </div>
                            <div className="col-6  text-center points-card">
                                <p style={{
                                    color: `${el?.colorPrincipal}`
                                }}> {parseFloat(el?.tuPuntaje || '0').toFixed(0)}</p>
                                <span>Tu puntaje</span>
                            </div>
                            <div className="col-12 text-center pt-3 ">
                                <button className='btn btn-act disabled text-white'
                                    style={{
                                        backgroundColor: `${el?.colorPrincipal}`
                                    }}>{getFinalizada(el)?'Finalizada':(getProximamente(el)?'Próximamente':'Ir a tus actividades')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
