import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDetailBlogDiversityApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { GetBodyInnerHTML } from '../../../../modules/services/getElements.service';

export const DetailDiversityBlog = (props: any) => {
  let dataPillar = useGetDetailBlogDiversityApiQuery(
    window.location.href.split('?')[1],
  ).data;
  var details = getDetailPillars(dataPillar);

  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  function getDetailPillars(section: any): any {
    var elements = {};

    let idImage = section?.data.relationships.field_image.data.id;
    let idMultimediaVideo = '';
    section?.data.relationships.field_contenido_multimedia.data.forEach(
      (media: any) => {
        idMultimediaVideo = media.id;
      },
    );
    let image = '';
    let node_video = '';
    section?.included?.map((include: any) => {
      if (idImage === include.id) {
        image = apiMainURL + include.attributes.uri.url;
      }
    });
    section?.included?.map((include: any) => {
      if (idMultimediaVideo === include.id) {
        node_video = include.relationships?.field_video?.data?.id;
      }
    });
    let media_video = '';
    section?.included?.map((include: any) => {
      if (node_video === include.id) {
        media_video = include.relationships.field_media_video_file.data.id;
      }
    });
    let file_file = '';
    section?.included?.map((include: any) => {
      if (media_video === include.id) {
        file_file = apiMainURL + include.attributes.uri.url;
      }
    });
    elements = {
      title: section?.data.attributes.title || '',
      subtitle: section?.data.attributes.field_subtitulo || '',
      image: image,
      video: file_file,
      description: section?.data.attributes?.body?.value || '',
      text1: section?.data.attributes?.field_informacion || '',
      text2: section?.data.attributes?.field_informacion_2 || '',
      link: section?.data.attributes.path?.alias || '',
      column: section?.data.attributes.field_column || '3',
      type: section?.data.attributes.field_tipo_articulo,
    };

    return elements;
  }

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row main_contests">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/diversity">Diversidad</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/diversity">Foco Diversidad</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {details?.type}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">{details?.title}</div>
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-7 mb-4">
                  <img
                    src={details?.image}
                    className="img-fluid rounded-4"
                    alt="..."
                  />
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="title-welcome fs-4"></div>
                  <GetBodyInnerHTML
                    className="info_programs"
                    content={details.description}
                  ></GetBodyInnerHTML>
                  <div className="text-center"></div>
                </div>
              </div>
              <GetBodyInnerHTML
                className="text-generic-r my-5"
                content={details?.text1}
              ></GetBodyInnerHTML>
            </div>
            <div className="col-12 mb-4">
              <a href="">
                {details.video && (
                  <div className="ratio ratio-21x9">
                    <video
                      src={details.video}
                      width="100%"
                      height="500"
                      controls
                    ></video>
                  </div>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
