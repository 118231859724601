import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';
import { } from '../../api/userSession';
import { MediaBanner } from './mediaBanner';

const CarouselView = (props: any) => {
  const items = props.content;
  const extern = (url: string) => {
    return /^(ftp|http|https):\/\/[^ "]+$/.test(url);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items?.map((item: any, indexItem: number) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={indexItem}
      >
        {extern(item.link) && (
          <a href={'' + item.link + ''} target="_blank" key={1}>
            {/* <MediaBanner></MediaBanner> */}
            {item.src.type == 'image' && (
              <img
                src={'' + item.src.src + ''}
                alt={item.altText}
                width="100%"
                height="750px"
                key={11}
              />
            )}
            {item.src.type == 'video' && (
              <div className="" key={12}>
                <video
                  src={item.src.src}
                  height="100%"
                  controls
                  autoPlay
                  muted
                ></video>
              </div>
            )}
             {item.src.type == 'remote-video' && (
              <div className="banner-video" key={12}>
              {/* <iframe className="embed-responsive-item" src={item.src.src}
                height="100%">
              </iframe> */}
              <iframe   height="100%" src={`https://www.youtube.com/embed/W3q8Od5qJio?&autoplay=1`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
            </div>
            )}
          </a>
        )}
        {!extern(item.link) && (
          <Link to={item.link || ''} key={2}>
            {/* <MediaBanner></MediaBanner> */}
            {item.src.type == 'image' && (
              <img
                src={'' + item.src.src + ''}
                alt={item.altText}
                width="100%"
                height="750"
                key={21}
              />
            )}
            {item.src.type == 'video' && (
              <div className="banner-video" key={22}>
                <video
                  src={item.src.src}
                  height="100%"
                  controls
                  autoPlay
                  muted
                ></video>
              </div>
            )}
             {item.src.type == 'remote-video' && (
              <div className="banner-video" key={12}>
                {/* <iframe className="embed-responsive-item" src={item.src.src}
                  height="100%">
                </iframe> */}
                 
                 {/* <iframe   height="100%" src={`https://www.youtube.com/embed/W3q8Od5qJio?&autoplay=1`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe> */}
                <iframe   height="100%" src={`https://www.youtube.com/embed/W3q8Od5qJio`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
              </div>
            )}
          </Link>
        )}
        <CarouselCaption captionText={''} captionHeader={''} />
        <CarouselCaption captionText={''} captionHeader={''} />
        {/* <CarouselCaption
          captionText={item.caption}
          captionHeader={item.altText}
        /> */}
      </CarouselItem>
    );
  });

  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={10000}
        key={Math.random()}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          key={Math.random()}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="<"
          onClickHandler={previous}
          key={Math.random()}
        />
        <CarouselControl
          direction="next"
          directionText=">"
          onClickHandler={next}
          key={Math.random()}
        />
      </Carousel>
    </>
  );
};

export default CarouselView;
