import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDetailBlogDiversityApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { GetBodyInnerHTML } from '../../../../modules/services/getElements.service';

const DetailDiversity = () => {
  let dataPillar = useGetDetailBlogDiversityApiQuery(
    window.location.href.split('?')[1],
  ).data;

  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  var details = getDetailPillars(dataPillar);
  function getDetailPillars(section: any): any {
    var elements = {};
    let ids_Infografia: string[] = [];
    let infografias: any[] = [];
    let idsResourcesInfografia: string[] = [];
    let idImage = section?.data?.relationships?.field_image?.data?.id || '';
    let idMultimediaVideo = '';
    section?.data.relationships.field_contenido_multimedia.data.forEach(
      (media: any) => {
        if (media.type == 'node--infografia') {
          ids_Infografia.push(media.id);
        } else {
          idMultimediaVideo = media.id;
        }
      },
    );
    let image = '';
    let node_video = '';
    section?.included?.map((include: any) => {
      if (idImage === include.id) {
        image = apiMainURL + include.attributes.uri.url;
      }
    });
    section?.included?.map((include: any) => {
      if (idMultimediaVideo === include.id) {
        node_video = include.relationships?.field_video?.data?.id;
      }
    });
    ids_Infografia.map((idInfo: string, indexInfo: number) => {
      section?.included?.map((include: any) => {
        if (idInfo === include.id) {
          let idResource = include.relationships.field_image.data.id;
          idsResourcesInfografia.push(idResource);
        }
      });
    });

    idsResourcesInfografia.map((id: string) => {
      section?.included?.map((resource: any) => {
        if (resource.id == id) {
          infografias.push(apiMainURL + resource.attributes.uri.url);
        }
      });
    });

    let media_video = '';
    section?.included?.map((include: any) => {
      if (node_video === include.id) {
        media_video = include.relationships.field_media_video_file.data.id;
      }
    });
    let file_file = '';
    section?.included?.map((include: any) => {
      if (media_video === include.id) {
        file_file = apiMainURL + include.attributes.uri.url;
      }
    });
    elements = {
      title: section?.data.attributes.title || '',
      subtitle: section?.data.attributes.field_subtitulo || '',
      image: image,
      video: file_file,
      description: section?.data.attributes?.body?.value || '',
      text1: section?.data.attributes?.field_informacion || '',
      text2: section?.data.attributes?.field_informacion_2 || '',
      link: section?.data.attributes.path?.alias || '',
      column: section?.data.attributes.field_column || '3',
      type: section?.data.attributes.field_tipo_articulo,
      infographics: infografias || [],
    };

    return elements;
  }
  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/diversity">Diversidad</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/diversity">Foco de Diversidad</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {details?.type}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">{details.title}</div>
              <div className="text-generic-m text-center my-4">
                {details.subtitle}
              </div>
              <div className="cover-page">
                <a href="">
                  <img src={details.image} className="img-fluid" height="320" />
                </a>
              </div>
              <GetBodyInnerHTML
                className="text-generic-r my-5"
                content={details.description}
              ></GetBodyInnerHTML>

              {details.video && (
                <div className="ratio ratio-21x9">
                  <video
                    src={details.video}
                    width="100%"
                    height="500"
                    controls
                  ></video>
                </div>
              )}

              <div className="text-center">
                {details.infographics.map((info: any, index: number) => {
                  return (
                    <img
                      key={index}
                      src={info}
                      alt="Infogr"
                      className="rounded img-fluid"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailDiversity;
