import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  useGetBannerSectionApiQuery,
  useGetContentMediaSectionApiQuery,
  useGetContentSectionApiQuery,
  useGetListDiversityFocusQuery,
} from '../../../api/userSession';
import { getDomain } from '../../../config/domain';
import { apiMainURL } from '../../../config/environment';
import { Section } from '../../../models/section.model';
import {
  bannersInSection,
  getURL,
} from '../../../modules/services/getElements.service';
import { cultureId, diversityId } from '../../../modules/services/sections';
import CarouselView from '../../carousel/carousel';
import Sections from '../../sections/sections';

export const Culture = (props: any) => {
  const [t, i18n] = useTranslation('global');
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  /* 
  const dataListDiversityFocusApi = useGetListDiversityFocusQuery('').data; */
  const dataBannersApi = useGetBannerSectionApiQuery(cultureId).data;
  var banners = bannersInSection(dataBannersApi);
  const dataContentApi = useGetContentMediaSectionApiQuery(cultureId).data;
  let ListMedia = getMedia(dataContentApi);

  let section: Section;

  useEffect(() => {}, []);

  function getMedia(section: any): any {
    var elements = {};
    let ids_Infografia: string[] = [];
    let infografias: any[] = [];
    let idsResourcesInfografia: string[] = [];
    let idImage = section?.data?.relationships?.field_image?.data?.id || '';
    let idMultimediaVideo = '';
    section?.data.forEach((sectionMedia: any) => {
      sectionMedia.relationships?.field_contenido_multimedia?.data.forEach(
        (media: any) => {
          if (media.type == 'node--infografia') {
            ids_Infografia.push(media.id);
          } else {
            idMultimediaVideo = media.id;
          }
        },
      );
    });
    let image = '';
    let node_video = '';
    section?.included?.map((include: any) => {
      if (idImage === include.id) {
        image = apiMainURL + include.attributes.uri.url;
      }
    });
    section?.included?.map((include: any) => {
      if (idMultimediaVideo === include.id) {
        node_video = include.relationships?.field_video?.data?.id;
      }
    });
    ids_Infografia.map((idInfo: string, indexInfo: number) => {
      section?.included?.map((include: any) => {
        if (idInfo === include.id) {
          let idResource = include.relationships.field_image.data.id;
          idsResourcesInfografia.push(idResource);
        }
      });
    });

    idsResourcesInfografia.map((id: string) => {
      section?.included?.map((resource: any) => {
        if (resource.id == id) {
          infografias.push(apiMainURL + resource.attributes.uri.url);
        }
      });
    });

    let media_video = '';
    section?.included?.map((include: any) => {
      if (node_video === include.id) {
        media_video = include.relationships.field_media_video_file.data.id;
      }
    });
    let file_file = '';
    section?.included?.map((include: any) => {
      if (media_video === include.id) {
        file_file = apiMainURL + include.attributes.uri.url;
      }
    });
    elements = {
      image: image || '',
      video: file_file || '',
      infographics: infografias || [],
    };
    return elements;
  }

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/landing"> {domainData.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nuestra Cultura
              </li>
            </ol>
          </nav>
          <h3 className="section-title">Nuestra Cultura</h3>
          <div className="row">
            <div className="col-1content={banners}2">
              <div className="title-welcome fs-2"></div>
              <CarouselView content={banners} />

              <h3 className="section-title"></h3>
              <div className="text-center">
                {ListMedia.infographics.map((info: any, index: number) => {
                  return (
                    <img
                      key={index}
                      src={info}
                      alt="Infogr"
                      className="rounded img-fluid"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
