import { ChangeEvent, FormEvent, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import {
  getCities,
  getPaymentTypes,
  postPaymentByOrder,
} from '../../api/userSession';
import { initialValues, orderResumeCases, orderResumeReducer } from './reducer';

type OrderResumeProps = { total: number; availableCredit: number };

const OrderResume = ({
  total,
  availableCredit,
}: OrderResumeProps): JSX.Element => {
  const { SET_PAYMENT, SET_PAYMENT_TYPES, SET_PHONE_NUMBER } = orderResumeCases;
  const history = useHistory();

  const [state, dispatch] = useReducer(orderResumeReducer, initialValues);

  const city = getCities().then(
    ({ data }) => data[0].attributes.drupal_internal__tid || 0,
  );

  useEffect(() => {
    const getData = async () => {
      const { data: paymentTypes } = await getPaymentTypes();

      const paymentTypesList = paymentTypes.map(
        ({
          attributes: { description, drupal_internal__tid },
        }: {
          attributes: {
            description: { value: string };
            drupal_internal__tid: number;
          };
        }) => ({
          value: drupal_internal__tid,
          label: description.value.replace(/(<(|\/)p>|\\r|\r|\\n|\n)+/g, ''),
        }),
      );

      dispatch({
        type: SET_PAYMENT_TYPES,
        payload: { paymentTypes: paymentTypesList },
      });
    };

    getData();
  }, [SET_PAYMENT_TYPES]);

  const onPhoneInputChange = async (
    { target: { value } }: ChangeEvent<{ value: string }>,
    type: string,
  ) =>
    dispatch({
      type: SET_PHONE_NUMBER,
      payload: { phoneNumber: value, zone: type },
    });

  const onPaymentTypeSelectChange = ({
    target: { value },
  }: ChangeEvent<{ value: string | number }>) =>
    dispatch({ type: SET_PAYMENT, payload: { paymentType: +value } });

  const onPaymentSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const { zone, paymentType, phoneNumber } = state;
    const phone = zone && phoneNumber ? `+(${zone}) ${phoneNumber}` : null;

    const res = await postPaymentByOrder(await city, phone, paymentType!);

    if (res === 'true') history.push('/e-commerce/shopping-cart');
  };

  return (
    <div className="d-table w-100 mt-5">
      <form onSubmit={onPaymentSubmit}>
        <p className="fs-5 mb-3">Resumen del Pedido</p>
        <div className="d-flex flex-column gap-2 mb-4">
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Tipo de Pago
            </p>
            <div className="d-flex align-items-center">
              <select
                className="form-select form-select-sm border-0"
                aria-label=".form-select-sm example"
                onChange={onPaymentTypeSelectChange}
              >
                {state.paymentTypes?.map(({ label, value }) => (
                  <option {...{ value }} key={`payment-option-${value}`}>
                    {label}
                  </option>
                )) || <option>No Payment Types</option>}
              </select>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Tu cupo de crédito
            </p>
            <p
              className="mb-0"
              style={{ fontSize: '14px', marginRight: '2em' }}
            >
              {`$ ${availableCredit}`}
            </p>
          </div>
          {/* <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              No de cuotas
            </p>
            <div className="d-flex align-items-center">
              <select
                className="form-select form-select-sm border-0"
                aria-label=".form-select-sm example"
              >
                <option selected>1</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div> */}
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Lugar de retiro
            </p>
            <p
              className="mb-0"
              style={{ fontSize: '14px', marginRight: '2em' }}
            >
              Guayaquil
            </p>
          </div>
          <div
            className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
            style={{ height: '2.7em' }}
          >
            <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
              Número de Teléfono
            </p>
            <div className="d-flex gap-1 align-items-center">
              +
              <Input
                className="border-top-0 border-end-0 border-start-0 rounded-0 px-1"
                style={{ width: '31px' }}
                placeholder="57"
                type="tel"
                minLength={2}
                maxLength={2}
                onChange={(e) => onPhoneInputChange(e, 'country')}
              />
              <Input
                className="border-top-0 border-end-0 border-start-0 rounded-0"
                style={{ width: '120px' }}
                type="tel"
                minLength={7}
                maxLength={10}
                onChange={(e) => onPhoneInputChange(e, 'number')}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between text-muted">
            <p style={{ fontSize: '12px' }}>Subtotal</p>
            <p style={{ fontSize: '12px' }}>{`$${total}`}</p>
          </div>
          {/* <div className="d-flex justify-content-between text-muted">
            <p style={{ fontSize: '12px' }}>Entrega/Envio</p>
            <p style={{ fontSize: '12px' }}>Gratis</p>
          </div> */}
        </div>
        <hr className="m-0 mb-3" />
        <div className="d-flex justify-content-between mb-2">
          <p className="fw-bolder" style={{ fontSize: '12px' }}>
            Total
          </p>
          <p className="fw-bolder" style={{ fontSize: '12px' }}>
            {`$${total}`}
          </p>
        </div>
        <div className="d-grid mx-auto">
          <button className="btn btn-primary rounded-pill mx-4" type="submit">
            Pagar
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderResume;
