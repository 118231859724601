import React, { useEffect, useState } from 'react'
import { Document, pdfjs, Page } from 'react-pdf';
import LoadSpinner from '../load-spinner/load-spinner';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



export const PdfViewer = (props: any) => {
    const { urlPDF, callbackPDF } = props;

    const [pdfString, setPdfString] = useState("");
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [PDFBlob, setPDFBlob] = useState<Blob | undefined>(undefined);

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };
    useEffect(() => {
        if (PDFBlob === undefined) fetchPDF();
    }, [pdfString, pageNumber]);
    function fetchPDF() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('localToken'));

        var requestOptions: RequestInit = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(urlPDF, requestOptions)
            .then((response) => {
                return response.blob()
            })
            .then((blob) => {
                setPDFBlob(blob);
                loadPDF(blob);
            })
            .catch((error) => console.log("error", error));
    }

    function loadPDF(blobLoad: Blob) {

        if (PDFBlob !== undefined)
            setPdfString(URL.createObjectURL(blobLoad));
    }
    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);

    }
    function onChangePage(event: string) {
        switch (event) {
            case '+':
                if (pageNumber + 1 <= numPages)
                    setPageNumber(pageNumber + 1);
                break;
            case '-':
                if (pageNumber - 1 > 0)
                    setPageNumber(pageNumber - 1);
                break;
        }
    }
    if (PDFBlob) {
        return (
            <div className="row mb-3 my-5">
                <div className='mx-auto'>
                    <Document className='mx-auto' file={{ url: URL.createObjectURL(PDFBlob) || '' }} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                        <div className="pageDimensions"
                            style={{
                                maxHeight: `800px`, // needed for scroll
                                overflowY: 'scroll', // yes vertical scroll
                                overflow: '-moz-scrollbars-vertical', 
                                overflowX: 'hidden', // no horizontal scroll
                                width: 'auto',
                                // just for fun
                                border: '2px solid lightgray', // matches the scroll color
                                borderRadius: '5px',
                                padding: '1rem 0 1rem 0'
                            }}
                        >
                            <Page pageNumber={pageNumber} scale={1} />
                        </div>

                    </Document>
                    {numPages > 1 && (
                        <>
                            <p className='pdf-controls mx-auto'>
                                Página {pageNumber} de {numPages}
                            </p>
                            <div className="pdf-controls mx-auto mb-5">
                                <button className={pageNumber == 1 ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('-')}>Anterior </button>
                                <button className={pageNumber == numPages ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('+')}>Siguiente</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    else {
        return <LoadSpinner />
    }
}
