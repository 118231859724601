import React, { useState } from 'react';
import { updateCartItem } from '../../api/userSession';
import { apiMainURL } from '../../config/environment';

export type OrderProps = {
  img: string;
  name: string;
  volume: string;
  price: string;
  quantity: string;
  calification: number;
  internID: string;
  orderId: string;
  onCancelClick: (productToCancel: string, orderId: string) => void;
};

const dropdownOpts = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
];

const Order = ({
  img,
  name,
  volume,
  price,
  calification,
  quantity: originalQuantity,
  orderId,
  internID,
  onCancelClick,
}: OrderProps) => {
  const [quantity, setQuantity] = useState(+originalQuantity);

  const onCancelButtonClick = () => {
    onCancelClick(internID, orderId);
  };

  const onButtonSelect = async (value: number) => {
    const res = await updateCartItem(value, internID);

    if (res === 'true') setQuantity(value);
  };

  return (
    <div className="row mb-4">
      <div className="col-3">
        <img className="full-image" src={img || ''} alt={'product-' + name} />
      </div>
      <div className="col-6">
        <h5>{name || ''}</h5>
        <p className="card-text">
          {[...Array(calification).keys()].map((id) => {
            return (
              <img
                src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                alt=""
                className="star-calification"
                key={`calification-star-${name}-${id}`}
              />
            );
          })}
        </p>
        <p className="title-vol-product">{volume || ''}</p>
        <div className="dropdown">
          <select
            className="btn-outlined-secondary btn-cant-payment dropdown-toggle rounded-pill fw-bolder sel-q"
            value={quantity}
            onChange={(e) => onButtonSelect(+e.target.value)}
          >
            {dropdownOpts.map(({ label, value }) => (
              <option key={`order-dropdown-opt-${label}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-2 close-section-cart">
        <div className="row">
          <div className="col-8">
            <p className="price-product-cart mb-1">Precio</p>
            <p className="price-cart-order">{price || ''}</p>
          </div>
          <div className="col-4">
            <button
              className="close-element-product"
              onClick={onCancelButtonClick}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
