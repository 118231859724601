import React from 'react';
import { useHistory } from 'react-router-dom';

type featureEventProps = {
  date: Date;
  hours: string[];
  category: string;
  title: string;
  place: string;
  image: string;
  url: string;
};

const localeDateType = 'en-US'; //'es-CO'

const FeatureEvent = ({
  date,
  hours,
  category,
  title,
  place,
  image,
  url,
}: featureEventProps) => {
  const history = useHistory();

  const month = date?.toLocaleString(localeDateType, { month: 'short' });
  const day = date?.toLocaleString(localeDateType, { day: '2-digit' });
  const year = date?.toLocaleString(localeDateType, { year: 'numeric' });
  const hasMultipleHours = hours.length > 1 && hours[0];

  let hoursToShow;

  const onOtherEventButtonClick = () => {
    history.push(url);
  };

  if (hours)
    if (hasMultipleHours) hoursToShow = hours.join(' - ');
    else hoursToShow = hours[0];

  return (
    <div
      className="w-full shadow"
      style={{ position: 'relative', backgroundColor: 'white' }}
    >
      {hours && (
        <div
          className="d-flex"
          style={{
            fontSize: '.85rem',
            width: '55%',
            position: 'absolute',
            left: '0px',
            top: '5%',
          }}
        >
          <div
            className="w-25 py-1"
            style={{ backgroundColor: 'red', paddingLeft: '6.5%' }}
          >
            <p className="m-0 fw-bold text-white fw-bold">{day}</p>
          </div>
          <div
            className="d-flex py-1 w-75 justify-content-between float-start"
            style={{
              backgroundColor: 'white',
              paddingLeft: '.65rem',
              paddingRight: '.65rem',
            }}
          >
            <p className=" m-0 fw-bold" style={{ color: '#1a9986' }}>
              {month}
            </p>
            <p className=" m-0 fw-bold" style={{ color: '#1a9986' }}>
              {year}
            </p>
          </div>
        </div>
      )}
      <img
        style={{ width: '100%', height: '9.5vw' }}
        src={image}
        alt="feature-event"
      />
      <div className="p-3">
        <p
          className="mb-2 fw-bolder"
          style={{ fontSize: '.7rem', color: '#1a9986' }}
        >
          {category}
        </p>
        <p className="fw-bolder">{title}</p>
        {hoursToShow && (
          <p className="mb-1 text-muted" style={{ fontSize: '.7rem' }}>
            <i className="bi bi-clock me-2" style={{ color: '#1a9986' }} />
            {hoursToShow}
          </p>
        )}
        {place && (
          <p className="mb-4 text-muted" style={{ fontSize: '.7rem' }}>
            <i
              className="bi bi-geo-alt-fill me-2"
              style={{ color: '#1a9986' }}
            />
            {place}
          </p>
        )}
        <button
          className="px-4 border bg-white fw-bolder"
          style={{
            paddingTop: '.4rem',
            paddingBottom: '.4rem',
            fontSize: '.8rem',
          }}
          onClick={onOtherEventButtonClick}
        >
          More details
        </button>
      </div>
    </div>
  );
};

export default FeatureEvent;
