import React from 'react'
import { Link } from 'react-router-dom';

export const ProgressStages = (props: any) =>{
    const {text, percent, score}=props;
    return (
        <div className="main-progress-gamification">
            <div className="row align-items-center">
                <div className="col-12 col-sm-4 col-md">
                    <div className="title-progress-gamification">
                       {text||''}
                    </div>
                </div>
                <div className="col-6 col-sm-4 col-md">
                    <div className="progress-percentage">
                        <span className="text">
                            Tu porcentaje
                        </span>
                        <span className="number">
                          {parseFloat(percent || '0').toFixed(0)}
                            <img
                                src={`${process.env.PUBLIC_URL}/resources/tour-gente/icon-porcentage.svg
                                `}
                                className=""
                                alt="..."
                            />
                        </span>
                    </div>
                </div>
                <div className="col-6 col-sm-4 col-md ">
                    <div className="progress-percentage">
                        <span className="text">
                            Tu puntaje
                        </span>
                        <span className="number">
                         {score}
                            <img
                                src={`${process.env.PUBLIC_URL}/resources/tour-gente/icon-score.svg`}
                                className=""
                                alt="..."
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}
