import { useEffect, useState } from "react";
import { getActivitiesApi, getAllBP, setBp } from "../../../../../api/userSession";

export const AutocompleteInput = (props: any) => {
    const { text, bu, trigger, options } = props;
    let Typeahead = require('react-bootstrap-typeahead').Typeahead;
    const [singleSelections, setSingleSelections] = useState<any[]>([]);
    const [disabledSubmmit, setDisabledSubmmit] = useState<boolean>(true);
    function handleChange(selectedOptions: any) {
        setSingleSelections(selectedOptions);
        setDisabledSubmmit(!disabledSubmmit);
    }
    function handleSubmmit(){
        const {name,uid,field_sharp}=singleSelections[0];
        setBp(field_sharp).then((elem: any) => {
           trigger(singleSelections[0])
        }).catch((error: any) => console.log("Error query:  ", error));
    }
    useEffect(() => {
        
    }, [disabledSubmmit]);
    if (options?.length > 0)
        return (
            <div className='my-4 mx-auto w-100'>
               <div className="d-flex justify-content-center">
               <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleChange}
                    options={options}
                    placeholder={text}
                    selected={singleSelections}
                    
                />
               </div>
               <hr />
                <button className={disabledSubmmit?' btn btn-primary d-flex justify-content-center mx-auto w-100 disabled':'btn btn-primary d-flex justify-content-center mx-auto w-100'} onClick={()=>handleSubmmit()}>Guardar</button>
            </div>
        )
    else return <></>

}
