import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetDetailTradeMarkApiQuery,
  useGetPillarsDetailMediaQuery,
} from '../../../../../api/userSession';
import { getDomain } from '../../../../../config/domain';
import { apiMainURL } from '../../../../../config/environment';
import {
  getAllMultimedia,
  getBanner,
  getImage,
  getInfographic,
  getVideo,
  Media,
} from '../../../../../modules/services/getElements.service';
import { TabDetailPillar } from './tab-detail';

export const DetailPillar = (props: any) => {
  let idMark = '';
  let Infografia = '';
  const dataNueva = useGetPillarsDetailMediaQuery(
    window.location.href.split('?')[1],
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  let multimedia: any[] = [];
  let fichas: any[] = [];
  const [tarjetas, setTarjetasData] = useState<any[]>([]);
  // const [banners, setBannersData] = useState<any[]>([]);
  let banners: any[] = [];
  let SmallImages: any[] = [];
  const dataSection = useGetDetailTradeMarkApiQuery(
    window.location.href.split('?')[1],
  );
  const details = getAllReferences(dataNueva?.data);

  function getAllReferences(tar: any) {
    tar?.data?.relationships?.field_banners?.data.map((banner: any) => {
      banners.push({
        altText: '',
        src: getBanner(banner.id, tar?.included) || '',
        caption: '',
        link: '',
      });
    });
    tar?.included?.map((include: any, index: number) => {
      if (include.type == 'node--ficha') {
        let dataMediaTabs: Media[] = [];
        let idImage =
          include?.relationships.field_imagen_miniatura.data.id || '';
        let image = getImage(idImage, tar?.included);
        SmallImages.push({
          src: image,
          title: include?.attributes.title || '',
        });
        include?.relationships?.field_contenido_multimedia?.data.forEach(
          (cont: any) => {
            if (cont.type == 'node--infografia') {
              let info = getInfographic(cont.id, tar?.included);
              dataMediaTabs.push({ type: 'infografia', src: info });
            }
            if (cont.type == 'node--video') {
              let info = getVideo(cont.id, tar?.included);
              dataMediaTabs.push({ type: 'video', src: info });
            }
          },
        );
        fichas.push({
          title: include?.attributes?.title || '',
          BigImage: image,
          smallImage: image || '',
          description: include?.attributes.body?.value || '',
          link: include?.attributes?.path.alias || '',
          mediaTab: dataMediaTabs,
        });
      }
    });
  }

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {localStorage.getItem('backIdPillar') && (
                      <Link
                        to={`/wellness/DetailPilar?${localStorage.getItem(
                          'backIdPillar',
                        )}`}
                      >
                        Pilares
                      </Link>
                    )}
                    {!localStorage.getItem('backIdPillar') && (
                      <Link to={`/wellness`}>Pilares</Link>
                    )}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Detalle
                  </li>
                </ol>
              </nav>

              <div className="tabs-brand">
                <TabDetailPillar
                  content={fichas}
                  idMark={window.location.href.split('?')[1]}
                  infografia={Infografia}
                  miniaturas={SmallImages}
                  banners={banners}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
