import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Modal, ModalBody } from 'reactstrap';
import { ModalLocation } from '../modalLocation';
import OrderResume from '../utitities/order-resume';
import { EmptyShoppingCart } from './empty-shopping-cart';
import { TrackingContainer } from '../utitities/trcking-container';
import classnames from 'classnames';
interface order {
  type: string;
  location: string;
  subtotal: number;
  entrega: string;
  total: number;
}
const orders: order[] = [
  {
    type: 'Transferencia',
    location: 'Guayaquil',
    subtotal: 25.0,
    entrega: 'Gratis',
    total: 25.0,
  },
  {
    type: 'Transferencia',
    location: 'Guayaquil',
    subtotal: 13.0,
    entrega: 'Contraentrega',
    total: 13.0,
  },
];
const breadItems = [
  { label: 'Planeta Beerway', active: false, link: '/landing' },
  { label: 'E-commerce', active: false, link: '/e-commerce/landing' },
  { label: 'Tu Pago', active: true, link: '' },
];
const calification: any[] = ['1', '2', '3', '4', '5'];

const products = [
  {
    img: 'https://i.ibb.co/9p3jYb4/4.jpg',
    name: 'Stella Artois',
    calification: 5,
    volume: 'LTA 320 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  },
  {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Corona',
    calification: 5,
    volume: 'LTA 335 ml x 4',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  },
];

export const CurrentOrder = () => {
  const mediaMatch = window.matchMedia('(min-width: 576px)');

  const [matches, setMatches] = useState(mediaMatch.matches);
  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <Breadcrumb>
            {breadItems.map(({ label, active, link }) => (
              <BreadcrumbItem
                key={`payment-breadcrumb-item-${label}`}
                {...{ active }}
              >
                {!active ? <Link to={link}>{label}</Link> : label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          <div className="title-welcome">Tu pedido actual</div>
          <div className="d-flex justify-content-between justify-content-md-end py-0">
            <ModalLocation></ModalLocation>
            <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 ml-5">
              <div className="col-12">
                <div className="row">
                  <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0"></div>
                  <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                    <div className="link-opt heart-in">
                      <Link to="/e-commerce/shopping-cart">
                        <i className="bi bi-cart3 heart-i"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                    <div className="link-opt">
                      <Link to="/e-commerce/favorites">
                        <i className="bi bi-heart"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {products.length == 0 ? (
              <div className="">
                <EmptyShoppingCart />
              </div>
            ) : (
              <>
                {orders?.map((o: any, io: number) => {
                  return (
                    <>
                      {' '}
                      <hr />
                      <div className="row" key={io}>
                        <div className="row col-12 col-lg-6 col-xl-8 mt-5">
                          <div className="pr-5 mb-5 col-11">
                            <TrackingContainer></TrackingContainer>
                          </div>
                          {products?.map((product: any, i: number) => {
                            return (
                              <div className="">
                                <div className="row mb-4 op-min">
                                  <div className="col-3">
                                    <img
                                      className="full-image"
                                      src={product?.img || ''}
                                      alt={'product-' + i}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <h5>{product?.name || ''}</h5>
                                    <p className="card-text">
                                      {calification.map(
                                        (cal: any, indexC: number) => {
                                          return (
                                            <img
                                              src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                                              alt=""
                                              className="star-calification"
                                              key={indexC}
                                            />
                                          );
                                        },
                                      )}
                                    </p>
                                    <p className="title-vol-product">
                                      {product?.volume || ''}
                                    </p>
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-outlined-secondary btn-cant-payment dropdown-toggle rounded-pill pt-2 fw-bolder"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        Cantidad 1
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            1
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            2
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            3
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-2 close-section-cart">
                                    <div className="row">
                                      <div className="col-8">
                                        <p className="price-product-cart">
                                          Precio
                                        </p>
                                        <p className="price-cart-order">
                                          {product?.price || ''}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <button className="close-element-product">
                                          <i className="bi bi-x-lg"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4 main-tracking mt-5">
                          <OrderResume type={'3'} summary={{}} />
                          <div className="d-grid mx-auto mt-5">
                            <p
                              className="text-muted mx-4"
                              style={{ fontSize: '10px' }}
                            >
                              *Recuerda mostrar el comprobante de la
                              transferencia o pago al momento de reclamar el
                              pedido
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
