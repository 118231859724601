import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import { deleteSession } from '../../../../api/userSession';
import { CalificationResults } from './calification-result';
import LoadSpinner from '../../../load-spinner/load-spinner';

export const ContestResults = (props: any) => {
  // console.log("Propiedades en resultados:¨ ", props);
  const id = window.location.href.split('?')[1];
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [resultsData, setResultsData] = useState<string>('');
  let end: number = new Date().valueOf();
  useEffect(() => {
    let inicio: string = localStorage.getItem('start') || '0';
    let diferencia = end - parseInt(inicio);
    let bodyQuery: any = getAnswers(props.result, diferencia);

    // sendDataResults(bodyQuery);
  }, []);

  function getAnswers(array: any[], time: number) {
    var answers = {};

    for (let elem of array) {
      let idq: string = elem.idQ;
      let ida: string = elem.idA;
      Object.defineProperty(answers, idq, {
        value: ida,
        writable: false,
        enumerable: true,
      });
    }
    if (answers) {
    }
    sendDataResults(answers, time);
  }
  function parseData(data: any) {
    if (!data) return {};
    if (typeof data === 'object') return data;
    if (typeof data === 'string') return JSON.parse(data);
    return {};
  }

  function sendDataResults(answers: object, time: number) {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      answers,
      time,
    });
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/save-quiz/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setResultsData(result);
      })
      .catch((error) => console.log('error', error));
  }
  if (resultsData != '')
    return (
      <CalificationResults
        content={JSON.parse(resultsData)}
      ></CalificationResults>
    );
  else return <LoadSpinner></LoadSpinner>;
};
export default ContestResults;
