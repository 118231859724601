import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CarouselView from '../../carousel/carousel';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import { getDomain } from '../../../config/domain';
import { Scrollbars } from 'rc-scrollbars';

export const ContestFinish = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  let any;
  const toggle = (any = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  });

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/competitions">Concursos</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    ¡Ecuatorianízate!
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">¡Ecuatorianízate!</div>
              <div className="tabs-section details-competitions">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Concurso
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === '2',
                      })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      Ranking
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <div className="banner">
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/banner-hub.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12">
                            <div className="text-details-awards">
                              Lorem ipsum dolor sit amet, consetetur sadipscing
                              elitr, sed diam nonumy eirmod tempor invidunt ut
                              labore et dolore magna aliquyam erat, sed diam
                              voluptua. At vero eos et accusam et justo duo
                              dolores et ea rebum. Stet clita kasd
                              <span>Fecha de cierre: 3 de Septiembre 2021</span>
                              <div className="mt-4">
                                <Link
                                  to="/competitions/ContestStart"
                                  className="btn btn-secondary"
                                >
                                  Ya participaste en este concurso
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="title-page">PREMIOS</div>
                            <div>
                              "Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Duis aute
                              irure dolor in reprehenderit in voluptate velit
                              esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident,
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum."
                            </div>
                            <div className="overflow-hidden mt-5">
                              <div className="row row-cols-md-4 mt-sm-4 items-awards">
                                <div className="col">
                                  <div className="img-awards">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/awards1.jpg`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div>Premio 1</div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="img-awards">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/awards1.jpg`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div>Premio 2</div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="img-awards">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/awards1.jpg`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div>Premio 3</div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="img-awards">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/awards1.jpg`}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div>Premio 4</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="title-page">BASES DEL CONCURSO</div>
                            <div>
                              "Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Duis aute
                              irure dolor in reprehenderit in voluptate velit
                              esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident,
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum."
                            </div>
                            <div className="banner mt-5">
                              <img
                                src={`${process.env.PUBLIC_URL}/resources/banner_awards1.jpg`}
                                alt=""
                                className="img-fluid"
                              />
                            </div>

                            <div className="title-page">CATEGORÍAS</div>
                            <div>
                              "Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Duis aute
                              irure dolor in reprehenderit in voluptate velit
                              esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident,
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum."
                            </div>
                            <div className="title-page">
                              TERMINOS Y CONDICIONES
                            </div>
                            <div>
                              "Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Duis aute
                              irure dolor in reprehenderit in voluptate velit
                              esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident,
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum." “Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat. Duis aute irure dolor in reprehenderit
                              in voluptate velit esse cillum dolore eu fugiat
                              nulla pariatur. Excepteur sint occaecat cupidatat
                              non proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.”
                            </div>
                            <div className="text-center mt-4">
                              <Link
                                to="/competitions/ContestStart"
                                className="btn btn-secondary"
                              >
                                Quiero Paticipar
                              </Link>
                              <div className="mt-4">
                                <Link
                                  to="/competitions"
                                  className="txt-secondary"
                                >
                                  Ver Más Concursos
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md="6">
                        <div className="main-podium">
                          <div className="header-podium">
                            <div className="text-podium">
                              <span>Andrea</span> Tu posición en el ranking es:
                            </div>
                            <div className="main-image">
                              <div className="image-podium">
                                <div className="icons">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="number-podium">
                                <span>1</span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 main-positions-podium">
                            <Row className="align-items-end">
                              <Col md="4" className="text-center mb-4">
                                <div className="position-relative">
                                  <div className="main-image">
                                    <div className="icons two">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="number-podium">
                                      <span>2</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="positions-podium">
                                  <div className="star two">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                      width="60"
                                    />
                                  </div>
                                  Oscar
                                  <span>70pt</span>
                                </div>
                              </Col>
                              <Col md="4" className="text-center mb-4">
                                <div className="position-relative">
                                  <div className="main-image">
                                    <div className="icons one">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="number-podium">
                                      <span>1</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="positions-podium">
                                  <div className="star one">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/star-one.svg`}
                                      width="80"
                                    />
                                  </div>
                                  Oscar
                                  <span>100pt</span>
                                </div>
                              </Col>
                              <Col md="4" className="text-center mb-4">
                                <div className="position-relative">
                                  <div className="main-image">
                                    <div className="icons three">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="number-podium">
                                      <span>3</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="positions-podium">
                                  <div className="star three">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                      width="60"
                                    />
                                  </div>
                                  Oscar
                                  <span>60pt</span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="positions-table">
                          <div className="text-center title">
                            Tabla de posiciones
                          </div>
                          <div className="positions-first">
                            <div className="box-positions first-place">
                              <div className="number">1</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star-one.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                          </div>
                          <Scrollbars style={{ height: 500 }}>
                            <div className="box-positions">
                              <div className="number">2</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">3</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">4</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">5</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">6</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">7</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">8</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                            <div className="box-positions">
                              <div className="number">9</div>
                              <div className="image">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="ps-3">Omar</div>
                              <div className="points">70pt</div>
                              <div className="star">
                                <img
                                  src={`${process.env.PUBLIC_URL}/resources/star.svg`}
                                  width="60"
                                />
                              </div>
                            </div>
                          </Scrollbars>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContestFinish;
