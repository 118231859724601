import { useTranslation } from 'react-i18next';
import {
  deleteSession,
  useGetDataDiversitySearchQuery,
  useGetDataMarkSearchQuery,
  useGetDataNewsSearchQuery,
  useGetDataPillarSearchQuery,
  useGetDataProgramsEventsSearchQuery,
  useGetUserQuery,
} from '../../api/userSession';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { domain_country, getDomain } from '../../config/domain';
import { linksSection } from '../../modules/services/getElements.service';
import { apiMainURL } from '../../config/environment';
import { InsertChartOutlined } from '@material-ui/icons';
import FichasResults from './fichas-results';

const SearchResults = (props: any) => {
  // console.log("Props en Search:   ", props.content);
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [fichasData, setFichasData] = useState<any[]>([]);
  const elemsDiversity = getElementsSearch(
    useGetDataDiversitySearchQuery({
      country: domainData.country,
      content: props?.content,
      page: '0',
    })?.data,
  );
  const elemsMarks = getElementsMarksSearch(
    useGetDataMarkSearchQuery({
      country: domainData.country,
      content: props?.content,
      page: '0',
    })?.data,
  );

  const elemsNews = getElementsSearch(
    useGetDataNewsSearchQuery({
      country: domainData.country,
      content: props?.content,
      page: '0',
    })?.data,
  );

  const elemsPillar = getElementsSearch(
    useGetDataPillarSearchQuery({
      country: domainData.country,
      content: props.content,
      page: '0',
    })?.data,
  );

  const elemsProgramsEvents = getElementsSearch(
    useGetDataProgramsEventsSearchQuery({
      country: domainData.country,
      content: props?.content,
      page: '0',
    })?.data,
  );

  async function getTarjeta(idFicha: string) {
    var myHeaders: Headers = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const resp = await fetch(
      `${apiMainURL}/jsonapi/node/tarjeta?filter[field_ficha.id][value]=${idFicha}`,
      requestOptions,
    );
    const idF = await resp.json();
    return idF;
  }
  function insertarId(id: string, title: string, body: string) {
    let band = false;
    fichasData.forEach((f) => {
      if (f.id == id) {
        band = true;
      }
    });
    if (!band) {
      setFichasData([...fichasData, { id: id, title: title, body: body }]);
    }
  }
  function getElementsSearch(data: any): any[] {
    let elems: any[] = [];
    data?.forEach((element: any, index: number) => {
      let type = element?.type[0]?.target_id || '';
      switch (type) {
        case 'ficha':
          getTarjeta(element?.uuid[0]?.value || '').then((r) => {
            insertarId(
              r?.data[0]?.id || '',
              element?.title[0]?.value || '',
              (element?.body && element?.body.length) > 0
                ? element.body[0].value
                : '',
            );
          });
          break;
        default:
          elems.push({
            id: element?.uuid[0]?.value || '',
            body:
              (element?.body && element?.body.length) > 0
                ? element.body[0].value
                : '',
            title: element?.title[0]?.value || '',
            type: element?.type[0]?.target_id || '',
          });
          break;
      }
    });
    return elems;
  }

  function getElementsMarksSearch(data: any): any[] {
    let elems: any[] = [];
    data?.forEach((element: any, index: number) => {
      elems.push({
        id: element?.field_marca[0]?.target_uuid || '',
        body:
          element?.body && element?.body.length ? element.body[0].value : '',
        title: element?.title[0]?.value || '',
        type: element?.type[0]?.target_id || '',
      });
    });
    // console.log("Elementos retornados:  ", elems);
    return elems;
  }
  useEffect(() => {}, []);

  return (
    <div className="mt-5">
      <p className="search-section-subtitle">Resultados de tu búsqueda</p>
      <div className="mt-5">
        {elemsDiversity.length > 0 &&
          elemsDiversity?.map((elem: any, index: number) => {
            return (
              <div className="mt-5" key={index}>
                <Link to={`${linksSection[elem?.type]}?${elem?.id}`}>
                  <h1 className="search-title-result mb-4"> {elem.title}</h1>
                </Link>
                <p className="search-desc-result">
                  {domainData.name} / Buscador
                </p>
                <p className="search-desc-result mb-4">
                  {elem?.body?.substr(0, 300) || ''}
                </p>
                <hr />
              </div>
            );
          })}
        {elemsMarks.length > 0 &&
          elemsMarks?.map((elem: any, index: number) => {
            return (
              <div className="mt-5" key={index}>
                <Link to={`${linksSection[elem?.type]}?${elem?.id}`}>
                  <h1 className="search-title-result mb-4"> {elem.title}</h1>
                </Link>
                <p className="search-desc-result">
                  {domainData.name} / Buscador
                </p>
                <p className="search-desc-result mb-4">
                  {elem?.body?.substr(0, 300) || ''}
                </p>
                <hr />
              </div>
            );
          })}
        {elemsNews.length > 0 &&
          elemsNews?.map((elem: any, index: number) => {
            return (
              <div className="mt-5" key={index}>
                <Link to={`${linksSection[elem?.type]}?${elem?.id}`}>
                  <h1 className="search-title-result mb-4"> {elem.title}</h1>
                </Link>
                <p className="search-desc-result">
                  {domainData.name} / Buscador
                </p>
                <p className="search-desc-result mb-4">
                  {elem?.body?.substr(0, 300) || ''}
                </p>
                <hr />
              </div>
            );
          })}
        {elemsProgramsEvents.length > 0 &&
          elemsProgramsEvents?.map((elem: any, index: number) => {
            return (
              <div className="mt-5" key={index}>
                <Link to={`${linksSection[elem?.type]}?${elem?.id}`}>
                  <h1 className="search-title-result mb-4"> {elem.title}</h1>
                </Link>
                <p className="search-desc-result">
                  {domainData.name} / Buscador
                </p>
                <p className="search-desc-result mb-4">
                  {elem?.body?.substr(0, 300) || ''}
                </p>
                <hr />
              </div>
            );
          })}
        {elemsPillar.length > 0 &&
          elemsPillar?.map((elem: any, index: number) => {
            return (
              <div className="mt-5" key={index}>
                <Link to={`${linksSection[elem?.type]}?${elem?.id}`}>
                  <h1 className="search-title-result mb-4"> {elem.title}</h1>
                </Link>
                <p className="search-desc-result">
                  {domainData.name} / Buscador
                </p>
                <p className="search-desc-result mb-4">
                  {elem?.body?.substr(0, 300) || ''}
                </p>
                <hr />
              </div>
            );
          })}
        {fichasData.length > 0 && (
          <FichasResults content={fichasData}></FichasResults>
        )}
      </div>
    </div>
  );
};
export default SearchResults;
