import Order from './Order';

type OrdersProps = {
  hasDelete?: boolean;
  onCancelClick: (productToCancel: string, orderId: string) => void;
  onChangeSelect: () => void;
  products: {
    img: string;
    name: string;
    volume: string;
    price: string;
    quantity: string;
    internID: string;
    calification: number;
    orderId: string;
  }[];
};

const Orders = ({
  hasDelete = true,
  products,
  onCancelClick,
  onChangeSelect,
}: OrdersProps): JSX.Element => {
  return (
    <>
      {products?.map((product) => (
        <Order
          key={`order-element-${product.name}-${product.quantity}`}
          {...{ ...{ onCancelClick, onChangeSelect }, ...product }}
        />
      ))}
    </>
  );
};

export default Orders;
