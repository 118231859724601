import { Link } from 'react-router-dom';

export const SolveLinks = (props: any) => {
  const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(props.url);
  if (extern) {
    return (
      <div>
        <a target="_blank" href={props.url || ''}>
          <img src={props.img} className="img-fluid" alt="wellness-section" />
          <div className="card-content">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text">{props.description}</p>
          </div>
        </a>
      </div>
    );
  } else {
    return (
      <div>
        <Link to={props.url + '?' + (props.id || '')}>
          <img src={props.img} className="img-fluid" alt="wellness-section" />
          <div className="card-content">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text">{props.description}</p>
          </div>
        </Link>
      </div>
    );
  }
};

export const ButtonLink = (props: any) => {
  const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(props.url);
  if (extern) {
    return (
      <div>
        <button className="btn btn-secondary">
          <a target="_blank" href={props.url || ''}>
            {props.name}
          </a>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <button className="btn btn-secondary subscription">
          <Link to={props.url}>{props.name}</Link>
        </button>
      </div>
    );
  }
};
