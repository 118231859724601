import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDomain } from '../config/domain';
import { apiMainURL, gamificationURL } from './../config/environment';

const AUTHORIZATION = 'authorization';
const AUTH_TOKEN = `Bearer ${localStorage.getItem('localToken')?.toString() || ''
  }`;
const APP_JSON = 'application/json';
const POST = 'POST';
const GET = 'GET';

const getHeader = (auth: string, token: string, contentType: string) => {
  const header = new Headers();

  header.append(auth, token);
  header.append('Content-Type', contentType);

  return header;
};
const fetchRequestBody = async (endpoint: string, requestOpts: RequestInit) => {
  const response = await fetch(endpoint, requestOpts);

  if (response.status >= 400) return false;

  const body = await response
    .text()
    .catch((error) => console.log('error', error));

  return body;
};

export const beerAmbassadorApi = createApi({
  reducerPath: 'beerAmbassadorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiMainURL,
    prepareHeaders: (headers, { getState }) => {
      if (localStorage.getItem('localToken')) {
        refreshLocalToken(localStorage.getItem('localToken')?.toString() || '');
        headers.set(
          'authorization',
          `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
        );
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<any, string>({
      query: (sharpId) => ({
        url: `api/authenticated-user`,
        body: {
          sharp: sharpId,
        },
      }),
    }),
    getBannerSectionApi: builder.query<any, any>({
      query: (idSection) => ({
        url: `/jsonapi/node/content_banner`,
        params: {
          'filter[taxonomy_term--tags][condition][path]':
            'field_section.field_path',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': `${idSection}`,
          'filter[status][value]': '1',
          include:
            'field_image,field_section,field_video,field_video.field_media_video_file',
        },
      }),
    }),
    getBannerByIdApi: builder.query<any, any>({
      query: (idSection) => ({
        url: `/jsonapi/node/content_banner`,
        params: {
          'filter[taxonomy_term--tags][condition][path]': 'field_section.id',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': `${idSection}`,
          'filter[status][value]': '1',
          include: 'field_image,field_section',
        },
      }),
    }),
    getContentSectionApi: builder.query<any, string>({
      query: (idSection) => ({
        url: `/jsonapi/node/content`,
        params: {
          'filter[taxonomy_term--tags][condition][path]':
            'field_section.field_path',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': `${idSection}`,
          'filter[status][value]': '1',
          include: 'field_image,field_section',
        },
      }),
    }),
    getContentMediaSectionApi: builder.query<any, string>({
      query: (idSection) => ({
        url: `/jsonapi/taxonomy_term/sections`,
        params: {
          'filter[field_path][value]': `${idSection}`,
          'filter[status][value]': '1',
          include:
            'field_contenido_multimedia,field_contenido_multimedia.field_imagen_galeria,field_contenido_multimedia.field_image',
        },
      }),
    }),
    getContentFocusApi: builder.query<any, string>({
      query: (idSection) => ({
        url: `/jsonapi/node/content`,
        params: {
          'filter[taxonomy_term--tags][condition][path]': 'field_section.id',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': `${idSection}`,
          'filter[status][value]': '1',
          include: 'field_image,field_section',
        },
      }),
    }),
    getListTradeMarksApi: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/taxonomy_term/marcas`,
        params: {
          include: 'field_banner_imagen',
        },
      }),
    }),
    getDetailTradeMarkApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/referencia_marca`,
        params: {
          'filter[taxonomy_term--tags][condition][path]': 'field_marca.id',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': id,
          'filter[status][value]': '1',
          include:
            'field_contenido_multimedia,field_image,field_imagen_miniatura,field_marca,field_referencia_atributos,field_referencia_social,field_contenido_multimedia.field_imagen_galeria,field_contenido_multimedia.field_image, field_contenido_multimedia.field_video,field_contenido_multimedia.field_video.field_media_video_file',
        },
      }),
    }),
    getListWellnessPillars: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/node/bienestar_pilares`,
        params: {
          include: 'field_imagen_destacada',
        },
      }),
    }),
    getListDiversityFocus: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/taxonomy_term/diversidad_focos`,
        params: {
          sort: 'weight',
          include: 'field_banner_imagen',
        },
      }),
    }),
    getDetailWellnessPillarApi: builder.query<any, string>({
      query: (idPillar) => ({
        url: `/jsonapi/node/bienestar_pilares`,
        params: {
          'filter[id]': idPillar,
          include:
            'field_image,field_contenido_multimedia,field_imagen_destacada,field_contenido_multimedia.field_video,field_contenido_multimedia.field_video.field_media_video_file, field_contenido_multimedia.field_image,field_banners.field_image, field_banners.field_video,  field_banners.field_video.field_media_video_file',
        },
      }),
    }),
    getDetailDiversityFocusApi: builder.query<any, string>({
      query: (idFocus) => ({
        url: `/jsonapi/node/diversidad_articulos`,
        params: {
          'filter[taxonomy_term--tags][condition][path]': 'field_section.id',
          'filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': `${idFocus}`,
          'filter[status][value]': '1',
          include: 'field_imagen_destacada',
        },
      }),
    }),
    getDiversityFocusTitleApi: builder.query<any, string>({
      query: (idFocus) => ({
        url: `/jsonapi/taxonomy_term/diversidad_focos/${idFocus}`,
      }),
    }),
    getHomeLinksListApi: builder.query<any, string>({
      query: (idFocus) => ({
        url: `/jsonapi/taxonomy_term/section`,
      }),
    }),
    getDetailBlogDiversityApi: builder.query<any, string>({
      query: (idBlog) => ({
        url: `/jsonapi/node/diversidad_articulos/${idBlog}`,
        params: {
          include:
            'field_image,field_contenido_multimedia.field_video,field_contenido_multimedia.field_video.field_media_video_file, field_contenido_multimedia.field_image',
        },
      }),
    }),
    getListLinksWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/content`,
        params: {
          'filter[taxonomy_term--tags][condition][path]':
            'field_section.field_path',
          ' filter[taxonomy_term--tags][condition][operator]': 'IN',
          'filter[taxonomy_term--tags][condition][value][]': id,
          'filter[status][value]': '1',
          include: 'field_image,field_section',
        },
      }),
    }),
    getListProgramsWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_programas`,
        params: {
          'sharp': `${id}`,
          ' status': 'active',
        },
      }),
    }),
    getListOtherProgramsWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_programas`,
        params: {
          'filter[status][value]': '1',
          include: 'field_imagen_destacada',
          ' filter[node--bienestar_programas][condition][path]': 'id',
          ' filter[node--bienestar_programas][condition][operator]': 'NOT IN',
          ' filter[node--bienestar_programas][condition][value][]': `${id}`,
        },
      }),
    }),
    getListOtherEventsWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_eventos`,
        params: {
          'filter[status][value]': '1',
          ' include': 'field_imagen_destacada,field_categoria_evento',
          ' filter[node--bienestar_eventos][condition][path]': 'id',
          ' filter[node--bienestar_eventos][condition][operator]': 'NOT IN',
          ' filter[node--bienestar_eventos][condition][value][]': `${id}`,
        },
      }),
    }),
    getListEventsWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_eventos`,
        params: {
          'filter[status][value]': '1',
          'include': 'field_imagen_destacada,field_categoria_evento',
          'sort[fecha][path]': 'field_fecha',
          'sort[fecha][direction]': 'desc',
        },
      }),
    }),
    getListEventsWellnessPreviewApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_eventos`,
        params: {
          'filter[status][value]': '1',
          'include': 'field_imagen_destacada,field_categoria_evento,field_imagen_preview',
          'sort[fecha][path]': 'field_fecha',
          'sort[fecha][direction]': 'desc',
          'page[limit]': '4'
        },
      }),
    }),
    getEventsList: builder.query<any, string>({
      query: (offset: string) => ({
        url: `/jsonapi/node/bienestar_eventos`,
        params: {
          'filter[status][value]': '1',
          'include': 'field_imagen_destacada,field_categoria_evento,field_imagen_preview',
          'sort[fecha][path]': 'field_fecha',
          'sort[fecha][direction]': 'desc',
          'page[limit]': '4',
          'page[offset]': offset,
        },
      }),
    }),
    getDetailEventWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_eventos/${id}`,
        params: {
          'filter[status][value]': '1',
          ' include':
            'field_imagen_destacada,field_image,field_categoria_evento',
        },
      }),
    }),
    getDetailProgramWellnessApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/bienestar_programas/${id}`,
        params: {
          include:
            'field_image,field_contenido_multimedia,field_imagen_destacada,field_contenido_multimedia.field_video,field_contenido_multimedia.field_video.field_media_video_file',
        },
      }),
    }),
    getListNewsFeedApi: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/node/news_feed`,
        params: {
          /* include: 'field_banner_imagen', */
        },
      }),
    }),
    getListMostRelevantNewsFeedApi: builder.query<any, string>({
      query: () => ({
        url: `/api/counter/statistics`,
        params: {
          /* include: 'field_banner_imagen', */
        },
      }),
    }),
    getCountViewsNewsFeedApi: builder.query<any, string>({
      query: (id) => ({
        url: `/api/${id}/check-views?_format=json`,
        params: {
          /* include: 'field_banner_imagen', */
        },
      }),
    }),

    getDetailNewsFeedApi: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/news_feed/${id}`,
        params: {
          include:
            'field_image, field_imagen_destacada, field_contenido_multimedia, field_contenido_multimedia.field_video,field_contenido_multimedia.field_video.field_media_video_file, field_contenido_multimedia.field_image',
        },
      }),
    }),
    getPillarsDetailMedia: builder.query<any, string>({
      query: (idTarjeta) => ({
        url: `/jsonapi/node/tarjeta/${idTarjeta}?`,
        params: {
          include:
            'field_imagen_destacada, field_banners, field_banners.field_image, field_ficha, field_ficha.field_imagen_miniatura,  field_ficha.field_contenido_multimedia, field_ficha.field_contenido_multimedia.field_imagen_galeria, field_ficha.field_contenido_multimedia.field_image, field_ficha.field_contenido_multimedia.field_video, field_ficha.field_contenido_multimedia.field_video.field_media_video_file',
        },
      }),
    }),
    getAllCards: builder.query<any, string>({
      query: (idTarjeta) => ({
        url: `/jsonapi/node/tarjeta/${idTarjeta}`,
        params: {
          include:
            'field_imagen_destacada, field_banners, field_banners.field_image',
        },
      }),
    }),
    getAllNews: builder.query<any, string>({
      query: (idTarjeta) => ({
        url: `jsonapi/node/news_feed`,
        params: {
          include: 'field_imagen_destacada',
        },
      }),
    }),
    getCategoryNews: builder.query<any, string>({
      query: (category) => ({
        url: `/jsonapi/node/news_feed?filter[field_categoria][value]=${category}`,
        params: {
          include: 'field_imagen_destacada',
        },
      }),
    }),
    getRelatedNews: builder.query<any, string>({
      query: (category) => ({
        url: `/api/related/${category}`,
        params: {
          include: 'field_imagen_destacada',
        },
      }),
    }),

    //Competitions
    getQuestions: builder.query<any, string>({
      query: (id) => ({
        url: `/api/get-contest/${id}`,
        params: {},
      }),
    }),
    getResultCompetition: builder.query<any, string>({
      query: (id) => ({
        url: `/api/quiz-result/${id}`,
        params: {},
      }),
    }),
    getListAllCompetitions: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/node/quiz`,
        params: {
          include:
            'field_banner , field_banner.field_media_image,field_sections,field_sections.field_banner, field_sections.field_banner.field_media_image,field_prizes,field_prizes.field_prize_list,field_prizes.field_prize_list.field_imagen,field_terms_conditions,field_quiz_preview, field_quiz_preview.field_image, field_quiz_result',
        },
      }),
    }),
    getRankingCompetition: builder.query<any, string>({
      query: (id) => ({
        url: `/api/quiz-ranking/${id}`,
        params: {},
      }),
    }),
    getDefaultContent: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/pagina_generica?filter[field_url][value]=/contenido?${id}`,
        params: {
          include:
            'field_arguments, field_arguments.field_contenido, field_arguments.field_contenido.field_list, field_arguments.field_contenido.field_list.field_image, field_arguments.field_contenido.field_list.field_video, field_arguments.field_contenido.field_list.field_video.field_media_video_file, field_section, field_section.parent, field_arguments.field_contenido.field_infografia, field_arguments.field_contenido.field_infografia.field_image, field_arguments.field_contenido.field_pdf.field_pdf',
        },
      }),
    }),

    getAppsHubApi: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi/node/hub`,
        params: {
          include: 'field_imagen_destacada',
        },
      }),
    }),
    getInfoCompetition: builder.query<any, string>({
      query: (id) => ({
        url: `/api/counter/${id}/views`,
        params: {},
      }),
    }),
    getUserIdApi: builder.query<any, string>({
      query: () => ({
        url: `/jsonapi`,
        params: {},
      }),
    }),
    //Search EndPoints
    getDataDiversitySearch: builder.query<any, any>({
      query: (data: any) => ({
        url: `/api/search/${data.country}/diversidad/${data.content}?_format=json&page=${data.page}`,
        params: {},
      }),
    }),
    getDataMarkSearch: builder.query<any, any>({
      query: (data: any) => ({
        url: `/api/search/${data.country}/marcas/${data.content}?_format=json&page=${data.page}`,
        params: {},
      }),
    }),
    getDataNewsSearch: builder.query<any, any>({
      query: (data: any) => ({
        url: `/api/search/${data.country}/newsfeed/${data.content}?_format=json&page=${data.page}`,
        params: {},
      }),
    }),
    getDataPillarSearch: builder.query<any, any>({
      query: (data: any) => ({
        url: `/api/search/${data.country}/pilares/${data.content}?_format=json&page=${data.page}`,
        params: {},
      }),
    }),
    getDataProgramsEventsSearch: builder.query<any, any>({
      query: (data: any) => ({
        url: `/api/search/${data.country}/programas_eventos/${data.content}?_format=json&page=${data.page}`,
        params: {},
      }),
    }),

    getTarjetabyFicha: builder.query<any, any>({
      query: (id) => ({
        url: `/jsonapi/node/tarjeta?filter[field_ficha.id][value]=${id}`,
        params: {},
      }),
    }),
    //E-COMMERCE EndPoints
    getAllProducts: builder.query<any, any>({
      query: (id) => ({
        url: `/jsonapi/node/products`,
        params: {
          include:
            'field_gallery, field_gallery.field_media_image, field_gallery.field_media_video_file, field_tags, field_product_category',
        },
      }),
    }),
    getShoppingCart: builder.query<any, any>({
      query: (id) => ({
        url: `/api/ecommerce/get-current-order`,
        params: {
          include:
            'field_gallery, field_gallery.field_media_image, field_gallery.field_media_video_file, field_tags, field_product_category',
        },
      }),
    }),
    //TOUR DE GENTE

  }),
});

// Export hooks for usage in functional components
export const {
  useGetUserQuery,
  useGetBannerSectionApiQuery,
  useGetContentSectionApiQuery,
  useGetListTradeMarksApiQuery,
  useGetDetailTradeMarkApiQuery,
  useGetListWellnessPillarsQuery,
  useGetDetailWellnessPillarApiQuery,
  useGetListDiversityFocusQuery,
  useGetDetailDiversityFocusApiQuery,
  useGetDiversityFocusTitleApiQuery,
  useGetDetailBlogDiversityApiQuery,
  useGetListLinksWellnessApiQuery,
  useGetListProgramsWellnessApiQuery,
  useGetListEventsWellnessApiQuery,
  useGetDetailEventWellnessApiQuery,
  useGetDetailProgramWellnessApiQuery,
  useGetListOtherProgramsWellnessApiQuery,
  useGetListOtherEventsWellnessApiQuery,
  useGetAppsHubApiQuery,
  useGetHomeLinksListApiQuery,
  useGetBannerByIdApiQuery,
  useGetContentFocusApiQuery,
  useGetContentMediaSectionApiQuery,
  useGetListNewsFeedApiQuery,
  useGetListMostRelevantNewsFeedApiQuery,
  useGetDetailNewsFeedApiQuery,
  useGetCountViewsNewsFeedApiQuery,
  useGetUserIdApiQuery,
  useGetQuestionsQuery,
  useGetPillarsDetailMediaQuery,
  useGetAllCardsQuery,
  useGetAllNewsQuery,
  useGetCategoryNewsQuery,
  useGetRelatedNewsQuery,
  useGetListAllCompetitionsQuery,
  useGetDefaultContentQuery,
  useGetResultCompetitionQuery,
  useGetRankingCompetitionQuery,
  useGetDataDiversitySearchQuery,
  useGetDataMarkSearchQuery,
  useGetDataNewsSearchQuery,
  useGetDataPillarSearchQuery,
  useGetDataProgramsEventsSearchQuery,
  useGetTarjetabyFichaQuery,
  useGetAllProductsQuery,
  useGetShoppingCartQuery,
  useGetListEventsWellnessPreviewApiQuery,
  useGetEventsListQuery
} = beerAmbassadorApi;

export async function getUserData(url = '', id = '') {
  // Opciones por defecto estan marcadas con un *

  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  var formdata = new FormData();
  formdata.append('sharp', id);

  const response = await fetch(url, {
    method: 'POST',
    headers: myHeaders,
  });
  if (response?.status !== 200) {
    deleteSession(window.location.href);
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getAPIToken(
  accessTokenSSO: string,
  expiresInSSO: string,
): Promise<string> {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  var raw = JSON.stringify({
    access_token_sso: `${localStorage.getItem('accessToken')?.toString() || ''
      }`,
  });
  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  await fetch(`${apiMainURL}/api/v1/auth/token?_format=json`, requestOptions)
    .then((response) => response.text())
    .then(async (result) => {
      if (JSON.parse(result).token) {
        let tokenLocal = await JSON.parse(result).token;
        localStorage.setItem('localToken', tokenLocal);
        if (window.localStorage.getItem('lastUrl')) {
          window.location.href = `${window.localStorage.getItem('lastUrl')}`;
          window.localStorage.removeItem('lastUrl');
        } else {
          window.location.href = `${getDomain(window.location.hostname + '').appURL
            }/landing`;
        }
      } else {
        deleteSession(window.location.href);
      }

      /* setTimeout(refreshLocalToken(tokenLocal), 3500000); */
    })
    .catch((error) => {
      console.log('error', error);
      deleteSession(window.location.href);
    });
  return '';
}
export async function refreshLocalToken(t: string): Promise<string> {
  /*  console.log("Autorization en refresh:  ",`Bearer ${localStorage.getItem('localToken')?.toString()||''}`);
   */ var myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };
  if (!localStorage.getItem('localToken')) {
    await fetch(`${apiMainURL}/jwt/token?_format=json`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).token) {
          localStorage.setItem(
            'localToken',
            JSON.parse(result).token.toString() || '',
          );
        } else {
          deleteSession(window.location.href);
        }
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }
  return '';
}
export function deleteSession(lastUrl?: string) {
  window.localStorage.clear();
  window.sessionStorage.clear();
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    })})
  if (lastUrl && !lastUrl.includes('callback')) {
    localStorage.setItem('lastUrl', lastUrl);
  }
  window.location.href = '/';
}

export const selectCartItems = async () => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const response = await fetchRequestBody(
    `${apiMainURL}/api/ecommerce/get-current-order?include=field_gallery&include=field_gallery.field_media_image&include=field_gallery.field_media_video_file&include=field_tags&include=field_product_category`,
    requestOptions,
  );

  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
};

export const updateCartItem = async (
  quantity: string | number,
  productInternalId: string,
) => {
  const myHeaders = new Headers();
  const raw = JSON.stringify({
    quantity: +quantity,
  });

  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiMainURL}/api/ecommerce/update-item/${productInternalId}`,
    requestOptions,
  );
  const body = await response
    .text()
    .catch((error) => console.log('error', error));

  return body;
};

export const deleteCartItem = async (orderId: string, productId: string) => {
  const myHeaders = new Headers();
  const raw = JSON.stringify({
    item_id: orderId,
  });

  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiMainURL}/api/ecommerce/delete-item/${productId}`,
    requestOptions,
  );
  const body = await response
    .text()
    .catch((error) => console.log('error', error));

  return body;
};

export const getNumberOfAssistants = async (eventId: number) => {
  const myHeaders = new Headers();

  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiMainURL}/api/event_validate/${eventId}`,
    requestOptions,
  );
  const body = await response
    .text()
    .catch((error) => console.log('error', error));

  return body;
};

export const postNewAssistantInEvent = async (eventId: number) => {
  const myHeaders = new Headers();

  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiMainURL}/api/event_add_user/${eventId}`,
    requestOptions,
  );
  const body = await response
    .text()
    .catch((error) => console.log('error', error));

  return body;
};

export const postPaymentByOrder = async (
  id: string | number,
  phone: string | null,
  paymentType: number,
) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
    body: JSON.stringify({
      payment_type: paymentType,
      cellphone: phone,
    }),
  };

  const res = await fetchRequestBody(
    `${apiMainURL}/api/ecommerce/pay-order/${id}`,
    requestOptions,
  );

  return res;
};

export const postExHubAccessManagement = async () => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const res = await fetchRequestBody(
    `${apiMainURL}/api/get-simplifica-hub`,
    requestOptions,
  );

  return res;
};

export const getPaymentTypes = async () => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const response = await fetchRequestBody(
    `${apiMainURL}/jsonapi/taxonomy_term/payment_types`,
    requestOptions,
  );

  return JSON.parse(response || '');
};

export const getCities = async () => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const response = await fetchRequestBody(
    `${apiMainURL}/jsonapi/taxonomy_term/cities`,
    requestOptions,
  );

  return JSON.parse(response || '');
};

export const getOrdersHistory = async () => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const response = await fetchRequestBody(
    `${apiMainURL}/api/ecommerce/get-orders-history`,
    requestOptions,
  );

  return JSON.parse(response || '');
};
//Querys TourDeGente
export const getTourApi = async (bu:string) => {
  var myHeaders = new Headers();
  let actual=new Date();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/jsonapi/node/tour?`+ new URLSearchParams({
      'include': 'field_image, field_media, field_media.field_media_image, field_media.field_media_video_file',
      'filter[field_bu.entity.name]':bu,
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}

export const getStagesApi = async (tourId:string, tourBand:string) => {
  var myHeaders = new Headers();
  let actual = new Date().toISOString();

  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/jsonapi/node/stage?include=field_image,field_tour,field_banda,field_type_stage&filter[field_tour.entity.nid]=${tourId}&filter[field_banda.entity.name]=${tourBand}&sort=field_orden`,
    requestOptions);
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const getStagesByIdApi = async (id:string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/jsonapi/node/stage?`+ new URLSearchParams({
      'include': 'field_image,field_tour, field_type_stage, field_banda',
      'filter[drupal_internal__nid]':id
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const getActivitiesApi = async (idStage:string) => {
  var myHeaders = new Headers();
  let actual = new Date().toISOString();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/jsonapi/node/activity?`+ new URLSearchParams({
      'include': 'field_image,field_tour, field_stage',
      'filter[field_stage.entity.nid]':idStage,
      'filter[fs][condition][path]':'field_start_date',
      'filter[fs][condition][operator]':'<=',
      'filter[fs][condition][value]':`${actual}`,
      'sort':'field_orden'
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const getActivitiyByIdApi = async (idActivity:string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/jsonapi/node/activity?`+ new URLSearchParams({
      'include': 'field_image,field_tour, field_stage, field_type, field_csat_imagen',
      'filter[nid]':idActivity
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const getPointsService = async (idTour:string,band:string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/json');
 
  var raw = JSON.stringify({
    "sharp_id": localStorage.getItem('sharp'),
    "band": band
  });

  var requestOptions:RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/api/get-points/`+ idTour,
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const getAllBP = async (bu:string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/api/tours/bp?`+ new URLSearchParams({
    sharp:localStorage.getItem('sharp')||'100000001',
    bu:bu
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}
export const setBp = async (bpSharp:string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/json');
  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetchRequestBody(
    `${gamificationURL}/api/tours/bp-assign?`+ new URLSearchParams({
      sharp:localStorage.getItem('sharp')||'100000001',
    bp:bpSharp
  }),
    requestOptions,
  );
  if (response) return JSON.parse(response);
  else throw new Error('Error: Cart Items Error');
}