import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CarouselView from '../../carousel/carousel';
import { getDomain } from '../../../config/domain';
import {
  GetBodyInnerHTML,
  getStart,
} from '../../../modules/services/getElements.service';
import { useGetListAllCompetitionsQuery } from '../../../api/userSession';

export const ContestStart = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const id = window.location.href.split('?')[1];
  const title_info = decodeURI(window.location.href.split('?')[2]);
  const datacompetitions = useGetListAllCompetitionsQuery('');
  const competition = getCompetition(datacompetitions?.data, id);
  // console.log("Competition:  ", competition)
  function getCompetition(section: any, id: string): any {
    // console.log("INCLUDES:  ", section?.included)
    // console.log("COMPETO CONTENIDO:  ", section)
    var element: any;
    section?.data?.map((elem: any, index: number) => {
      if (elem?.attributes?.drupal_internal__nid == id) {
        let banner: string = '';
        let c: any;
        let rel: object = elem?.relationships;
        let prizes: object;
        let sections: object[] = [];
        let terms: object = {};
        Object.keys(rel).forEach(function (key, i) {
          if (key == 'field_quiz_preview') {
            {
              element = {
                type: 'sectionStart',
                content: getStart(
                  elem?.relationships[key]?.data.id,
                  section?.included,
                ),
              };
            }
          }
        });
      }
    });
    return element;
  }
  useEffect(() => {
    localStorage.removeItem('start');
    console.log(
      'Variable de sessión eliminada:¨ ',
      localStorage.getItem('start'),
    );
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/competitions">Concursos</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    {title_info || ''}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">
                {competition?.content?.name}
              </div>
              <div className="row align-content-center">
                <div className="col-md-6">
                  <img
                    src={`${competition?.content?.src}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6">
                  <div className="mt-5">
                    <GetBodyInnerHTML
                      content={competition?.content?.description || ''}
                    ></GetBodyInnerHTML>
                    <div className="mt-4 text-end">
                      <Link
                        to={`/competitions/ContestQuestions?${id}?${title_info}`}
                        className="btn btn-primary"
                      >
                        Empecemos
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContestStart;
