import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { deleteCartItem, selectCartItems } from '../../../../api/userSession';
import { getProductsShoppingCart } from '../../../../modules/services/getElements.service';
import OrderResume from '../../../OrderResume';
import Orders from '../../../Orders';
import { ModalLocation } from '../modalLocation';
import { EmptyShoppingCart } from './empty-shopping-cart';

const breadItems = [
  { label: 'Planeta Beerway', active: false, link: '/landing' },
  { label: 'E-commerce', active: false, link: '/e-commerce/landing' },
  { label: 'Tu Pago', active: true, link: '' },
];

export const PaymentProduct = () => {
  const [productsToShow, setProductsToShow] = useState<any>([]);
  const [resume, setResume] = useState({ total: 0, availableCredit: 0 });

  const getData = useCallback(async () => {
    const res = await selectCartItems();
    const products = getProductsShoppingCart(res.items);

    return {
      products: products.map((product) => ({
        ...product,
        calification: 5,
        orderId: product.ecommerce_orders_id,
        img: product.gallery[0]?.content.src || '',
      })),
      resume: {
        total: +res.credit_data.total_consumed_credit,
        availableCredit: res.credit_data.available,
        paymentType: res.payment_type,
      },
    };
  }, []);

  useEffect(() => {
    const obtainFirstCharge = async () => {
      const data = await getData();

      setProductsToShow(data.products);
      setResume(data.resume);
    };

    obtainFirstCharge();
  }, [getData]);

  const onCancelClick = async (product: string, orderId: string) => {
    const res = await deleteCartItem(orderId, product);

    if (res === 'true') {
      const data = await getData();

      setProductsToShow(data.products);
      setResume(data.resume);
    }
  };

  const onChangeDropdownButton = async () => {
    const data = await getData();

    setResume(data.resume);
  };

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <Breadcrumb>
            {breadItems.map(({ label, active, link }) => (
              <BreadcrumbItem
                key={`payment-breadcrumb-item-${label}`}
                {...{ active }}
              >
                {!active ? <Link to={link}>{label}</Link> : label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          <div className="d-flex justify-content-between justify-content-md-end py-0">
            <ModalLocation />
            <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 ml-5">
              <div className="col-12">
                <div className="row">
                  <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0"></div>
                  <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                    <div className="link-opt heart-in">
                      <Link to="/e-commerce/shopping-cart">
                        <i className="bi bi-cart3 heart-i"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                    <div className="link-opt">
                      <Link to="/e-commerce/favorites">
                        <i className="bi bi-heart"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {productsToShow.length === 0 ? (
              <div className="">
                <EmptyShoppingCart />
              </div>
            ) : (
              <div className="row">
                <div className="row col-12 col-lg-6 col-xl-8 mt-5">
                  <Orders
                    {...{ onCancelClick }}
                    products={productsToShow}
                    onChangeSelect={onChangeDropdownButton}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-4 main-tracking">
                  <OrderResume {...resume} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
