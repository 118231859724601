import React from 'react';
import { GetBodyInnerHTML } from '../../../modules/services/getElements.service';

type detailsProps = {
  id?: string;
  image?: string;
  title?: string;
  date?: Date;
  hours?: string[];
  place?: string;
  description?: string;
  buttonData?: {
    uri: string;
    label: string;
  };
  category?: string;
  onEventButtonClick: () => {};
};

const localeDateType = 'en-US'; //'es-CO'

const Details = ({
  image,
  title,
  date,
  hours,
  place,
  description,
  buttonData,
  category,
  onEventButtonClick,
}: detailsProps) => {
  const localeDate = date?.toLocaleDateString(localeDateType, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const hasMultipleHours = hours && hours.length > 1 && hours[0];

  let hoursToShow: string | undefined;

  if (hours)
    if (hasMultipleHours) hoursToShow = hours.join(' - ');
    else hoursToShow = hours[0];

  const ItHasNot = <>Doesn't have.</>;

  return (
    <>
      <img
        className="w-full mx-auto"
        style={{ maxHeight: '30vw', marginBottom: '2.5rem' }}
        src={image}
        alt="Event Img."
      />
      <p className="fw-bold fs-2">{title}</p>
      <div className="d-flex" style={{ marginBottom: '2.5rem' }}>
        <div className="d-flex flex-column w-75 gap-2 me-auto">
          <p style={{ marginBottom: '0.2rem', fontSize: '0.85rem' }}>
            <i
              className="bi bi-calendar-week-fill me-2"
              style={{ color: '#1a9986' }}
            />
            {localeDate ? localeDate : ItHasNot}
          </p>
          <p style={{ marginBottom: '0.2rem', fontSize: '0.85rem' }}>
            <i className="bi bi-clock me-2" style={{ color: '#1a9986' }} />
            {hoursToShow ? hoursToShow : ItHasNot}
          </p>
          <p style={{ marginBottom: '0.2rem', fontSize: '0.85rem' }}>
            <i
              className="bi bi-geo-alt-fill me-2"
              style={{ color: '#1a9986' }}
            />
            {place ? place : ItHasNot}
          </p>
          <p style={{ marginBottom: '0.2rem', fontSize: '0.85rem' }}>
            <i className="bi bi-card-list me-2" style={{ color: '#1a9986' }} />
            {category ? category : ItHasNot}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <a
            className="px-4 py-3 border bg-white fw-bolder text-center"
            style={{
              paddingTop: '.65rem',
              paddingBottom: '.65rem',
            }}
            href={buttonData?.uri}
            type="button"
            target="_blank"
            rel="noreferrer"
            onClick={onEventButtonClick}
          >
            {buttonData?.label === '' ? 'Click Here' : buttonData?.label}
          </a>
        </div>
      </div>
      <div
        className="text-muted lh-base"
        style={{ fontSize: '.85rem', textAlign: 'justify' }}
      >
        <GetBodyInnerHTML content={description} />
      </div>
    </>
  );
};

export default Details;
