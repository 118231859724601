import React from 'react';

const Littleshop = () => {
  return (
    <div>
      <h1>Little SHOP.!</h1>
    </div>
  );
};

export default Littleshop;
