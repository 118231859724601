export const secondsToDate = (seconds: number) => {
  if (seconds) {
    const pad = (num: number, size: number) => ('000' + num).slice(size * -1),
      time = seconds.toFixed(3),
      hours = Math.floor(+time / 60 / 60),
      minutes = Math.floor(+time / 60) % 60;

    return `${pad(hours, 2)}:${pad(minutes, 2)}`;
  }

  return;
};
