import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  CarouselItem,
} from 'reactstrap';
import {
  useGetBannerSectionApiQuery,
  useGetShoppingCartQuery,
} from '../../../../api/userSession';
import { domain_country, getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';
import {
  bannersInSectionEcommerce,
  getProducts,
  getProductsShoppingCart,
  getResumenPedidoActual,
} from '../../../../modules/services/getElements.service';
import { landingId } from '../../../../modules/services/sections';
import Favorites from '../../../favorites/favorites';
import { ModalLocation } from '../modalLocation';
import { EmptyShoppingCart } from './empty-shopping-cart';
import ShoppingCartProducts from './shoppingCart-products';

export const ShoppingCart = (props: any) => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [p, setP] = useState({ items: [] });

  let resumen = getResumenPedidoActual(p);
  let products = getProductsShoppingCart(p?.items);
  useEffect(() => {
    getProductsUpdate();
  }, []);
  function getProductsUpdate() {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      `${apiMainURL}/api/ecommerce/get-current-order?include=field_gallery&include=field_gallery.field_media_image&include=field_gallery.field_media_video_file&include=field_tags&include=field_product_category`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result: any) => {
        let error = JSON.parse(result)?.error;
        if (error && error?.length > 0) {
        } else {
          setP(JSON.parse(result));
        }
      })
      .catch((error) => console.log('error', error));
  }

  const calification: any[] = ['1', '2', '3', '4', '5'];

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  function handleUpdate() {
    setTimeout(() => {
      getProductsUpdate();
    }, 1000);
  }

  let dataBannersApi: any = {};
  var banners: Element[] = [];

  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  banners = bannersInSectionEcommerce(dataBannersApi);
  if (true)
    return (
      <div>
        <div className="main-content">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/landing">{domainData.name}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/e-commerce/landing">Tu tiendita</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/e-commerce/catalogue">Catálogo</Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Tu Carrito
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-between justify-content-md-end py-0">
              <ModalLocation></ModalLocation>
              <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 ml-5">
                <div className="col-12">
                  <div className="row">
                    <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0"></div>
                    <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                      <div className="link-opt heart-in">
                        <Link to="/e-commerce/shopping-cart">
                          <i className="bi bi-cart3 heart-i"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                      <div className="link-opt">
                        <Link to="/e-commerce/favorites">
                          <i className="bi bi-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {products.length == 0 ? (
                <div className="">
                  <EmptyShoppingCart />
                </div>
              ) : (
                <ShoppingCartProducts
                  content={products}
                  summary={resumen}
                  update={() => handleUpdate()}
                ></ShoppingCartProducts>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};
