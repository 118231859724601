import React from 'react';
import Event from './event';

type eventListProps = {
  events: {
    id?: string;
    date?: Date;
    hours?: string[];
    category?: string;
    title?: string;
    place?: string;
    image?: string;
  }[];
};

const EventList = ({ events }: eventListProps): JSX.Element => {
  return (
    <>
      {events.map((event) => (
        <Event
          key={`event-item-${event.date}-${event.category}-${event.title}`}
          {...event}
        />
      ))}
    </>
  );
};

export default EventList;
