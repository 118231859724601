import React, { useEffect, useState } from 'react';
import { SolveLinks } from '../../modules/links/solve_links';

export default function Sections(props: any) {
  const data = props.content;
  const [state, setState] = useState({
    loading: true,

    imageStatus: 'loading',
    countResources: 0,
  });
  const { countResources, imageStatus, loading } = state;
  function handleImageLoaded() {
    /* console.log("full Resource download"); */

    setState({
      ...state,
      countResources: countResources + 1,
    });
    if (countResources == props.content.length - 1) {
      setState({
        ...state,
        loading: false,
      });
    }
  }

  function handleImageErrored() {
    /* console.log("Falló la Carga"); */

    setState({
      ...state,
      imageStatus: 'failed to load',
    });
  }

  return (
    <div className="row">
      {data?.map((el: any, index: number) => (
        <div
          key={index}
          className={'section-card my-4 col-sm-6 col-lg-' + 12 / el.column}
        >
          {/*   Recursos Cargados: {countResources}
          Tamaño Array: {data.length}
          Loading:{loading.toString()} */}
          <div className="card text-white">
            <SolveLinks
              url={el.link}
              img={el.image}
              title={el.title}
              description={el.description}
              id={el.id || ''}
            />
          </div>
          {/* <div className={loading ? "mostrar" : "ocultar"}>
            <LoadSpinner />
          </div> */}
        </div>
      ))}
    </div>
  );
}
