import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CarouselView from '../../carousel/carousel';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import { getDomain } from '../../../config/domain';
import { Scrollbars } from 'rc-scrollbars';
import {
  getBannerCompetition,
  GetBodyInnerHTML,
  getListPrizes,
  getSection,
  getTermsAndConditions,
} from '../../../modules/services/getElements.service';
import {
  deleteSession,
  getUserData,
  useGetListAllCompetitionsQuery,
  useGetRankingCompetitionQuery,
  useGetResultCompetitionQuery,
} from '../../../api/userSession';
import { apiMainURL } from '../../../config/environment';
import LoadSpinner from '../../load-spinner/load-spinner';

export const ContestDetails = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [user2, setUser2] = useState('');
  const sharp = localStorage.getItem('sharp') || '';
  useEffect(() => {
    getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
      .then((data) => {
        let name = data?.profile?.first_name || '';
        localStorage.setItem('user', name);
        setUser2(name);
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, []);
  const id = window.location.href.split('?')[1];
  const nowDate = new Date();
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const datacompetitions = useGetListAllCompetitionsQuery('');
  const competition = getCompetition(datacompetitions?.data, id);
  const range =
    new Date(competition?.starDate || '') <= new Date() &&
    new Date() <= new Date(competition?.endDate || '');
  const competitionEnded = new Date() > new Date(competition?.endDate || '');
  const competitionNotStarted =
    new Date() < new Date(competition?.starDate || '');

  const ranking: any[] = [].concat(
    useGetRankingCompetitionQuery(id).data ?? [],
  );
  const participants: object[] = getRanking(ranking);
  const userR: any = getUserRanking(ranking);
  const limit = 2;
  const res = useGetResultCompetitionQuery(id).data;
  const r2 = useGetResultCompetitionQuery(id);

  // console.log("Concurso formado:  ", competition);
  // Toggle active state for Tab
  let any;
  const toggle = (any = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  });
  function getCompetition(section: any, id: string): any {
    // console.log("INCLUDES:  ", section?.included)
    // console.log("COMPETO CONTENIDO:  ", section)
    var element: any;
    section?.data?.map((elem: any, index: number) => {
      if (elem?.attributes?.drupal_internal__nid == id) {
        let banner: string = '';
        let c: any;
        let rel: object = elem?.relationships;
        let prizes: object;
        let sections: object[] = [];
        let terms: object = {};
        Object.keys(rel).forEach(function (key, i) {
          switch (key) {
            case 'field_banner':
              banner = getBannerCompetition(
                key,
                elem?.relationships,
                section?.included,
              );
              sections.push({
                type: 'banner',
                content: {
                  src: banner,
                },
              });
              break;
            case 'field_prizes':
              prizes = getListPrizes(
                elem.relationships[key]?.data?.id || '',
                section?.included,
              );
              sections.push({
                type: 'prizes',
                content: prizes,
              });
              break;
            case 'field_sections':
              elem?.relationships[key]?.data?.map((s: any, ind: number) => {
                sections.push({
                  type: 'section',
                  content: getSection(s?.id, section?.included),
                });
              });
              break;
            case 'field_terms_conditions':
              terms = getTermsAndConditions(
                elem?.relationships[key]?.data?.id,
                section?.included,
              );
              sections.push({
                type: 'terms',
                content: terms,
              });
              break;
          }
        });
        element = {
          author: 'En-curso',
          tag: 'En-curso',
          src: banner || '',
          text_info: elem?.attributes?.field_description?.summary || '',
          title_info: elem?.attributes?.title || '',
          id: elem?.attributes?.drupal_internal__nid || '',
          description: elem?.attributes?.field_description?.value || '',
          endDate: elem?.attributes?.field_end_date || '',
          starDate: elem?.attributes?.field_start_date || '',
          limit: elem?.attributes?.field_ranking_winners || 0,
          content: sections,
        };
      }
    });
    return element;
  }
  function getRanking(rankingData: any[]): object[] {
    let ranking: object[] = [];

    rankingData?.forEach((r: any, index: number) => {
      ranking.push({
        src:
          r?.uri == ''
            ? `${process.env.PUBLIC_URL}/resources/perfil-default.png`
            : r?.uri,
        points: r?.total_points + ' pts',
        name: r?.name,
        sharp: r?.user_sharp_id,
      });
    });
    return ranking;
  }
  function getUserRanking(rankingData: any[]): object {
    let ranking: object = {};
    rankingData?.forEach((r: any, index: number) => {
      if (r?.user_sharp_id == localStorage.getItem('sharp'))
        ranking = {
          sharp: r?.user_sharp_id,
          src:
            r?.uri == ''
              ? `${process.env.PUBLIC_URL}/resources/perfil-default.png`
              : r?.uri,
          position: index + 1,
        };
    });
    return ranking;
  }
  if (datacompetitions?.data)
    return (
      <div>
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/landing">{domainData.name}</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/competitions">Concursos</Link>
                    </li>
                    {competition?.title_info && (
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {`${competition?.title_info.substring(0, 20)}...` || ''}
                      </li>
                    )}
                  </ol>
                </nav>
                {competition?.title_info && (
                  <div className="title-welcome text-center">
                    {`${competition?.title_info}` || ''}
                  </div>
                )}

                <div className="tabs-section details-competitions">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === '1',
                        })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        Concurso
                      </NavLink>
                    </NavItem>
                    {competition?.limit !== 0 && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: currentActiveTab === '2',
                          })}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          Ranking
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div className="banner">
                            <img
                              src={competition?.src}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="row mt-5">
                            <div className="col-md-12">
                              <div className="text-details-awards">
                                <GetBodyInnerHTML
                                  className="text-generic-r my-5"
                                  content={competition?.description || ''}
                                ></GetBodyInnerHTML>

                                <span>
                                  Fecha de cierre: {competition?.endDate || ''}
                                </span>
                                <div className="mt-4">
                                  {res && (
                                    <Link
                                      to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                        competition?.title_info,
                                      )}`}
                                      className="btn btn-secondary disabled"
                                    >
                                      Ya participaste
                                    </Link>
                                  )}
                                  {!res && range && (
                                    <Link
                                      to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                        competition?.title_info,
                                      )}`}
                                      className="btn btn-secondary"
                                    >
                                      Quiero Paticipar
                                    </Link>
                                  )}
                                  {!range && competitionEnded && (
                                    <Link
                                      to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                        competition?.title_info,
                                      )}`}
                                      className="btn btn-secondary disabled"
                                    >
                                      Concurso terminado
                                    </Link>
                                  )}
                                  {!range && competitionNotStarted && (
                                    <Link
                                      to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                        competition?.title_info,
                                      )}`}
                                      className="btn btn-secondary disabled"
                                    >
                                      Próximamente{' '}
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {competition?.content?.map(
                            (elem: any, index: number) => {
                              if (elem.type == 'prizes') {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-12">
                                      <div className="title-page">
                                        {elem?.content?.title || ''}
                                      </div>
                                      <div>
                                        <GetBodyInnerHTML
                                          className="text-generic-r my-5"
                                          content={elem?.content?.desc || ''}
                                        ></GetBodyInnerHTML>
                                      </div>
                                      <div className="overflow-hidden mt-5">
                                        <div className="row row-cols-md-4 mt-sm-4 items-awards">
                                          {elem?.content?.content?.map(
                                            (prize: any, i: number) => {
                                              return (
                                                <div className="col" key={i}>
                                                  <div className="img-awards">
                                                    <img
                                                      src={
                                                        `${prize?.src}` || ''
                                                      }
                                                      alt=""
                                                      className="img-fluid prize"
                                                    />
                                                    <div>
                                                      {prize?.name || ''}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              if (elem.type == 'section') {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-12">
                                      <div className="title-page">
                                        {elem?.content?.name || ''}
                                      </div>
                                      <div>
                                        <GetBodyInnerHTML
                                          className="text-generic-r my-5"
                                          content={elem?.content?.description}
                                        ></GetBodyInnerHTML>
                                      </div>
                                      <div className="banner mt-5">
                                        <img
                                          src={`${elem?.content?.src}`}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              if (elem.type == 'terms') {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-12">
                                      <div className="title-page">
                                        {elem?.content?.name || ''}
                                      </div>
                                      <div>
                                        <GetBodyInnerHTML
                                          className="text-generic-r my-5"
                                          content={
                                            elem?.content?.description || ''
                                          }
                                        ></GetBodyInnerHTML>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            },
                          )}
                          <div className="text-center mt-4">
                            {res && (
                              <Link
                                to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                  competition?.title_info,
                                )}`}
                                className="btn btn-secondary disabled"
                              >
                                Ya participaste
                              </Link>
                            )}
                            {!res && range && (
                              <Link
                                to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                  competition?.title_info,
                                )}`}
                                className="btn btn-secondary"
                              >
                                Quiero Paticipar
                              </Link>
                            )}
                            {!range && competitionEnded && (
                              <Link
                                to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                  competition?.title_info,
                                )}`}
                                className="btn btn-secondary disabled"
                              >
                                Concurso terminado
                              </Link>
                            )}
                            {!range && competitionNotStarted && (
                              <Link
                                to={`/competitions/ContestStart?${id}?${encodeURIComponent(
                                  competition?.title_info,
                                )}`}
                                className="btn btn-secondary disabled"
                              >
                                Próximamente{' '}
                              </Link>
                            )}
                            <div className="mt-4">
                              <Link
                                to="/competitions"
                                className="txt-secondary"
                              >
                                Ver Más Concursos
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="center-ranking">
                        <div className="main-podium col-8 ">
                          {userR && userR.src && (
                            <div className="header-podium">
                              <div className="text-podium">
                                <span>{user2}</span> Tu posición en el ranking
                                es:
                              </div>
                              <div className="main-image">
                                <img
                                  className="imgMain"
                                  src={userR?.src}
                                  alt="user-img"
                                />
                                <div className="number-podium">
                                  <span>{userR.position}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="positions-table">
                            <div className="text-center title">Ranking</div>
                            {/* <div className="positions-first">
                                                        <div className="box-positions first-place">
                                                            <div className="number">1</div>
                                                            <div className="image">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/resources/img-positions.jpg`}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="ps-3">Omar</div>
                                                            <div className="points">70pt</div>
                                                            <div className="star">
                                                                <img src={`${process.env.PUBLIC_URL}/resources/star-one.svg`} width="60" />
                                                            </div>
                                                        </div>
                                                    </div> */}
                            <Scrollbars style={{ height: 500 }}>
                              {participants?.map((p: any, i: number) => {
                                if (i < competition?.limit) {
                                  return (
                                    <div
                                      className="row box-positions success"
                                      key={i}
                                    >
                                      <div className="col-11">
                                        <div className="number"> {i + 1}</div>
                                        <div className="image">
                                          <img
                                            src={`${p?.src}`}
                                            alt="img-ranking-user"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="ps-3 bold">
                                          {p.name || ''}
                                        </div>
                                      </div>
                                      <div className="star col-1">
                                        <div className="points bold">
                                          {p.points || ''}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                if (i >= competition?.limit) {
                                  return (
                                    <div className="row box-positions" key={i}>
                                      <div className="col-11">
                                        <div className="number">{i + 1}</div>
                                        <div className="image">
                                          <img
                                            src={`${p?.src}`}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="ps-3">
                                          {p.name || ''}
                                        </div>
                                      </div>

                                      <div className="star col-1">
                                        <div className="points">
                                          {p.points || ''}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </Scrollbars>
                          </div>
                        </div>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
export default ContestDetails;
