import React, { useEffect, useState } from 'react';
import { getCities, getPaymentTypes } from '../../../api/userSession';

const Information = ({
  total,
  phone,
  paymentType,
  locationId,
}: {
  total: number;
  phone?: string;
  locationId: string;
  paymentType: string;
}) => {
  const [city, setCity] = useState('');

  useEffect(() => {
    const getData = async () => {
      // const { data: paymentTypes } = await getPaymentTypes();
      const { data: cities } = await getCities();
      const id = cities
        .map(
          ({
            attributes: { drupal_internal__tid },
          }: {
            attributes: { drupal_internal__tid: number };
          }) => drupal_internal__tid,
        )
        .indexOf(+locationId);

      setCity(id !== -1 ? cities[id].attributes.name : 'Ninguna');
      // .then(
      //   ({
      //     data,
      //   }: {
      //     data: { attributes: { drupal_internal__tid: number; name: string } }[];
      //   }) => {
      //     const id = data
      //       .map(({ attributes: { drupal_internal__tid } }) => drupal_internal__tid)
      //       .indexOf(+locationId);

      //     return id !== -1 ? data[id].attributes.name : 'Ninguna';
      //   },
      // );
    };

    getData();
  }, []);

  return (
    <div className="d-table w-100 mt-5">
      <p className="fs-5 mb-3">Resumen del Pedido</p>
      <div className="d-flex flex-column gap-2 mb-4">
        <div
          className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
          style={{ height: '2.7em' }}
        >
          <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
            Tipo de Pago
          </p>
          <div className="d-flex align-items-center">
            <p
              className="mb-0"
              style={{
                fontSize: '14px',
              }}
            >
              {paymentType}
            </p>
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
          style={{ height: '2.7em' }}
        >
          <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
            Tu cupo de credito
          </p>
          <p className="mb-0" style={{ fontSize: '14px' }}>
            $10.00
          </p>
        </div>
        <div
          className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
          style={{ height: '2.7em' }}
        >
          <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
            Número de Teléfono
          </p>
          <div className="d-flex gap-1 align-items-center">
            {phone || 'Ninguno'}
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center border border-1 rounded-3 px-3"
          style={{ height: '2.7em' }}
        >
          <p className="fw-bolder mb-0" style={{ fontSize: '14px' }}>
            Lugar de retiro
          </p>
          <p className="mb-0" style={{ fontSize: '14px' }}>
            {city}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between text-muted">
          <p style={{ fontSize: '12px' }}>Subtotal</p>
          <p style={{ fontSize: '12px' }}>${total}</p>
        </div>
      </div>
      <hr className="m-0 mb-3" />
      <div className="d-flex justify-content-between mb-2">
        <p className="fw-bolder" style={{ fontSize: '12px' }}>
          Total
        </p>
        <p className="fw-bolder" style={{ fontSize: '12px' }}>
          ${total}
        </p>
      </div>
      <div className="d-grid mx-auto text-center">
        <a href="#download">
          <span className="me-1">
            <i className="bi bi-file-earmark-arrow-down" />
          </span>
          Descargar Comprobante
        </a>
      </div>
    </div>
  );
};

export default Information;
