import { VerticalAlignBottom, VerticalAlignCenter } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getActivitiesApi } from '../../../../../api/userSession';
import { capitalizeFirstLetter } from '../../../../../modules/services/getElements.service';
import LoadSpinner from '../../../../load-spinner/load-spinner';
import { getAllActivitiesData } from '../../GamificationServices/gamification-service';
import { ActivitiesCard } from './activities-card';

export const ActivitiesList = (props: any) => {
    const { idStage, data } = props;
    const [activities, setActivities] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);


    useEffect(() => {
        if (!isOpen && data) {
            getActivitiesApi(idStage).then((elem: any) => {
                let t = getAllActivitiesData(elem, idStage, data?.status, data?.points);
                if (t) {setActivities(t);
                    setIsOpen(true)}
            }).catch((error: any) => console.log("Error query:  ", error));
        }
    }, [activities, isOpen, data]);

    const [pendientes, setPendientes] = useState<any[]>([]);
    const [finalizadas, setFinalizadas] = useState<any[]>([]);
    function getActivitiesData() {
        let arrayPendientes: any[] = [];
        let arrayFinalizadas: any[] = [];
        activities?.forEach((act: any) => {
            (act?.estado == 'Pendiente' || act?.estado == 'Por hacer') ? arrayPendientes.push(act)  : arrayFinalizadas.push(act) ;
        });
        setFinalizadas(arrayFinalizadas);
        setPendientes(arrayPendientes);
    }
    useEffect(() => {
        getActivitiesData();
    }, [activities, data]);
    if(activities?.length>0 ||isOpen)
    return (
        <div className='pb-5 tabs-activities'>
            <div className="tabs-section">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <span className="tg-desc-section-activities">
                        <h1>Tus Actividades</h1>
                    </span>
                    <li className="nav-item " role="presentation">
                        <button
                            className="nav-link active tg-tab-activities w-100"
                            id="iniciativas-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#iniciativas"
                            type="button"
                            role="tab"
                            aria-controls="iniciativas"
                            aria-selected="true"
                        >
                            <i className="rounded-number">{pendientes?.length || 0}</i>   Actividades Pendientes
                        </button>
                    </li>
                    <li className="nav-item " role="presentation">
                        <button
                            className="nav-link tg-tab-activities w-100"
                            id="Blog-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Blog"
                            type="button"
                            role="tab"
                            aria-controls="Blog"
                            aria-selected="false"
                        >
                            <i className="rounded-number">{finalizadas?.length || 0}</i>  Actividades Finalizadas
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="iniciativas"
                        role="tabpanel"
                        aria-labelledby="iniciativas-tab"
                    >
                        <div className="py-4 box-programs ">
                            {pendientes.length > 0 && (
                                <div className="row">
                                    {pendientes.map((element: any, index: number) => {
                                        return (
                                            <div className="col-md-8 mx-md-auto col-lg-6 mx-lg-0 mt-4" key={index + '-' + element?.id}>
                                                <Link to={`/tour-de-gente/activities/detail?${element.id}`}>
                                                    <ActivitiesCard element={element} />
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {
                                pendientes.length == 0 && (
                                    <div className="mt-5 tg-success-section">
                                        <h2>Felicidades,</h2>
                                        <p className='mt-3 mb-5'>Has completado todas las actividades de esta etapa</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="Blog"
                        role="tabpanel"
                        aria-labelledby="Blog-tab"
                    >
                        <div className="py-4 box-programs ">
                            <div className="row">
                                {finalizadas.length > 0 && finalizadas?.map((element: any, index: number) => {
                                    return (
                                        <div className="col-md-8 mx-md-auto col-lg-6 mx-lg-0 mt-4" key={index + '-' + element?.id}>
                                            <Link to={`/tour-de-gente/activities/detail?${element.id}`}>
                                                <ActivitiesCard element={element} />
                                            </Link>
                                        </div>
                                    );
                                })}
                                {
                                finalizadas.length == 0 && (
                                    <div className="mt-5 tg-success-section">
                                        <h2>Lo sentimos,</h2>
                                        <p className='mt-3 mb-5'>Todavía no tienes ninguna actividad finalizada y aprobada por tu BP.</p>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    else return <LoadSpinner/>
}
