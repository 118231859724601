import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { useGetBannerSectionApiQuery } from '../../../api/userSession';
import { domain_country, getDomain } from '../../../config/domain';
import { bannersInSectionEcommerce } from '../../../modules/services/getElements.service';
import { landingId } from '../../../modules/services/sections';
import CardItemProduct from './card-item-product';

export function FavoriteProducts() {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const products: any[] = [
    {
      img: 'https://i.ibb.co/9p3jYb4/4.jpg',
      name: 'Stella Artois',
      calification: 5,
      volume: 'LTA 335 ml x 6',
      price: '12.000',
      id: '32',
      like: false,
      type: 'beer',
    },
    {
      img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
      name: 'Corona',
      calification: 5,
      volume: 'LTA 335 ml x 6',
      price: '11.000',
      id: '33',
      like: false,
      type: 'beer',
    },
    {
      img: 'https://i.ibb.co/9p3jYb4/4.jpg',
      name: 'Stella Artois2',
      calification: 5,
      volume: 'LTA 335 ml x 6',
      price: '2.000',
      id: '34',
      like: false,
      type: 'beer',
    },
    {
      img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
      name: 'Corona2',
      calification: 5,
      volume: 'LTA 335 ml x 6',
      price: '1.000',
      id: '35',
      like: false,
      type: 'beer',
    },
    {
      img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
      name: 'Corona33 -3',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'beer',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`,
      name: 'Bebida gaseosa 1',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'drink',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`,
      name: 'Bebida gaseosa 2',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'drink',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`,
      name: 'Bebida gaseosa 3',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'drink',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`,
      name: 'Bebida gaseosa 4',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'drink',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/red-juice-bottle.jpg`,
      name: 'Bebida gaseosa 5',
      calification: 5,
      volume: 'LTA 335 ml x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'drink',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`,
      name: 'Snack 1',
      calification: 5,
      volume: 'PACK  x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'snack',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`,
      name: 'Snack 2',
      calification: 5,
      volume: 'PACK  x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'snack',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`,
      name: 'Snack 3',
      calification: 5,
      volume: 'PACK  x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'snack',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`,
      name: 'Snack 4',
      calification: 5,
      volume: 'PACK  x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'snack',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/snack-bag.jpg`,
      name: 'Snack 5',
      calification: 5,
      volume: 'PACK  x 8',
      price: '1.100',
      id: '35',
      like: false,
      type: 'snack',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`,
      name: 'Alimento 1',
      calification: 5,
      volume: 'PACK  40g',
      price: '1.100',
      id: '35',
      like: false,
      type: 'food',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`,
      name: 'Alimento 2',
      calification: 5,
      volume: 'PACK  40g',
      price: '1.100',
      id: '35',
      like: false,
      type: 'food',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`,
      name: 'Alimento 3',
      calification: 5,
      volume: 'PACK  40g',
      price: '1.100',
      id: '35',
      like: false,
      type: 'food',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`,
      name: 'Alimento 4',
      calification: 5,
      volume: 'PACK  40g',
      price: '1.100',
      id: '35',
      like: false,
      type: 'food',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/food-packagingp.jpg`,
      name: 'Alimento 5',
      calification: 5,
      volume: 'PACK  40g',
      price: '1.100',
      id: '35',
      like: false,
      type: 'food',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`,
      name: 'Regalito 1',
      calification: 5,
      volume: '1 Unid',
      price: '',
      id: '35',
      like: false,
      type: 'gift',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`,
      name: 'Regalito 2',
      calification: 5,
      volume: '1 Unid',
      price: '',
      id: '35',
      like: false,
      type: 'gift',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`,
      name: 'Regalito 3',
      calification: 5,
      volume: '1 Unid',
      price: '',
      id: '35',
      like: false,
      type: 'gift',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`,
      name: 'Regalito 4',
      calification: 5,
      volume: '1 Unid',
      price: '',
      id: '35',
      like: false,
      type: 'gift',
    },
    {
      img: `${process.env.PUBLIC_URL}/resources/products/small-kids-backpack.jpg`,
      name: 'Regalito 5',
      calification: 5,
      volume: '1 Unid',
      price: '',
      id: '35',
      like: false,
      type: 'gift',
    },
  ];
  // Modal open state
  const [modal, setModal] = React.useState(false);
  const [fav, setFav] = React.useState(0);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  let dataBannersApi: any = {};
  var banners: Element[] = [];

  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  banners = bannersInSectionEcommerce(dataBannersApi);

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Favoritos
                  </li>
                </ol>
              </nav>
              <div className="title-welcome">Favoritos</div>
              <div className="d-flex justify-content-between justify-content-md-end py-0">
                <div className="col-auto col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0 me-md-2 buttons-actions-ecommerce">
                  <div className="link-geo ms-md-3" onClick={toggle}>
                    <div>
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="text">
                      {' '}
                      Recoger en
                      <br />
                      <span>Guayaquil</span>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    modalTransition={{ timeout: 1000 }}
                    className="modal-dialog-centered"
                  >
                    <ModalBody>
                      Selecciona el lugar donde quieres reclamar
                      <span>
                        La disponibilidad de los productos varía según el punto
                        de elección
                      </span>
                      <div>
                        <select>
                          <option value="">Seleccionar...</option>
                          <option value="1"> Cervecería Unión </option>
                          <option value="2"> Cervecería de Tocancipá </option>
                          <option value="3">
                            {' '}
                            Cervecería de Barranquilla{' '}
                          </option>
                          <option value="4"> Cervecería del Valle </option>
                          <option value="5"> Cervecería de Bucaramanga </option>
                          <option value="6"> Cervecería de Boyacá </option>
                          <option value="7"> Edificio 127 </option>
                          <option value="8"> Impresora del Sur </option>
                          <option value="9"> Indugral </option>
                          <option value="10"> Malteria Tibitó </option>
                          <option value="11"> Malteria Tropical </option>
                          <option value="12">
                            {' '}
                            R. Centro CD San Fernando{' '}
                          </option>
                          <option value="13"> R. Centro Calle 22 </option>
                          <option value="14"> R. Centro Amerícas </option>
                          <option value="15"> R. Centro AutoSur </option>
                          <option value="16"> R. Andes CD Armenia </option>
                        </select>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
                <div className="col-6 col-md-auto col-lg-2 my-3 px-md-0 px-3 py-0">
                  <div className="col-12">
                    <div className="row">
                      <div className="col col-xl-5 col-lg-5 col-md-3 col-sm-2 col-0"></div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                        <div className="link-opt">
                          <Link to="/e-commerce/shopping-cart">
                            <i className="bi bi-cart3"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4">
                        <div className="link-opt heart-in">
                          <Link to="/e-commerce/favorites">
                            <i className="bi bi-heart heart-i "></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {products.map((elem: any, index: number) => {
                    if (elem.type == 'beer')
                      return (
                        <div className="mb-4 col-xl-3 col-lg-4 col-md-6  col-sm-12 border-product">
                          <CardItemProduct
                            key={index}
                            content={elem}
                            className="card card-product"
                            fav={1}
                          ></CardItemProduct>
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
