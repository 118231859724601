import React, { useEffect, useState } from 'react';
import { useGetListOtherEventsWellnessApiQuery } from '../../../api/userSession';
import { apiMainURL } from '../../../config/environment';
import { secondsToDate } from '../../../tools/seconds2time';
import FeatureEventList from './feature-event-list';

type FeatureEventsProps = {
  id: string;
};

const FeatureEvents = ({ id }: FeatureEventsProps) => {
  const featureEventsData = useGetListOtherEventsWellnessApiQuery(id).data;

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (featureEventsData) {
      const newEvents = featureEventsData?.data.map(
        ({
          id: eventId,
          attributes: {
            field_fecha,
            title,
            field_direccion,
            field_hora_inicio,
            field_hora_final,
          },
          relationships: {
            field_imagen_destacada: {
              data: { id },
            },
            field_categoria_evento: { data: categoryData },
          },
        }: {
          id: string;
          attributes: {
            field_fecha: string;
            title: string;
            field_direccion: string;
            field_hora_inicio: number;
            field_hora_final: number;
          };
          relationships: {
            field_imagen_destacada: { data: { id: string } };
            field_categoria_evento: { data?: { id: string } };
          };
        }) => ({
          id: eventId,
          date: field_fecha && new Date(field_fecha + 'T00:00:00'),
          title,
          place: field_direccion,
          image: featureEventsData.included.reduce(
            (acc: string, cur: any) =>
              id === cur.id ? `${acc}${cur.attributes.uri.url}` : acc,
            apiMainURL,
          ),
          url: `/welfare-events/${eventId}`,
          category: featureEventsData.included.filter(
            (cat: any | null) => cat.id === (categoryData?.id || ''),
          )[0]?.attributes.name,
          hours: [
            secondsToDate(field_hora_inicio),
            secondsToDate(field_hora_final),
          ],
        }),
      );

      setEvents(newEvents);
    }
  }, [featureEventsData]);

  return <FeatureEventList {...{ events }} />;
};

export default FeatureEvents;
