import {
  postExHubAccessManagement,
  useGetAppsHubApiQuery,
  useGetBannerSectionApiQuery,
} from '../../../api/userSession';
import {
  appsInSection,
  bannersInSection,
} from '../../../modules/services/getElements.service';
import { Exhub } from './ex-hub';
import { hubId } from '../../../modules/services/sections';
import { useEffect, useState } from 'react';

export const AppsHub = (props: any) => {
  const [apps, setApps] = useState<any[]>([]);
  useEffect(() => {
    nueva();
  }, []);
  async function nueva() {
    const res = await postExHubAccessManagement();
    let dd: any[] = appsInSection(JSON.parse(res || ''));
    setApps(dd);

    console.log('APPS FORMADAS DESPUES DE LA FUNCION:     ', dd);
  }

  const dataBannersApi = useGetBannerSectionApiQuery(hubId).data;
  var banners = bannersInSection(dataBannersApi);

  console.log('APPS FORMADAS EN EXHUB:   ', apps);
  if (apps.length > 0)
    return (
      <>
        <Exhub imgs={apps} banner={banners} />
      </>
    );
  else return <></>;
};
