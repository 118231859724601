import React, { useEffect } from 'react';
import { deleteSession } from '../../api/userSession';
import CarouselView from '../carousel/carousel';

import './MediaComponent.scss';

type MediaComponentProps = {
  content: {
    type: 'video' | 'infografia' | 'image';
    src: string;
  }[];
  hasCarousel?: boolean;
};

export default function MediaComponent({
  content,
  hasCarousel = false,
}: MediaComponentProps) {
  if (hasCarousel) {
    const contentToShow = content?.map(({ src = '', type = '' }) => ({
      altText: `img-${src}`,
      src: { src, type: type !== 'video' ? 'image' : 'video' },
    }));

    return (
      <div className="media-carousel">
        <CarouselView content={contentToShow} />
      </div>
    );
  }

  return (
    <>
      {content?.map((element: any, index: number) => {
        return (
          <div
            key={index}
            className="multimedia-zone row justify-content-center align-items-center align-content-md-around"
          >
            {element.type == 'video' && (
              <div className="ratio ratio-21x9 multimedia-item">
                <video
                  src={element.src}
                  width="100%"
                  height="500"
                  controls
                ></video>
              </div>
            )}
            {element.type == 'infografia' && (
              <div className="multimedia-item">
                <img
                  key={index}
                  src={element.src}
                  alt="Infogr"
                  className="rounded img-fluid center"
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}
