import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDefaultContentQuery } from '../../api/userSession';
import { getDomain } from '../../config/domain';
import {
  getBannerDefaultSection,
  GetBodyInnerHTML,
  getDefaultContent,
  getListLinks,
  getMultimediaDefault,
} from '../../modules/services/getElements.service';
import CarouselView from '../carousel/carousel';
import LoadSpinner from '../load-spinner/load-spinner';
import MediaComponent from '../mediaComponent/media-component';
import Sections from '../sections/sections';
import { CardDefault } from './card-default';
import { Document, Page, pdfjs } from 'react-pdf';
// Import the main component

// Plugins

// Import the styles

// Worker

import { PdfViewer } from '../pdf-viewer/pdf-viewer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const Default = (props: any) => {

  const defaultData = useGetDefaultContentQuery(
    window.location.href.split('?')[1],
  )?.data;
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const [defaultPdfFile, setDefaultPdfFile] = useState(
    ''
  );

  const elem: any = getDefaultContent(defaultData);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderPDF, setRenderPDF] = useState(false);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  function onChangePage(event: string) {
    switch (event) {
      case '+':
        if (pageNumber + 1 <= numPages)
          setPageNumber(pageNumber + 1);
        break;
      case '-':
        if (pageNumber - 1 > 0)
          setPageNumber(pageNumber - 1);
        break;
    }

  }

  useEffect(() => {

  }, []);
  const callbackPDF = () => {


  }
  if (defaultData?.data && elem?.content)
    return (
      <div>
        <section className="main-content">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{domainData.name}</Link>
                </li>

                {elem?.breadcrumb?.map((el: any, i: number) => {
                  if (el?.name != 'Home') {
                    if (i < elem?.breadcrumb?.length - 1) {
                      return (
                        <li className="breadcrumb-item" key={i}>
                          <Link to={el?.path || '/'}> {el?.name || ''}</Link>
                        </li>
                      );
                    } else
                      return (
                        <li className="breadcrumb-item active" key={i}>
                          {el?.name || ''}
                        </li>
                      );
                  }
                })}
              </ol>
            </nav>
            <h3 className="section-title">{elem?.title || ''}</h3>
            <div className="row">
              <div className="col-12">
                {elem?.content?.map((el: any, index: number) => {
                  if (el?.type == 'links') {
                    return <Sections key={index} content={el?.content} />;
                  }
                  if (el?.type == 'media') {
                    return (
                      <MediaComponent
                        key={index}
                        content={el?.content}
                        hasCarousel={true}
                      />
                    );
                  }
                  if (el?.type == 'text') {
                    return (
                      <GetBodyInnerHTML
                        key={index}
                        className="text-generic-r my-5"
                        content={el?.content?.value || ''}
                      />
                    );
                  }
                  if (el?.type == 'banner') {
                    return <CarouselView key={index} content={el?.content} />;
                  }
                  if (el?.type == 'title') {
                    switch (el?.content?.type) {
                      case 'Título 1':
                        return (
                          <h1 key={index} className="section-title">
                            {el?.content?.value || ''}
                          </h1>
                        );
                        break;
                      case 'Título 2':
                        return (
                          <h2 key={index} className="section-title">
                            {el?.content?.value || ''}
                          </h2>
                        );
                        break;
                      case 'Título 3':
                        return (
                          <h3 key={index} className="section-title">
                            {el?.content?.value || ''}
                          </h3>
                        );
                        break;
                      case 'Título 4':
                        return (
                          <h4 key={index} className="section-title">
                            {el?.content?.value || ''}
                          </h4>
                        );
                        break;
                      case 'Título 5':
                        return (
                          <h5 key={index} className="section-title">
                            {el?.content?.value || ''}
                          </h5>
                        );
                        break;
                    }
                  }
                  if (el?.type == 'separator') {
                    return (
                      <div key={index} style={{ paddingTop: el?.content?.px }}>
                        <p className="oculto">{'   separator    '}</p>
                      </div>
                    );
                  }
                  if (el?.type == 'infografia') {
                    return (
                      <div className="multimedia-item">
                        <img
                          key={index}
                          src={el.content}
                          alt="Infografia"
                          className="rounded img-fluid center"
                        />
                      </div>
                    );
                  }
                  if (el?.type == 'cardGroup') {
                    return (
                      <div key={index} className="row">
                        {el?.content?.map((card: any, cardIndex: number) => {
                          return (
                            <CardDefault
                              idCard={card?.id}
                              key={index + cardIndex}
                            ></CardDefault>
                          );
                        })}
                      </div>
                    );
                  }
                  if (el?.type == 'pdf' && el?.content) {
                    return (
                      <div key={index} className="row mb-3">
                        <div className='mx-auto'>
                          <PdfViewer urlPDF={el?.content} callbackPDF={callbackPDF()} />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
