import { Link } from 'react-router-dom';
import { useGetListMostRelevantNewsFeedApiQuery } from '../../../../api/userSession';
import { getDomain } from '../../../../config/domain';
import { apiMainURL } from '../../../../config/environment';

export const LastNewsShorts = (props: any) => {
  const btnNewsfeed = props?.btn;
  const country = getDomain(window.location.hostname + '').country || '';
  const dataMostRelevantNews = useGetListMostRelevantNewsFeedApiQuery('');
  const news = getRelevantNews(dataMostRelevantNews?.data);

  function getRelevantNews(section: any): any[] {
    let news: any[] = [];
    section?.forEach((element: any) => {
      if (element?.field_country?.includes(country)) {
        news.push({
          image: apiMainURL + element.field_imagen_destacada || '',
          title: element.title || '',
          description: element?.body?.substr(0, 100) || '',
          date: element.created || '',
          id: element.uuid || '',
        });
      }
    });
    return news;
  }
  if (news.length > 0)
    return (
      <div className="row col-12">
        <div className="col col-lg-10 col-md-12  col-sm-12 title-welcome fs-3">
          Las noticias más relevantes
        </div>
        {btnNewsfeed == 1 && (
          <div className="col-2">
            <button className="global-button">
              <Link to="/news-feed" className="global-LinknewsfeedDate-link">
                {' '}
                Ir al Newsfeed
              </Link>
            </button>
          </div>
        )}
        <div className="overflow-hidden py-4 px-2 box-programs">
          <div className="row row-cols-1 row-cols-sm-2">
            {news.map((el: any, index: number) => {
              if (index < 3) {
                return (
                  <div className="col col-md-4 mb-4" key={index}>
                    <Link className="text-black" to={'/detail-news?' + el.id}>
                      <div className="card shadow-sm">
                        <img
                          src={el.image}
                          className="relevant-images "
                          alt="..."
                        />
                        <div className="card-body">
                          <span className="date-news">{el.date}</span>
                          <h5 className="card-title mt-4">
                            {el.title.substr(0, 73)}...
                          </h5>
                          <p className="card-text">
                            {el.description.substr(0, 200)}...
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else return <div className="" key={index}></div>;
            })}
          </div>
        </div>
      </div>
    );
  else return <></>;
};
