import React, { useEffect } from 'react';
import { deleteSession } from '../../api/userSession';

const LoadSpinner = () => {
  useEffect(() => {
    if (!localStorage.getItem('accessToken'))
      setTimeout(function () {
        deleteSession(window.location.href);
      }, 10000);
  }, []);
  return (
    <div>
      <section className="overlay-content">
        <div>
          <img
            src="https://media.tenor.com/images/a742721ea2075bc3956a2ff62c9bfeef/tenor.gif"
            alt="spinning"
            width="150"
            className="img-fluid"
          />
        </div>
      </section>
    </div>
  );
};

export default LoadSpinner;
