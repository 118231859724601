import counterReducer from '../counter/couterSlice';
import { combineReducers, configureStore, createStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { beerAmbassadorApi } from '../../../api/userSession';
const initialValue = '';
const reducerImage = (state = initialValue, action: any) => {
  return action.type;
};

const rootReducer = combineReducers({
  [beerAmbassadorApi.reducerPath]: beerAmbassadorApi.reducer,
  AppReducer: reducerImage,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
    getDefaultMiddleware().concat(beerAmbassadorApi.middleware),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat(beerAmbassadorApi.middleware),
    preloadedState,
  });
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
