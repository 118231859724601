import React, { useEffect, useState } from 'react';
import { getDomain } from '../../config/domain';
const FooterTerms = (props: any) => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  return (
    <div>
      <footer>
        <a target="_blank" href={domainData.policy}>
          Política de privacidad
        </a>
        <a target="_blank" href={domainData.terms} className="">
          Términos y condiciones
        </a>
      </footer>
    </div>
  );
};
export default FooterTerms;
