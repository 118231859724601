import { Link } from 'react-router-dom';
import { useGetListOtherProgramsWellnessApiQuery } from '../../../../api/userSession';
import { apiMainURL } from '../../../../config/environment';

export const OtherPrograms = (props: any) => {
  let dataOtherPrograms = useGetListOtherProgramsWellnessApiQuery(
    window.location.href.split('?')[1],
  ).data;
  var listOtherPrograms = getAllPrograms(dataOtherPrograms);

  function getAllPrograms(section: any): Element[] {
    var elements: any[] = [];
    let idSection = '';
    section?.data?.map((result: any, i: number) => {
      idSection = result.id || '';
      let id = result.relationships.field_imagen_destacada.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });
      elements[i] = {
        id: idSection,
        title: result.attributes.title,
        image: image,
        description: result.attributes?.field_informacion || '',
        link: `/programs-events/details-programs?${idSection}`,
        column: result.attributes.field_column || '3',
      };
    });

    return elements;
  }

  return (
    <>
      <div className="title-welcome fs-3">Otros Programas</div>
      <div className="overflow-hidden py-4 px-2 box-programs">
        <div className="row row row-cols-1 row-cols-sm-2 row-cols-lg-3">
          {listOtherPrograms.map((el: any, index: number) => {
            return (
              <div className="col mb-4" key={index}>
                <Link className="text-black" to={el.link}>
                  <div className="card">
                    <img src={el.image} className="img-fluid" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">{el.title}</h5>
                      <p className="card-text">
                        {el.description.substr(0, 200)}...
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OtherPrograms;
