import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OrderResume from '../utitities/order-resume';
{
  /* Tipos de resumen: 
                1. Simple o solo calculo de total$.
                2. Simple + Resumen con Credito
                3. simple + Resumen con Credito + QR  */
}
export default function ShoppingCartOrderSummary(props: any) {
  const history = useHistory();

  const onPaymentButtonClick = () => {
    history.push('/e-commerce/payment');
  };
  let summary = props?.summary;
  let type = props?.type;
  if (summary || type) {
    return (
      <div className="col-12 col-lg-6 col-xl-4 main-tracking mt-5">
        <OrderResume summary={summary} type={type}></OrderResume>
        <div className="d-grid mx-auto">
          <button
            className="btn btn-outlined-secondary btn-primary rounded-pill mx-4"
            style={{
              color: 'white',
              fontSize: '10px',
            }}
            onClick={onPaymentButtonClick}
          >
            Ir a pagar
          </button>
        </div>
      </div>
    );
  } else
    return (
      <div className="col-12 col-lg-6 col-xl-4 main-tracking">
        <div>
          <div className="d-table w-100 mt-5 placeholder-glow">
            <div className="summary-title mb-4 placeholder">
              Resumen de pedido
            </div>
            <div className="row">
              <div className="col-9 placeholder-glow">
                <p className="placeholder">Subtotal</p>
              </div>
              <div className="col-3 placeholder-glow">
                <p className="placeholder">$24.00</p>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-9 placeholder-glow">
                <p className="placeholder">Total</p>
              </div>
              <div className="col-3 placeholder-glow">
                <p className="placeholder">$ 24.00</p>
              </div>
            </div>
          </div>
          <div className="mt-5 placeholder-glow">
            <button className="btn btn-primary btn-detail-operation placeholder"></button>
          </div>
        </div>
      </div>
    );
}
