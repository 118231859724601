import React, { useState } from 'react';
import { domain_country } from '../../config/domain';
const PageError = () => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );

  return (
    <div className="main-content">
      <div className="container-fluid py-5">
        <div className="row flex-row align-items-center">
          <div className="col-sm-12 text-center">
            <div className="">
              <img
                src={`${process.env.PUBLIC_URL}/customs/${domain.color}/error_logo.svg`}
                width="960"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="fs-1 text-uppercase mt-5 mb-4 ">
              {' '}
              ¡Ups! algo salió mal.{' '}
            </div>
            <div className="fs-5">
              {' '}
              La URL solicitada no se encontró en este servidor.
              <br />
              Verifica si hay errores ortográficos en la URL o regrese a la
              página de inicio.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageError;
