import { Link } from 'react-router-dom';
import { useGetResultCompetitionQuery } from '../../../../api/userSession';
import { toLocaleDate } from '../../../../modules/services/getElements.service';
import LoadSpinner from '../../../load-spinner/load-spinner';

export const CalificationResults = (props: any) => {
  const id = window.location.href.split('?')[1];
  const title_info = decodeURI(window.location.href.split('?')[2]);
  const res = useGetResultCompetitionQuery(id).data;
  const res2 = props.content;
  if (res2)
    return (
      <div className="row">
        <div className="col-12">
          <div className="title-welcome text-center">{title_info || ''}</div>
          <div className="row align-content-center">
            <div className="col-md-6">
              <img
                src={`${process.env.PUBLIC_URL}/resources/banner-results.jpg`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="mt-5">
                <div className="">
                  {
                    <div className="score-section">
                      <span>¡Felicidades!</span> Obtuviste:
                      <br />
                      <div className="text-point">
                        {res2?.total_points || ''} de{' '}
                        {res2?.questionsNumber || ''} Puntos
                        <br />
                      </div>
                      <div className="fs-4">
                        Recuerda que este concurso cierra el{' '}
                        {toLocaleDate(res2?.field_end_date)}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="mt-5">
                <Link
                  to={`/competitions/ContestDetails?${id}`}
                  className="btn btn-primary"
                >
                  Finalizar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
