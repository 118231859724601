import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getNumberOfAssistants,
  postNewAssistantInEvent,
  useGetDetailEventWellnessApiQuery,
} from '../../../api/userSession';
import { apiMainURL } from '../../../config/environment';
import { secondsToDate } from '../../../tools/seconds2time';
import Details from './details';
import FeatureEvents from './feature-events';

const DetailedEvent = () => {
  const { eventId }: { eventId: string } = useParams();
  const eventData = useGetDetailEventWellnessApiQuery(eventId).data;

  const [event, setEvent] = useState({ title: '', id: undefined });

  // Fn to get number of assistants in the event
  const getAssistants = useCallback(async () => {
    const res = await getNumberOfAssistants(
      eventData.data.attributes.drupal_internal__nid,
    );

    if (res) return JSON.parse(res).count;
  }, [eventData]);

  const addNewAssistant = useCallback(async () => {
    const res = await postNewAssistantInEvent(
      eventData.data.attributes.drupal_internal__nid,
    );

    if (res) return JSON.parse(res).message;
  }, [eventData]);

  useEffect(() => {
    if (eventData) {
      const {
        id: eventId,
        attributes: {
          body: { value },
          field_fecha,
          title,
          field_direccion,
          field_enlace_externo,
          field_hora_inicio,
          field_hora_final,
        },
        relationships: {
          field_imagen_destacada: {
            data: { id },
          },
          field_categoria_evento: { data: categoryData },
        },
      } = eventData.data;

      console.log(field_fecha);
      const newEvent = {
        id: eventId,
        date: field_fecha && new Date(field_fecha + 'T00:00:00'),
        title,
        place: field_direccion,
        description: value,
        image: eventData.included.reduce(
          (acc: string, cur: any) =>
            id === cur.id ? `${acc}${cur.attributes.uri.url}` : acc,
          apiMainURL,
        ),
        buttonData: {
          uri: field_enlace_externo?.uri || '',
          label: field_enlace_externo?.title || '',
        },
        category: eventData.included.filter(
          (cat: any | null) => cat.id === (categoryData?.id || ''),
        )[0]?.attributes.name,
        hours: [
          secondsToDate(field_hora_inicio),
          secondsToDate(field_hora_final),
        ],
      };

      setEvent(newEvent);
    }
  }, [eventData]);

  return (
    <div className="main-content">
      <div className="container-fluid">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/landing">Beer Ambassador</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to="/welfare-events">Eventos</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {event.title}
            </li>
          </ol>
        </nav>
        <div className="d-flex">
          <div
            className="d-flex flex-column pt-2 pb-4 px-4 me-auto"
            style={{ width: '70%' }}
          >
            <Details {...event} onEventButtonClick={addNewAssistant} />
          </div>
          <div className="w-25 ps-2 pe-4 pt-3">
            {event.id && <FeatureEvents id={event.id!} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedEvent;
