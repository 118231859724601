import React, { memo, useEffect, useState } from 'react';
import { apiMainURL } from '../../../../config/environment';
import ProductItemOrder from './product-item-order';
import ShoppingCartOrderSummary from './shoppingCart-orderSummary';

export default function ShoppingCartProducts(props: any) {
  const [products, setProducts] = React.useState<any[]>([]);
  const [summary, setSummary] = React.useState<Object>();
  const f2 = props?.update;
  let id: string = '';
  let quantity: number = 0;
  let price: string = '';
  function handleUpdate(id1: string, quantity1: number, price1: string) {
    f2();
  }

  useEffect(() => {
    if (props?.content) {
      setProducts(props?.content);
    }
    if (props?.summary) {
      setSummary(props?.summary);
    }
  });

  return (
    <div className="row">
      <div className="row col-12 col-lg-6 col-xl-8 mt-5">
        {products?.map((product: any, i: number) => {
          return (
            <div className="" key={i}>
              <ProductItemOrder
                product={product}
                update={() => handleUpdate(id, quantity, price)}
              />
            </div>
          );
        })}
      </div>
      {/* Tipos de resumen: 
                1. Simple o solo calculo de total$.
                2. Simple + Resumen con Credito
                3. simple + Resumen con Credito + QR  */}

      {summary && (
        <ShoppingCartOrderSummary
          summary={summary}
          type={'1'}
        ></ShoppingCartOrderSummary>
      )}
    </div>
  );
}
