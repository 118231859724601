import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  CarouselItem,
} from 'reactstrap';
import { useGetBannerSectionApiQuery } from '../../../../api/userSession';
import { domain_country, getDomain } from '../../../../config/domain';
import { bannersInSectionEcommerce } from '../../../../modules/services/getElements.service';
import { landingId } from '../../../../modules/services/sections';

export const EmptyShoppingCart = (props: any) => {
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const product = {
    img: [
      'https://i.ibb.co/9p3jYb4/4.jpg',
      'https://i.ibb.co/Qkhg6M1/stella-2.jpg',
      'https://i.ibb.co/9p3jYb4/4.jpg',
    ],
    name: 'Stella Artois2',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '2.000',
    id: '34',
    like: false,
    type: 'beer',
  };
  const [mainImage, setMainImage] = useState<string>(product.img[0]);
  const elem = {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Stella Artois',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  };
  const elem2 = {
    img: 'https://i.ibb.co/JvRjHQ5/catalogue.png',
    name: 'Corona',
    calification: 5,
    volume: 'LTA 335 ml x 6',
    price: '12.000',
    id: '32',
    like: false,
    type: 'beer',
  };
  function handleImage(e: any) {
    setMainImage(e);
  }
  let dataBannersApi: any = {};
  var banners: Element[] = [];

  dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  banners = bannersInSectionEcommerce(dataBannersApi);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="section-title">
              <img
                src="https://i.ibb.co/0rh3JT5/carrito.jpg"
                alt=""
                className="empty-cart"
              />
            </div>
            <div className="return-cart section-title">
              <p className="search-text">
                Aún no tienes productos en tu carrito. <br />
                <br />
                Puedes regresar a la tiendita para seguir comprando.
              </p>
              <br />
              <br />
              <button className="btn btn-primary btn-return">
                Regresar a la tienda
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
