import React, { useEffect, useState } from 'react';
import {
  useGetBannerSectionApiQuery,
  useGetContentSectionApiQuery,
} from '../../api/userSession';
import { domain_country, getDomain } from '../../config/domain';
import { ssoURL } from '../../config/environment';
import { generateCodeChallenge, generateState } from '../../modules/Auth/auth';
import {
  bannersInSection,
  contentInSection,
} from '../../modules/services/getElements.service';
import { client_id, landingId } from '../../modules/services/sections';

const LandingUserWithSession = () => {
  const dataBannersApi = useGetBannerSectionApiQuery(landingId).data;
  const dataContentApi = useGetContentSectionApiQuery(landingId).data;
  var contents = contentInSection(dataContentApi);
  var banners = bannersInSection(dataBannersApi);
  const [domain, setDomain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  useEffect(() => {
    if (localStorage.getItem('code_verifier')) {
      window.location.href = '/landing';
    }
  });
  const login = () => {
    //TODO LOGIN REDIRECT
    if (true) { 
      var codeChallenge = '';
      var state = generateState();

      generateCodeChallenge().then((res) => {
        codeChallenge = res;
        let args = new URLSearchParams({
          response_type: 'code',
          client_id: client_id,
          code_challenge_method: 'S256',
          code_challenge: codeChallenge,
          redirect_uri: `${
            getDomain(window.location.hostname + '').appURL
          }/auth/callback`,
          state: state,
          scope: 'openid',
        });

        localStorage.setItem('args', args.toString());
        localStorage.setItem(
          'appURL',
          getDomain(window.location.hostname + '').appURL?.toString() || '',
        );
        localStorage.setItem(
          'redirectSinSession',
          `${getDomain(window.location.hostname + '').appURL}/auth/callback`,
        );
        if (!window.localStorage.getItem('lastUrl'))
          window.localStorage.setItem('lastUrl', window.location.href);
        else {
        }
        window.location.href = `${ssoURL}/oauth/authorize?` + args.toString();
      });
    } else {
    }
  };
  return (
    <div className="withOut">
      {/*  <h3 className="section-title">Diversidad</h3> */}
      <div className="row">
        <div className="col-lg-5 order-2 order-lg-1 mt-3">
          <div className="imgLanding text-black">
            <img
              src={`${process.env.PUBLIC_URL}/customs/${domain.color}/without-image.jpg`}
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-lg-7 order-1 order-lg-2">
          <div className="logSection">
            <img
              className="logo img-fluid"
              src={`${process.env.PUBLIC_URL}/customs/${domain.color}/logo.png`}
            />

            <div className="title-without">
              <h1 className="big-text fs-6">{domainData.title}</h1>
            </div>
            <h4 className="subtitle-without">Únete hoy.</h4>
            <button className="btn login-without btn-secondary" onClick={login}>
              Quiero iniciar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingUserWithSession;
