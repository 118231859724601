import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetBannerSectionApiQuery,
  useGetListProgramsWellnessApiQuery,
} from '../../../api/userSession';
import { getDomain } from '../../../config/domain';
import { apiMainURL } from '../../../config/environment';
import { programans_eventsId } from '../../../modules/services/sections';
import { bannersInSection } from '../../../modules/services/getElements.service';
import CarouselView from '../../carousel/carousel';

const Programs_Events = () => {
  const listProgramsWellnessApi = useGetListProgramsWellnessApiQuery('').data;
  var listPrograms = getAllPrograms(listProgramsWellnessApi);
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const dataBannersApi = useGetBannerSectionApiQuery(programans_eventsId).data;
  var banners = bannersInSection(dataBannersApi);
  function getAllPrograms(section: any): Element[] {
    var elements: any[] = [];
    let idSection = '';
    section?.data?.map((result: any, i: number) => {
      idSection = result.id || '';
      let id = result.relationships.field_imagen_destacada.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });
      elements[i] = {
        id: idSection,
        title: result.attributes.title,
        image: image,
        description: result.attributes?.field_informacion || '',
        link: `/programs-events/details-programs?${idSection}`,
        column: result.attributes.field_column || '3',
      };
    });
    return elements;
  }

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row main_contests">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/wellness">Bienestar</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {domainData.programs}
                  </li>
                </ol>
              </nav>
              <div className="title-welcome text-center">
                {domainData.programs}
                <CarouselView content={banners} />
              </div>
              <div className="text-generic-m text-center my-4">
                {domainData.descPrograms}
              </div>
            </div>
            <div className="title-welcome text-center">
              {' '}
              {domainData.titlePrograms}
            </div>
            <div className="overflow-hidden py-4 px-2 box-programs">
              <div className="row row row-cols-1 row-cols-sm-2 row-cols-lg-3">
                {listPrograms?.map((el: any, index: number) => {
                  return (
                    <div className="col mb-4" key={index}>
                      <Link className="text-black" to={el.link}>
                        <div className="card">
                          <img src={el.image} className="img-fluid" alt="..." />
                          <div className="card-body">
                            <h5 className="card-title">{el.title}</h5>
                            <p className="card-text">
                              {el.description.substr(0, 200)}...
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs_Events;
