type orderResumeState = {
  paymentType?: number;
  paymentTypes?: { label: string; value: number | string }[];
  withdrawalPlace?: string;
  zone?: string;
  phoneNumber?: string;
};

export enum orderResumeCases {
  SET_PAYMENT_TYPES = 'SET_PAYMENT_TYPES',
  SET_PAYMENT = 'SET_PAYMENT',
  SET_PHONE_NUMBER = 'SET_PHONE_NUMBER',
  SUBMIT_RESUME = 'SUBMIT_RESUME',
}

type orderResumeAction = {
  type: orderResumeCases;
  payload?: orderResumeState;
};

export const initialValues: orderResumeState = {
  paymentType: 0,
  paymentTypes: [{ label: '', value: 0 }],
  withdrawalPlace: '',
  zone: '',
  phoneNumber: '',
};

export const orderResumeReducer = (
  state: orderResumeState,
  action: orderResumeAction,
) => {
  switch (action.type) {
    case 'SET_PAYMENT_TYPES': {
      const { paymentTypes } = action.payload!;

      return { ...state, paymentTypes, paymentType: +paymentTypes![0].value };
    }

    case 'SET_PHONE_NUMBER': {
      const { phoneNumber, zone } = action.payload!;
      const number = zone === 'number' ? 'phoneNumber' : 'zone';

      return { ...state, [number]: phoneNumber };
    }

    case 'SET_PAYMENT': {
      const { paymentType } = action.payload!;

      return { ...state, paymentType };
    }

    default: {
      return initialValues;
    }
  }
};
