import { useGetListAllCompetitionsQuery } from '../../../../api/userSession';
import {
  getBannerCompetition,
  getImage,
  getListPrizes,
  getSection,
  getTermsAndConditions,
} from '../../../../modules/services/getElements.service';
import { Competitions } from '../competitions';
import { apiMainURL } from '../../../../config/environment';
import { Link } from 'react-router-dom';
import LoadSpinner from '../../../load-spinner/load-spinner';

export const PreCompetitions = (props: any) => {
  const datacompetitions = useGetListAllCompetitionsQuery('');
  const allCompetitions = getAllCompetitions(datacompetitions?.data);

  function getAllCompetitions(section: any): any[] {
    // console.log("INCLUDES:  ", section?.included)
    var elements: any[] = [];
    section?.data?.map((elem: any, index: number) => {
      let banner: string = '';
      let c: any;
      let rel: object = elem?.relationships;
      let prizes: object[] = [];
      let sections: object[] = [];
      let terms: object = {};
      Object.keys(rel).forEach(function (key, i) {
        if (key == 'field_banner')
          banner = getBannerCompetition(
            key,
            elem?.relationships,
            section?.included,
          );
      });
      elements[index] = {
        author: 'En-curso',
        tag: 'En-curso',
        src: banner || '',
        text_info: elem?.attributes?.field_description?.summary || '',
        title_info: elem?.attributes?.title || '',
        id: elem?.attributes?.drupal_internal__nid || '',
        description: elem?.attributes?.field_description?.value || '',
        endDate: elem?.attributes?.field_end_date || '',
        limit: elem?.attributes?.field_ranking_winners || '',
      };
    });

    // console.log("Elementos a retornar:  ", elements);
    return elements;
  }
  if (allCompetitions.length > 0)
    return <Competitions imgs={allCompetitions}></Competitions>;
  else if (datacompetitions.data)
    return (
      <section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{localStorage.getItem('appName')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Concursos
                  </li>
                </ol>
              </nav>
              <h3 className="section-title">No hay concursos para mostrar</h3>
            </div>
          </div>
        </div>
      </section>
    );
  else return <LoadSpinner></LoadSpinner>;
};
