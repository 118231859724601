import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  useGetBannerSectionApiQuery,
  useGetContentSectionApiQuery,
  useGetListDiversityFocusQuery,
} from '../../../api/userSession';
import { getDomain } from '../../../config/domain';
import { apiMainURL } from '../../../config/environment';
import { Section } from '../../../models/section.model';
import {
  bannersInSection,
  contentInSection,
  getURL,
} from '../../../modules/services/getElements.service';
import { diversityId } from '../../../modules/services/sections';
import CarouselView from '../../carousel/carousel';
import ContentLinks from '../../content-links/contentLinks';
import Sections from '../../sections/sections';

export const Diversity = (props: any) => {
  const [t, i18n] = useTranslation('global');
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  /*  const count = useSelector((state: any) => state.language.value);
    const user = useSelector((state: any) => state.image); */
  const history = useHistory();
  const dispatch = useDispatch();
  const dataContentApi = useGetContentSectionApiQuery(diversityId).data;
  const contents = contentInSection(dataContentApi);

  //useGetListDiversityFocusQuery
  const dataListDiversityFocusApi = useGetListDiversityFocusQuery('').data;

  /*   const { data, error, isLoading } = useGetUserQuery(""); */
  // Diversidad, Equidad e inclusión => Para MX
  const dataBannersApi = useGetBannerSectionApiQuery(diversityId).data;
  var banners = bannersInSection(dataBannersApi);
  var ListDiversityFocus = getAllFocus(dataListDiversityFocusApi);

  let section: Section;

  useEffect(() => {
    /*  dispatch(initData()); */
  });

  function getAllFocus(section: any): Element[] {
    var elements: any[] = [];
    let idSection = '';
    section?.data?.map((result: any, i: number) => {
      idSection = result.id || '';
      let id = result.relationships.field_banner_imagen.data.id;
      let image = '';
      section?.included?.map((include: any) => {
        if (id === include.id) {
          image = apiMainURL + include.attributes.uri.url;
        }
      });

      elements[i] = {
        id: idSection,
        title: result.attributes.name,
        image: image,
        description: result.attributes?.description?.value
          ? result.attributes.description.value
          : '',
        link: getURL(result.attributes.field_link?.uri || ''),
        column: result.attributes.field_column || '3',
      };
    });
    return elements;
  }

  return (
    <div>
      <section className="main-content">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/landing"> {domainData.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {domainData.diversityTitle || ''}
              </li>
            </ol>
          </nav>
          <h3 className="section-title">{domainData.diversityTitle || ''}</h3>
          <div className="row">
            <div className="col-12">
              <div className="title-welcome fs-2"></div>
              <CarouselView content={banners} />

              <h3 className="section-title">Nuestros focos</h3>
              <Sections content={ListDiversityFocus} />
              <ContentLinks id={diversityId}></ContentLinks>
              {domainData.diversityMedia.info && (
                <div className="row align-content-center">
                  <img
                    src={domainData.diversityMedia.info}
                    alt=""
                    className="align-content-center"
                  />
                </div>
              )}
              {domainData.diversityMedia.video && (
                <div className="ratio ratio-21x9 mt-5">
                  <video
                    src={domainData.diversityMedia.video}
                    width="100%"
                    height="500"
                    controls
                  ></video>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Diversity;
