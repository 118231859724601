import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { apiMainURL } from '../../../config/environment';
import Alerts from '../../../modules/services/alerts';
import LoadSpinner from '../../load-spinner/load-spinner';
import FavHeart from './utitities/favHeart';

export const CardItemProduct = (props: any) => {
  const product = props?.content;
  const favButton = props?.fav;
  const [fav, setFav] = useState(0);
  const [flag, setFlag] = useState(0);

  const calification: any[] = ['1', '2', '3', '4', '5'];

  function handleAdd(event: any): void {
    event.preventDefault();
    if (event?.target?.classList?.contains('disabled')) {
      //FETCH AGREGAR FAVORITOS

      event?.target?.classList?.remove('disabled');
    } else {
      event?.target?.classList?.add('disabled');
      //FETCH ELIMINAR DE FAVORITOS
    }
    // console.log("Evento del botón:  ", event);

    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      quantity: 1,
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    if (product?.internID)
      fetch(
        `${apiMainURL}/api/ecommerce/add-item/${product?.internID}`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result: any) => {
          let error = JSON.parse(result)?.error;
          if (error && error?.length > 0) {
          } else {
            // console.log("RESULTADO DE AGREGAR:   ", JSON.parse(result));
            setFlag(1);
            setTimeout(() => {
              setFlag(0);
            }, 20000);
          }
        })
        .catch((error) => console.log('error', error));
  }
  useEffect(() => {
    setFav(1);
  });
  if (product)
    return (
      <div className="card-product">
        {product?.internID && <FavHeart content={product?.internID}></FavHeart>}

        <div className="card-product-content">
          <div className="card-upper-content">
            <Link to={'/e-commerce/detail?' + product?.internID}>
              <img
                src={product?.gallery[0]?.content?.src || ''}
                className="card-img-top img-product"
                alt="..."
              />
            </Link>
          </div>
          <div className="card-lower-content black ">
            <div className="product-title">
              <h5 className=" black">{product?.name?.substr(0, 35)}</h5>
            </div>
            <div className="row">
              <div className="col-8">
                <Link to={'/e-commerce/detail?' + product?.internID}>
                  <p className="card-text black">
                    <div className="row mx-0">
                      {calification.map((cal: any, indexC: number) => {
                        return (
                          <img
                            src={`${process.env.PUBLIC_URL}/resources/star-complete.jpg`}
                            alt=""
                            className="star-calification"
                            key={indexC}
                          />
                        );
                      })}
                    </div>
                  </p>
                  <p
                    className="card-text black card-volume"
                    style={{ marginBottom: '10px' }}
                  >
                    {product?.volume}
                  </p>
                  <small
                    className="card-text black card-volume"
                    style={{ fontSize: '10px' }}
                  >
                    Precio
                  </small>
                  <h5
                    className="card-text black "
                    style={{ marginTop: '0px', fontSize: '1rem' }}
                  >
                    $ {product?.price}
                  </h5>
                </Link>
              </div>
              <div className="col-4"></div>
              <button
                className="btn btn-primary btn-addCart "
                onClick={(e) => handleAdd(e)}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
export default CardItemProduct;
