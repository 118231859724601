import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Modal, ModalBody } from 'reactstrap';
import { getActivitiyByIdApi, getAllBP, getPointsService } from '../../../../../api/userSession';
import { store } from '../../../../../modules/appState/store/store';
import LoadSpinner from '../../../../load-spinner/load-spinner';
import { getActivityData } from '../../GamificationServices/gamification-service';
import { ModalVoucher } from "../Modal/modal-voucher";
import { Typeahead } from 'react-bootstrap-typeahead';
import { AutocompleteInput } from './bp-autocomplete';
import { GetBodyInnerHTML } from '../../../../../modules/services/getElements.service';
import { ModalComponentBp } from '../../landing/gamificationLanding';
import { getDomain } from '../../../../../config/domain';

export const ActivitiesDetail = () => {
    // getActivitiyByIdApi

    const [activities, setActivities] = useState<any[]>([]);
    const location = useLocation();
    const storeApp = store;
    const id = location?.search?.split('?')[1] || '0';
    const [user, setUser] = useState<any>(null);
    const [points, setPoints] = useState<any>(null);
    const [updated, setUpdated] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<any>(false);
    const [domainData, setDomainData] = useState(
        getDomain(window.location.hostname + ''),
    );



    storeApp.subscribe(() => {
        setUser(JSON.parse(localStorage.getItem('user_info') || ''));
    });
    function handleClick(url: string) {
        window.open(url);
    }
    useEffect(() => {
        if (!user && localStorage.getItem('user_info'))
            setUser(JSON.parse(localStorage.getItem('user_info') || ''));
        if (activities.length < 1 && !isOpened) {
            getActivitiyByIdApi(id).then((act: any) => {

                let Ids = getIdsTourStage(act?.included);
                if (user?.band && Ids?.tourId != '' && Ids?.stageId != '') {
                    getPointsService(Ids?.tourId, user?.band).then((elem: any) => {
                        if (elem) {
                            setPoints({ ...elem, 'band': user?.band })
                            let t = getActivityData(act, { ...elem, 'band': user?.band, ...Ids });
                            if (t) {

                                document.documentElement.style.setProperty('--custom-color', Ids?.color);
                                setActivities(t);
                                setUpdated(true);

                            }
                        }
                    }).catch((error: any) => console.log("Error query:  ", error));
                }
                setIsOpened(true);
            }).catch((error: any) => console.log("Error query:  ", error));
        }
    }, [user, activities, updated]);
    function getIdsTourStage(includes: any[]): any {
        let tourId = '';
        let stageId = '';
        let color = '';

        includes?.map((inc: any) => {
            if (inc?.type == 'node--tour') {
                tourId = inc?.attributes?.drupal_internal__nid;
            }
            if (inc?.type == 'node--stage') {
                stageId = inc?.attributes?.drupal_internal__nid;
                color = inc?.attributes?.field_color?.color || '';
            }
        });
        return {
            tourId: tourId,
            stageId: stageId,
            color: color
        };
    }
    if (activities?.length > 0 && isOpened == true && activities[0]?.fechaFin != '' && updated) {
        const breadItems = [
            { label: domainData?.name || '', active: false, link: '/landing' },
            { label: 'Tour de gente', active: false, link: '/tour-de-gente' },
            // { label: 'Etapa', active: false, link: `/tour-de-gente/stages?${activities[0]?.stageId}` },
            { label: 'Detalle de Actividad', active: true, link: '' },
        ];

        return (
            <div className='main-content'>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col col-12 col-md-8">
                            <Breadcrumb>
                                {breadItems.map(({ label, active, link }) => (
                                    <BreadcrumbItem
                                        key={`payment-breadcrumb-item-${label}`}
                                        {...{ active }}
                                    >
                                        {!active ? <Link to={link}>{label}</Link> : label}
                                    </BreadcrumbItem>
                                ))}
                            </Breadcrumb>
                        </div>
                        <div className="col col-12 col-md-4">
                            {user && (
                                <ModalComponentactivityBp lockBackdrop={true} user={user} />
                            )}
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="box-border-custom me-lg-4 me-0">
                                <span className="title-progress-gamification">
                                    {activities[0]?.titulo}
                                </span>
                                <div className="box-activity-detail">
                                    <img
                                        src={activities[0]?.image}
                                        className="img-fluid"
                                        alt="act-image"
                                    />
                                    <div className="box-activity-date">
                                        Fecha final: <span>{new Date(activities[0]?.fechaFin)?.toLocaleDateString()}</span>
                                    </div>
                                    <div>
                                        {activities[0]?.descripcion}
                                        <div className="steps-list">
                                            <GetBodyInnerHTML content={activities[0]?.detail} />
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center">
                                        {activities[0]?.buttons?.map((btn: any, indexBtn: number) => {
                                            if (indexBtn < 2)
                                                return (
                                                    <button onClick={() => handleClick(btn?.uri || '')} className={indexBtn % 2 == 0 ? `btn btn-outline-secondary m-1` : 'btn btn-primary m-1'} key={indexBtn + ' btn-act'} >
                                                        {btn?.title?.substring(0, 20) || 'Ingresa aquí'}
                                                    </button>
                                                )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">

                            <div className="ps-lg-5 ps-0">
                                <div className="title-welcome fw-bold">
                                    Estado de tu actividad
                                </div>
                                <div className="box-border-custom">
                                    {activities[0]?.estado != 'Aprobada' && (
                                        <div className="row ">
                                            <div className="col-6 col-sm-8 my-auto">
                                                <div className="title-welcome2  fw-bold">
                                                    {activities[0]?.estado == 'Pendiente' ? 'Pendiente por aprobar' : activities[0]?.estado}
                                                </div>

                                            </div>
                                            <div className="col-6 col-sm-4">
                                                {activities[0]?.estado == 'Aprobada' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Aprobada.png`}
                                                    className="img-fluid"
                                                    alt="img-Aprobada"
                                                />}
                                                {activities[0]?.estado == 'Pendiente' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Pendiente.png`}
                                                    className="img-fluid"
                                                    alt="img-Pendiente"
                                                />}
                                                {activities[0]?.estado == 'Rechazada' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Rechazada.png`}
                                                    className="img-fluid"
                                                    alt="img-Rechazada"
                                                />}
                                                {activities[0]?.estado == 'Por hacer' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Por_hacer.png`}
                                                    className="img-fluid"
                                                    alt="img-Por hacer"
                                                />}
                                                {activities[0]?.estado == 'Finalizada' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Finalizada.png`}
                                                    className="img-fluid"
                                                    alt="img-Por hacer"
                                                />}
                                            </div>
                                        </div>
                                    )}
                                    {activities[0]?.estado == 'Aprobada' && (
                                        <div className="row center-status">
                                            <div className="col-8 col-sm-9">
                                                <div className="row center-status">
                                                    <div className="col col-12 col-sm-4  text-center">
                                                        <div className="title-welcome-status fw-bold">
                                                            {'Haz ganado'}
                                                        </div>
                                                    </div>

                                                    {activities[0]?.estado == 'Aprobada' && (
                                                        <div className="col col-12 col-sm-8 text-center">

                                                            <span className='numberPoints'>
                                                                {activities[0]?.puntos} de   {activities[0]?.max_points} puntos
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/resources/tour-gente/icon-score.svg`}
                                                                    className="icon-star ms-1"
                                                                    alt="..."
                                                                />
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                            <div className="col-4 col-sm-3">
                                                {activities[0]?.estado == 'Aprobada' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Aprobada.png`}
                                                    className="img-fluid"
                                                    alt="img-Aprobada"
                                                />}
                                                {activities[0]?.estado == 'Pendiente' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Pendiente.png`}
                                                    className="img-fluid"
                                                    alt="img-Pendiente"
                                                />}
                                                {activities[0]?.estado == 'Rechazada' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Rechazada.jpg`}
                                                    className="img-fluid"
                                                    alt="img-Rechazada"
                                                />}
                                                {activities[0]?.estado == 'Por hacer' && <img
                                                    src={`${process.env.PUBLIC_URL}/resources/Por_hacer.png`}
                                                    className="img-fluid"
                                                    alt="img-Por hacer"
                                                />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {activities[0]?.evidence && (
                                    <>
                                        {new Date().toISOString()
                                            <= activities[0]?.fechaFin && activities[0]?.estado != 'Aprobada' && (
                                                <div className="">
                                                    <div className="title-welcome fw-bold">
                                                        Sube tu comprobante
                                                    </div>
                                                    <div className="box-border-custom">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/resources/Comprobante.png`}
                                                                    className="img-fluid"
                                                                    alt="..." width="156"
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                Sube una imagen que no pese mas de 5MB donde podamos ver claramente que completaste la actividad
                                                                <div className="mt-4 text-center">
                                                                    <ModalVoucher status={activities[0]?.estado} text={activities[0]?.estado == 'Por hacer' ? 'Subir comprobante' : 'Cambiar comprobante'} idActivity={id} trigger={() => { window.location.reload(); }}></ModalVoucher>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {new Date()
                                            <= new Date(activities[0]?.fechaFin) && activities[0]?.estado == 'Aprobada' && (
                                                <div className="">
                                                    <div className="title-welcome fw-bold">
                                                        Actividad  Aprobada
                                                    </div>
                                                    <div className="box-border-custom">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/resources/Aprobada.png`}
                                                                    className="img-fluid"
                                                                    alt="..." width="156"
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                No puedes subir comprobantes, ya fué aprobada.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        {new Date()
                                            > new Date(activities[0]?.fechaFin) && activities[0]?.estado != 'Aprobada' && activities[0]?.estado != 'Rechazada' && (
                                                <div className="">
                                                    <div className="title-welcome fw-bold">
                                                        Actividad  Finalizada
                                                    </div>
                                                    <div className="box-border-custom">
                                                        <div className="row">
                                                            <div className="col-6">

                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/resources/Finalizada.png`}

                                                                    className="img-fluid"
                                                                    alt="..." width="156"
                                                                />
                                                            </div>
                                                            <div className="col-6 my-auto">
                                                                Lo sentimos esta actividad ha finalizado y no puedes subir un comprabante
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        <div className="">
                                            <div className="title-welcome fw-bold">
                                                Queremos escucharte
                                            </div>
                                            <div className="box-border-custom">


                                                <img
                                                    src={activities[0]?.csat?.image}
                                                    className="img-fluid"
                                                  
                                                />

                                               <div className="text-center mt-3">
                                               <button className='btn btn-opinion ' onClick={() => window.open(activities[0]?.csat?.btn?.url, '_blank', 'noopener,noreferrer')}><span><i className="bi bi-heart-fill mx-1"></i>{activities[0]?.csat?.btn?.title}</span> </button>
                                               </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                        </div>
                    </div>
                </div>
            </div>
            </div >
        )
    }
    else return <LoadSpinner />
}




export const ModalComponentactivityBp = (props: any) => {


    const [name, setName] = useState<string>('');
    const [modal, setModal] = React.useState(false);
    const [bp, setBp] = useState('');
    const [options, setOptions] = useState([]);
    const [opened, setOpened] = useState(false);
    const [backdrop, setBackdrop] = useState<boolean | "static">(true);
    const { lockBackdrop, user } = props;


    const toggle = () => setModal(!modal);
    useEffect(() => {
        if (!opened)
            getAllBP(user?.bu).then(({ bp, bps }: any) => {
                setOptions(bps);
                setName(getNameBp(bp, bps));
                setOpened(true);
                if (!bp && lockBackdrop) {
                    setModal(true);
                    setBackdrop('static')
                }
            }).catch((error: any) => console.log("Error query:  ", error));
    }, [options, bp, name, backdrop]);
    function getNameBp(bp: string, bps: any[]): string {
        let name = '';
        bps?.map((bpItem: any) => {
            if (bpItem?.field_sharp == bp) {

                name = bpItem?.name || '';
            }
        });
        return name;
    }
    return (
        <div className="col-auto col-md-auto  px-3 py-0 me-md-2">
            <div className="link-geo ms-md-3" onClick={toggle}>
                <div className="text">
                    <span>{name ? 'Tu BP:  ' + (name || '') : 'Selecciona tu BP'} </span>
                </div>
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 1000 }}
                backdrop={backdrop} keyboard={false}
                className="modal-dialog-centered"
            >
                <ModalBody>
                    <h3 className='mb-3'>Bussiness Partner</h3>
                    <span className='mb-3'>
                        Cuéntanos quien es tu Business Partner, recuerda que será la persona encargada de calificar tus actividades en Tour de gente.
                    </span>
                    <div>
                        <AutocompleteInput
                            text={'Escriba el nombre aquí..'}
                            bu={'CO'}
                            trigger={(bpData: any) => { setName(bpData?.name); toggle(); }}
                            options={options}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}