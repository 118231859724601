import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetAllNewsQuery,
  useGetCategoryNewsQuery,
  useGetDetailDiversityFocusApiQuery,
  useGetListMostRelevantNewsFeedApiQuery,
} from '../../../api/userSession';
import { getDomain } from '../../../config/domain';
import { apiMainURL } from '../../../config/environment';
import {
  GetBodyInnerHTML,
  getImage,
  toLocaleDate,
} from '../../../modules/services/getElements.service';
import { LastNewsShorts } from './last-news/last-news-short';
import LoadSpinner from '../../load-spinner/load-spinner';

const Newsfeed = () => {
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const country = getDomain(window.location.hostname + '').country || '';

  const dataNews = useGetAllNewsQuery('')?.data;
  const globalData = useGetCategoryNewsQuery('GLOBAL')?.data;
  const tubuData = useGetCategoryNewsQuery('TU BU')?.data;
  const zonamazData = useGetCategoryNewsQuery('ZONA MAZ')?.data;
  const globalNews = getNews(globalData);
  const tubuNews = getNews(tubuData);
  const zonamazNews = getNews(zonamazData);
  const allNews = getNews(dataNews);

  const dataMostRelevantNews = useGetListMostRelevantNewsFeedApiQuery('');
  const news = getRelevantNews(dataMostRelevantNews?.data);

  function getRelevantNews(section: any): any[] {
    let news: any[] = [];

    section?.forEach((element: any) => {
      if (element?.field_country?.includes(country)) {
        news.push({
          image: apiMainURL + element.field_imagen_destacada || '',
          title: element.title || '',
          description: element.body?.substr(0, 200) || '',
          date: element.created || '',
          id: element.uuid || '',
        });
      }
    });
    return news;
  }
  function getNews(n: any): any[] {
    let news: any[] = [];
    let category = '';
    n?.data?.forEach((element: any) => {
      let idImage = element?.relationships?.field_imagen_destacada?.data?.id;
      let image = getImage(idImage, n.included);
      category = element?.attributes?.field_categoria[0] || '';
      news.push({
        image: image || '',
        title: element?.attributes?.title || '',
        description:
          element?.attributes?.body?.summary?.substr(0, 100) ||
          element?.attributes?.body?.value?.substr(0, 100) ||
          '',
        date: toLocaleDate(element?.attributes?.created) || '',
        id: element?.id || '',
      });
    });
    return news;
  }
  if (
    dataMostRelevantNews?.data &&
    zonamazData?.data &&
    tubuData?.data &&
    globalData?.data &&
    dataNews?.data
  ) {
    if (
      globalNews.length > 0 ||
      tubuNews.length > 0 ||
      zonamazNews.length > 0 ||
      allNews.length > 0
    )
      return (
        <div>
          <div className="main-content">
            <div className="container-fluid">
              <div className="row main_contests newsfeed-contest">
                <div className="col-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/landing">{domainData.name}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        NewsFeed
                      </li>
                    </ol>
                  </nav>
                  <div className="title-welcome text-center">
                    Últimas noticias
                  </div>
                  <div className="content-news">
                    {news.length > 0 && (
                      <div className="row">
                        <div className="col-lg-7">
                          {news.map((el: any, index: number) => {
                            if (index == 0) {
                              return (
                                <Link
                                  className="text-black"
                                  key={index}
                                  to={'/detail-news?' + el.id}
                                >
                                  <div className="card shadow news-list">
                                    <img
                                      src={el?.image}
                                      className="img-fluid rounded-4"
                                      alt="..."
                                    />
                                    <div className="card-body preview-content">
                                      <div className="prev-body">
                                        <h5 className="card-title">
                                          {el?.title}
                                        </h5>

                                        <GetBodyInnerHTML
                                          content={el.description}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            }
                          })}
                        </div>
                        <div className="col-lg-5 left-content">
                          {news.map((el: any, index: number) => {
                            if (index > 0 && index < 5) {
                              return (
                                <div className="col-12 " key={index}>
                                  <Link
                                    className="text-black"
                                    to={'/detail-news?' + el.id}
                                  >
                                    <div className="card shadow news-list row secondary-card">
                                      <div className="col-8 my-2">
                                        <span className="date-news">
                                          {el.date}
                                        </span>
                                        <h5 className="card-title mt-4">
                                          {el.title.substr(0, 80)}...
                                        </h5>
                                        {/* <GetBodyInnerHTML content={el.description} /> */}
                                      </div>
                                      <div className="col-4">
                                        <img
                                          src={el.image}
                                          className="prev-image"
                                          alt="..."
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                    <div className="title-welcome text-center">
                      Historias para ti
                    </div>
                    <div className="row tabs-section tabs-news">
                      <div className=" col col-lg-10 col-md-12  col-sm-12  start-navs">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item " role="presentation">
                            <button
                              className="nav-link active"
                              id="global-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#global"
                              type="button"
                              role="tab"
                              aria-controls="global"
                              aria-selected="true"
                            >
                              GLOBAL
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="zonamaz-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#zonamaz"
                              type="button"
                              role="tab"
                              aria-controls="zonamaz"
                              aria-selected="false"
                            >
                              ZONA MAZ
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="tubu-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#tubu"
                              type="button"
                              role="tab"
                              aria-controls="tubu"
                              aria-selected="false"
                            >
                              Tu BU
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="masleidas-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#masleidas"
                              type="button"
                              role="tab"
                              aria-controls="masleidas"
                              aria-selected="false"
                            >
                              Las más leidas
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="todas-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#todas"
                              type="button"
                              role="tab"
                              aria-controls="todas"
                              aria-selected="false"
                            >
                              Todas las Noticias
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="col-2">
                        <button className="global-button">
                          <Link to="/news-feed/global" className="global-Link">
                            {' '}
                            Ver todo global
                          </Link>
                        </button>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="global"
                          role="tabpanel"
                          aria-labelledby="global-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row row row-cols-1 row-cols-sm-2">
                              {globalNews?.map((el: any, index: number) => {
                                return (
                                  <div
                                    className="col col-md-4 mb-4"
                                    key={index}
                                  >
                                    <Link
                                      className="text-black"
                                      to={'/detail-news?' + el.id}
                                    >
                                      <div className="card shadow-sm">
                                        <img
                                          src={el.image}
                                          className="relevant-images"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                          <span className="date-news">
                                            {el.date}
                                          </span>
                                          <h5 className="card-title mt-4">
                                            {el.title}
                                          </h5>
                                          <p className="card-text">
                                            {el.description}...
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="tubu"
                          role="tabpanel"
                          aria-labelledby="tubu-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row row-cols-1 row-cols-sm-2">
                              {tubuNews?.map((el: any, index: number) => {
                                return (
                                  <div
                                    className="col col-md-4 mb-4"
                                    key={index}
                                  >
                                    <Link
                                      className="text-black"
                                      to={'/detail-news?' + el.id}
                                    >
                                      <div className="card shadow-sm">
                                        <img
                                          src={el.image}
                                          className="relevant-images"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                          <span className="date-news">
                                            {el.date}
                                          </span>
                                          <h5 className="card-title mt-4">
                                            {el.title}
                                          </h5>
                                          <p className="card-text">
                                            {el.description.substr(0, 200)}...
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="zonamaz"
                          role="tabpanel"
                          aria-labelledby="zonamaz-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row row-cols-1 row-cols-sm-2">
                              {zonamazNews?.map((el: any, index: number) => {
                                return (
                                  <div
                                    className="col  col-md-4 mb-4"
                                    key={index}
                                  >
                                    <Link
                                      className="text-black"
                                      to={'/detail-news?' + el.id}
                                    >
                                      <div className="card shadow-sm">
                                        <img
                                          src={el.image}
                                          className="relevant-images"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                          <span className="date-news">
                                            {el.date}
                                          </span>
                                          <h5 className="card-title mt-4">
                                            {el.title}
                                          </h5>
                                          <p className="card-text">
                                            {el.description.substr(0, 200)}...
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="masleidas"
                          role="tabpanel"
                          aria-labelledby="masleidas-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row">
                              <LastNewsShorts btn={0} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="zonamaz"
                          role="tabpanel"
                          aria-labelledby="zonamaz-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row row-cols-1 row-cols-sm-2">
                              {zonamazNews?.map((el: any, index: number) => {
                                return (
                                  <div
                                    className="col col-md-4 mb-4"
                                    key={index}
                                  >
                                    <Link
                                      className="text-black"
                                      to={'/detail-news?' + el.id}
                                    >
                                      <div className="card shadow-sm">
                                        <img
                                          src={el.image}
                                          className="relevant-images"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                          <span className="date-news">
                                            {el.date}
                                          </span>
                                          <h5 className="card-title mt-4">
                                            {el.title}
                                          </h5>
                                          <p className="card-text">
                                            {el.description.substr(0, 200)}...
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="todas"
                          role="tabpanel"
                          aria-labelledby="todas-tab"
                        >
                          <div className="overflow-hidden py-4 px-2 box-programs">
                            <div className="row row-cols-1 row-cols-sm-2 ">
                              {allNews?.map((el: any, index: number) => {
                                return (
                                  <div
                                    className="col col-md-4 mb-4"
                                    key={index}
                                  >
                                    <Link
                                      className="text-black"
                                      to={'/detail-news?' + el.id}
                                    >
                                      <div className="card shadow-sm">
                                        <img
                                          src={el.image}
                                          className="relevant-images"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                          <span className="date-news">
                                            {el.date}
                                          </span>
                                          <h5 className="card-title mt-4">
                                            {el.title}
                                          </h5>
                                          <p className="card-text">
                                            {el.description.substr(0, 200)}...
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <>
          <div className="main-content">
            <div className="container-fluid">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/landing">{domainData.name}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    NewsFeed
                  </li>
                </ol>
              </nav>
              <div className="section-title mt-5">
                <img
                  src="https://i.ibb.co/sw34sxm/buscador.png"
                  className="search-image"
                  alt="img-buscador"
                />
                <div className="">
                  <p className="search-text">
                    No existen noticias para mostrar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  } else return <LoadSpinner></LoadSpinner>;
};

export default Newsfeed;
