import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';



// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsLib;

type PDFViewerProps = {
  fileUrl: string | File;
  actPageNumber?: number;
};

const PDFViewer = ({ fileUrl, actPageNumber = 1 }: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState<string>('');

  const onDocumentLoadSuccess = ({ numPages = 0 }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    setPageNumber(actPageNumber);
  }, [actPageNumber]);
  useEffect(() => {
    if (typeof fileUrl === 'string') setFile(fileUrl);
    else {
      const reader = new FileReader();

      reader.readAsDataURL(fileUrl);
      reader.onload = () => {
        const res = reader.result;

        if (typeof res === 'string') setFile(res || '');
      };
    }
  }, [fileUrl]);

  console.log(file);

  return (
    <>
      {/* <Document
        {...{ file }}
        onLoadSuccess={onDocumentLoadSuccess}
        error="Invalid URL"
        noData={'NO PDF'}
        onSourceError={(e: Error) =>
          alert('Error while retrieving document source! ' + e.message)
        }
      >
        <Page pageNumber={pageNumber} />
      </Document> */}
      {numPages && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}
    </>
  );
};

export default PDFViewer;
